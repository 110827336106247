import uuid from 'uuid/v1';
import moment from 'moment';

import mock from 'utils/mock';
import { submissionInitialValues } from './fixtures/submissionFixtures';

mock.onGet('/api/management/customers').reply(200, {
  customers: [
    {
      id: uuid(),
      name: 'Ekaterina Tankova',
      email: 'ekaterina.tankova@devias.io',
      avatar: '/images/avatars/avatar_2.png',
      spent: '5,002.00',
      currency: '$',
      type: 'Agency',
      projects: '1',
      rating: 5,
      location: 'West Virginia, USA'
    },
    {
      id: uuid(),
      name: 'Cao Yu',
      email: 'cao.yu@devias.io',
      avatar: '/images/avatars/avatar_3.png',
      spent: '3,040.00',
      type: 'Freelancer',
      currency: '$',
      projects: '3',
      rating: 4.3,
      location: 'Bristow'
    },
    {
      id: uuid(),
      name: 'Alexa Richardson',
      email: 'alexa.richardson@devias.io',
      avatar: '/images/avatars/avatar_4.png',
      spent: '26,030.00',
      type: 'Enterprise',
      currency: '$',
      projects: '0',
      rating: 4.5,
      location: 'Georgia, USA'
    },
    {
      id: uuid(),
      name: 'Anje Keizer',
      email: 'anje.keizer@devias.io',
      avatar: '/images/avatars/avatar_5.png',
      spent: '56,300.00',
      type: 'Enterprise',
      currency: '$',
      projects: '6',
      rating: 4,
      location: 'Ohio, USA'
    },
    {
      id: uuid(),
      name: 'Clarke Gillebert',
      email: 'clarke.gillebert@devias.io',
      avatar: '/images/avatars/avatar_6.png',
      spent: '50,660.00',
      type: 'Agency',
      currency: '$',
      projects: '1',
      rating: 3.5,
      location: 'Texas, USA'
    },
    {
      id: uuid(),
      name: 'Adam Denisov',
      email: 'adam.denisov@devias.io',
      avatar: '/images/avatars/avatar_7.png',
      spent: '53,830.00',
      type: 'Agency',
      currency: '$',
      projects: '0',
      rating: 3,
      location: 'California, USA'
    },
    {
      id: uuid(),
      name: 'Ava Gregoraci',
      email: 'ava.gregoraci@devias.io',
      avatar: '/images/avatars/avatar_8.png',
      spent: '3,080.00',
      type: 'Freelancer',
      currency: '$',
      projects: '0',
      rating: 4,
      location: 'California, USA'
    },
    {
      id: uuid(),
      name: 'Emilee Simchenko',
      email: 'emilee.simchenko@devias.io',
      avatar: '/images/avatars/avatar_9.png',
      spent: '1,030.00',
      type: 'Agency',
      currency: '$',
      projects: '4',
      rating: 4.5,
      location: 'Nevada, USA'
    },
    {
      id: uuid(),
      name: 'Kwak Seong-Min',
      email: 'kwak.seong.min@devias.io',
      avatar: '/images/avatars/avatar_10.png',
      spent: '10,000.00',
      type: 'Freelancer',
      currency: '$',
      projects: '2',
      rating: 5,
      location: 'Michigan, USA'
    },
    {
      id: uuid(),
      name: 'Jenn Broker',
      email: 'shen.zhi@devias.io',
      avatar: '/images/avatars/avatar_11.png',
      spent: '20,300.00',
      type: 'Agency',
      currency: '$',
      projects: '0',
      rating: 3.9,
      location: 'Utah, USA'
    },
    {
      id: uuid(),
      name: 'Merrile Burgett',
      email: 'merrile.burgett@devias.io',
      avatar: '/images/avatars/avatar_12.png',
      spent: '2,900.00',
      type: 'Enterprise',
      currency: '$',
      projects: '7',
      rating: 4.2,
      location: 'Utah, USA'
    }
  ]
});

mock.onGet('/api/management/customers/1/summary').reply(200, {
  summary: {
    name: 'Ekaterina Tankova',
    email: 'ekaterina@devias.io',
    phone: '+55 748 327 439',
    state: 'Alabama',
    country: 'United States',
    zipCode: '240355',
    address1: 'Street John Wick, no. 7',
    address2: 'House #25',
    iban: '4142 **** **** **** ****',
    autoCC: false,
    verified: true,
    currency: '$',
    invoices: [
      {
        id: uuid(),
        type: 'paid',
        value: 10.0
      },
      {
        id: uuid(),
        type: 'paid',
        value: 15.0
      },
      {
        id: uuid(),
        type: 'due',
        value: 5
      },
      {
        id: uuid(),
        type: 'income',
        value: 10.0
      }
    ],
    vat: 19,
    balance: 0,
    emails: [
      {
        id: uuid(),
        description: 'Order confirmation',
        created_at: moment()
          .subtract(3, 'days')
          .subtract(5, 'hours')
          .subtract(34, 'minutes')
      },
      {
        id: uuid(),
        description: 'Order confirmation',
        created_at: moment()
          .subtract(4, 'days')
          .subtract(11, 'hours')
          .subtract(49, 'minutes')
      }
    ]
  }
});

mock.onGet('/api/management/customers/1/invoices').reply(200, {
  invoices: [
    {
      id: uuid(),
      date: moment(),
      description: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
      paymentMethod: 'Credit Card',
      value: '5.25',
      currency: '$',
      status: 'paid'
    },
    {
      id: uuid(),
      date: moment(),
      description: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
      paymentMethod: 'Credit Card',
      value: '5.25',
      currency: '$',
      status: 'paid'
    }
  ]
});

mock.onGet('/api/management/customers/1/logs').reply(200, {
  logs: [
    {
      id: uuid(),
      status: 200,
      method: 'POST',
      route: '/api/purchase',
      desc: 'Purchase',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(2, 'minutes')
        .subtract(56, 'seconds')
    },
    {
      id: uuid(),
      status: 522,
      error: 'Invalid credit card',
      method: 'POST',
      route: '/api/purchase',
      desc: 'Purchase',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(2, 'minutes')
        .subtract(56, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'DELETE',
      route: '/api/products/d65654e/remove',
      desc: 'Cart remove',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(8, 'minutes')
        .subtract(23, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'GET',
      route: '/api/products/d65654e/add',
      desc: 'Cart add',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(20, 'minutes')
        .subtract(54, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'GET',
      route: '/api/products/c85727f/add',
      desc: 'Cart add',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(34, 'minutes')
        .subtract(16, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'GET',
      route: '/api/products/c85727f',
      desc: 'View product',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(54, 'minutes')
        .subtract(30, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'GET',
      route: '/api/products',
      desc: 'Get products',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(56, 'minutes')
        .subtract(40, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'POST',
      route: '/api/login',
      desc: 'Login',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(57, 'minutes')
        .subtract(5, 'seconds')
    }
  ]
});

mock.onGet('/api/customers').reply(200, {
  customers: [
    {
      id: uuid(),
      contactName: 'Abrams, JJ',
      avatar: '//logo.clearbit.com/cactusclubcafe.com',
      name: 'Cactus Club Cafe',
      spent: '34,238.29',
      currency: '$',
      type: 'Restaurant',
      submissions: '1',
      joinedDate: new Date('Aug 13, 2019'),
      phoneNumber: '604-661-5362',
      emailAddress: 'info@cactusclubcafe.com',
      address: {
        city: 'Vancouver',
        province: 'British Columbia'
      }
    },
    {
      id: uuid(),
      contactName: 'Lewis, Emma',
      avatar: '//logo.clearbit.com/timhortons.co.uk',
      name: 'Tim Hortons',
      spent: '24,232.50',
      currency: '$',
      type: 'Restaurant',
      submissions: '2',
      joinedDate: new Date('Aug 15, 2019'),
      phoneNumber: '605-231-2322',
      emailAddress: 'elewis@timhortons.com',
      address: {
        city: 'Toronto',
        province: 'Ontario'
      }
    },
    {
      id: uuid(),
      contactName: 'Smith, Kevin',
      avatar: '//logo.clearbit.com/herschel.com',
      name: 'Herschel',
      spent: '4,022.30',
      currency: '$',
      type: 'Apparel',
      submissions: '1',
      joinedDate: new Date('Nov 11, 2019'),
      phoneNumber: '403-661-5362',
      emailAddress: 'smith@herschel.com',
      address: {
        city: 'Vancouver',
        province: 'British Columbia'
      }
    },
    {
      id: uuid(),
      contactName: 'Braun, Jacob',
      avatar: '//logo.clearbit.com/ledcor.com',
      name: 'Ledcor',
      spent: '20,334.75',
      currency: '$',
      type: 'Construction',
      submissions: '1',
      joinedDate: new Date('Sep 23, 2019'),
      phoneNumber: '416-661-5362',
      emailAddress: 'jbraun@ledcor.com',
      address: {
        city: 'Toronto',
        province: 'Ontario'
      }
    },
    {
      id: uuid(),
      contactName: 'Anje Keizer',
      avatar: '//logo.clearbit.com/beedie.ca',
      emailAddress: 'anje@beedie.ca',
      type: 'Construction',
      name: 'Beedie Properties',
      spent: '32,092.90',
      currency: '$',
      submissions: '3',
      joinedDate: new Date('Aug 27, 2018'),
      phoneNumber: '604-643-5333',
      address: {
        city: 'Vancouver',
        province: 'British Columbia'
      }
    },
    {
      id: uuid(),
      contactName: 'Emilee Simchenko',
      avatar: '//logo.clearbit.com/teck.com',
      emailAddress: 'esimchenko@teck.com',
      spent: '15,750',
      currency: '$',
      type: 'Mining',
      name: 'Teck Corporation',
      submissions: '1',
      joinedDate: new Date('Apr 1, 2019'),
      phoneNumber: '604-573-3533',
      address: {
        city: 'Vancouver',
        province: 'British Columbia'
      }
    },
    {
      id: uuid(),
      contactName: 'Kwak Seong-Min',
      avatar: '//logo.clearbit.com/clearly.ca',
      emailAddress: 'kwak@clearly.ca',
      type: 'Contact Lenses',
      name: 'Clearly',
      spent: '10,209.22',
      currency: '$',
      submissions: '2',
      joinedDate: new Date('Jan 6, 2018'),
      phoneNumber: '778-807-9785',
      address: {
        city: 'Vancouver',
        province: 'British Columbia'
      }
    },
    {
      id: uuid(),
      contactName: 'Paul Reinhart',
      avatar: '//logo.clearbit.com/whitespot.ca',
      emailAddress: 'reinhart@whitespot.ca',
      type: 'Restaurant',
      name: 'White Spot',
      spent: '96,209.10',
      currency: '$',
      submissions: '4',
      joinedDate: new Date('Jun 19, 2019'),
      phoneNumber: '604-573-3533',
      address: {
        city: 'Vancouver',
        province: 'British Columbia'
      }
    },
    {
      id: uuid(),
      contactName: 'Cao Yu',
      avatar: '//logo.clearbit.com/whistlergolf.com',
      emailAddress: 'caoyu@whistlergolf.com',
      type: 'Golf',
      name: 'Whistler Golf',
      spent: '42,230.23',
      currency: '$',
      submissions: '5',
      joinedDate: new Date('Mar 26, 2019'),
      phoneNumber: '778-978-7845',
      address: {
        city: 'Whistler',
        province: 'British Columbia'
      }
    },
    {
      id: uuid(),
      contactName: 'Jack Cook',
      avatar: '//logo.clearbit.com/dirtyapron.com',
      emailAddress: 'jcook@dirtyapron.com',
      type: 'Restaurant',
      name: 'The Dirty Apron',
      spent: '7,209.20',
      currency: '$',
      submissions: '6',
      joinedDate: new Date('Feb 15, 2018'),
      phoneNumber: '604-254-4778',
      address: {
        city: 'Vancouver',
        province: 'British Columbia'
      }
    }
  ]
});

mock.onGet('/api/customers/1/summary').reply(200, {
  summary: {
    contactName: 'Keizer, Anje',
    avatar: '//logo.clearbit.com/beedie.ca',
    name: 'Beedie Properties',
    email: 'anje@beedie.ca',
    phone: '604-643-5333',
    state: 'British Columbia',
    country: 'Canada',
    zipCode: 'V5K 1T9',
    address1: '123 Main St',
    address2: 'Apt #25',
    iban: '4142 **** **** **** ****',
    autoCC: false,
    verified: true,
    currency: '$',
    submissions: [
      {
        id: uuid(),
        type: 'Commercial Realty',
        submittedDate: new Date(),
        status: 'out to market',
        ...submissionInitialValues
      },
      {
        id: uuid(),
        type: 'Equipment Breakdown',
        submittedDate: new Date('Jan 1, 2020'),
        status: 'submitted',
        name: 'Westlake Resort 3432 Lakeway, Penticton, BC'
      },
      {
        id: uuid(),
        type: 'CoC',
        submittedDate: new Date('Nov 23, 2019'),
        status: 'out to market',
        name: 'Pacific Gateway 74223 Steveston Hwy, Richmond, BC'
      },
      {
        id: uuid(),
        type: 'Commercial Realty',
        submittedDate: new Date('Jan 30, 2019'),
        status: 'completed',
        name: 'Rock Estates 9786 Burke Mountain, Port Moody, BC'
      },
      {
        id: uuid(),
        type: 'Commercial Realty',
        submittedDate: new Date('Jan 30, 2018'),
        status: 'completed',
        name: 'Sunset Beach 4343 Marine Way, Vancouver, BC'
      },
      {
        id: uuid(),
        type: 'Commercial Realty',
        submittedDate: new Date('Jan 30, 2017'),
        status: 'rejected',
        name: 'Oceanview Estates 4232 Crescent Way, White Rock, BC'
      }
    ],
    vat: 19,
    balance: 0,
    emails: [
      {
        id: uuid(),
        description: 'Order confirmation',
        created_at: moment()
          .subtract(3, 'days')
          .subtract(5, 'hours')
          .subtract(34, 'minutes')
      },
      {
        id: uuid(),
        description: 'Order confirmation',
        created_at: moment()
          .subtract(4, 'days')
          .subtract(11, 'hours')
          .subtract(49, 'minutes')
      }
    ]
  }
});
