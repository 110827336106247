/* eslint-disable react/no-danger */
import React from 'react';
import { Helmet } from 'react-helmet';

// eslint-disable-next-line no-undef
const BUGHERD_PROJECT_KEY =
  process.env.REACT_APP_BUGHERD_PROJECT_KEY || 'pmapz1y9oeuqld7cknxjxa';

const BugHerd = () => {
  return (
    <Helmet>
      <script
        async
        src={`https://www.bugherd.com/sidebarv2.js?apikey=${BUGHERD_PROJECT_KEY}`}
      />
    </Helmet>
  );
};

export default BugHerd;
