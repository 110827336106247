import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  colors,
  Link,
  Tooltip,
  Typography
} from '@material-ui/core';
import { submissionStatusStyling } from '../../constants';
import { TrafficLight } from '../';

import getInitials from 'utils/getInitials';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap'
    },
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  header: {
    maxWidth: '100%',
    width: 275,
    position: 'relative',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      flexBasis: '100%'
    }
  },
  avatar: {
    marginRight: theme.spacing(2),
    alignSelf: 'center'
  },
  stats: {
    padding: theme.spacing(1),
    width: 175,
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    }
  },
  narrowStats: {
    width: 115
  },
  actions: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    }
  }
}));

const progressMap = {
  'on track': {
    label: 'On Track',
    prop: { GreenOn: true }
  },
  behind: {
    label: 'Behind',
    prop: { YellowOn: true }
  },
  'at risk': {
    label: 'At Risk',
    prop: { RedOn: true }
  }
};
const ProgressTrafficLight = ({ progress }) => {
  return (
    <div>
      <TrafficLight
        {...progressMap[progress].prop}
        Size={16}
        Horizontal
        BlackColor={'none'}
        Flip
        DisabledColor={colors.grey[300]}
      />
    </div>
  );
};

const SubmissionCard = props => {
  const { submission, className, ...rest } = props;

  const classes = useStyles();
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.header}>
          <Avatar
            alt="Author"
            className={classes.avatar}
            src={submission.author.avatar}>
            {getInitials(submission.author.name)}
          </Avatar>
          <div>
            <Link
              color="textPrimary"
              component={RouterLink}
              noWrap
              to="/submissions/1/response"
              variant="h6">
              {submission.name}
            </Link>
            <Typography variant="body2">
              from{' '}
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/customers/1"
                variant="h6">
                {submission.author.name}
              </Link>
            </Typography>
            <Typography variant="overline">{submission.type}</Typography>
          </div>
        </div>
        <div className={classes.stats}>
          <Typography variant="h6">{submission.company}</Typography>
          <Typography variant="body2">Company</Typography>
        </div>
        <Tooltip
          title={progressMap[submission.progressStatus].label}
          aria-label={progressMap[submission.progressStatus].label}
          placement="top">
          <div className={clsx(classes.stats, classes.narrowStats)}>
            <ProgressTrafficLight progress={submission.progressStatus} />
            <Typography variant="body2">Progress</Typography>
          </div>
        </Tooltip>
        <div className={clsx(classes.stats, classes.narrowStats)}>
          <Typography variant="h6">{submission.assignedBroker}</Typography>
          <Typography variant="body2">Task Owner</Typography>
        </div>
        <div className={clsx(classes.stats, classes.narrowStats)}>
          <Typography variant="h6">
            {submission.submittedDate != null
              ? moment(submission.submittedDate).format('MMM DD, YYYY')
              : 'n/a'}
          </Typography>
          <Typography variant="body2">Submitted</Typography>
        </div>
        <div className={clsx(classes.stats, classes.narrowStats)}>
          <Typography variant="h6">
            {moment(submission.targetDate).format('MMM DD, YYYY')}
          </Typography>
          <Typography variant="body2">Target</Typography>
        </div>
        <div className={clsx(classes.stats, classes.narrowStats)}>
          <Typography
            style={{
              color: submissionStatusStyling(submission.status)?.color
            }}
            variant="h6">
            {submissionStatusStyling(submission.status)?.label}
          </Typography>
          <Typography variant="body2">Status</Typography>
        </div>
        <div className={classes.actions}>
          <Button
            color="primary"
            component={RouterLink}
            size="small"
            variant="outlined"
            to="/submissions/1/response">
            View
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

SubmissionCard.propTypes = {
  className: PropTypes.string,
  submission: PropTypes.object.isRequired
};

export default SubmissionCard;
