import { useMemo } from 'react';
import { State } from 'country-state-city';

const useCountryStates = (countryCode) => {
  const states = useMemo(() => {
    if (countryCode) {
      const result = State.getStatesOfCountry(countryCode);
      if (result?.length) {
        return result.map(state => ({
          name: state.name,
          code: state.isoCode
        }));
      }
      return [];
    }

    return [];
  }, [countryCode]);

  return states;
};

export default useCountryStates;
