import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Button,
  Chip
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { useFormikContext } from 'formik';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

const useStyles = makeStyles(theme => ({
  root: {},
  alert: {
    marginBottom: theme.spacing(3)
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: theme.spacing(1, 0)
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  dateField: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const AboutSubmission = props => {
  const { className, initialValues, ...rest } = props;

  const classes = useStyles();

  const {
    values,
    setFieldValue,
    touched,
    errors,
    getFieldProps
  } = useFormikContext();

  const handleFieldChange = (event, field, value) => {
    event.persist && event.persist();
    console.log('event.target.value', event.target.value);
    console.log('value', value);

    setFieldValue(field, value);
  };

  const handleTagAdd = () => {
    const newValues = { ...values };
    if (newValues.tag && !newValues.projectTags.includes(newValues.tag)) {
      newValues.projectTags = [...newValues.projectTags, newValues.tag];
    }
    setFieldValue('projectTags', newValues.projectTags);
    setFieldValue('tag', '');
  };

  const handleTagDelete = tag => {
    const newValues = { ...values };
    newValues.projectTags = newValues.projectTags.filter(t => t !== tag);
    setFieldValue('projectTags', newValues.projectTags);
  };

  const handleCalendarChange = () => {};

  const handleCalendarAccept = date => {
    setFieldValue('startDate', date);
  };

  const handleCalendarClose = () => {};

  const handleEnterPressedTags = e => {
    if (e.key === 'Enter') {
      handleTagAdd();
    }
  };

  const startMinDate = moment(values.startDate);
  const startMaxDate = values.targetDate ? moment(values.targetDate) : moment();
  const targetMinDate = values.startDate
    ? moment(values.startDate)
    : moment().add(2, 'week');

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="About this submission" />
      <CardContent>
        <form>
          <div className={classes.formGroup}>
            <TextField
              {...getFieldProps('submissionName')}
              required
              fullWidth
              label="Submission Name"
              name="submissionName"
              onChange={event =>
                handleFieldChange(event, 'submissionName', event.target.value)
              }
              value={values.submissionName}
              inputProps={{ readOnly: initialValues ? true : false }}
              variant="outlined"
              error={touched['submissionName'] && !!errors['submissionName']}
              helperText={touched['submissionName'] && errors['submissionName']}
            />
          </div>
          <div className={classes.formGroup}>
            <div className={classes.fieldGroup}>
              <TextField
                className={classes.flexGrow}
                {...getFieldProps('projectTags')}
                label="Project Tags"
                name="projectTags"
                onChange={event =>
                  handleFieldChange(event, 'tag', event.target.value)
                }
                value={values.tag}
                variant="outlined"
                error={
                  touched['projectTags'] && errors['projectTags'] ? true : false
                }
                helperText={touched['projectTags'] && errors['projectTags']}
                onKeyDown={handleEnterPressedTags}
              />
              <Button
                className={classes.addButton}
                onClick={handleTagAdd}
                size="small">
                <AddIcon className={classes.addIcon} />
                Add
              </Button>
            </div>

            <div className={classes.tags}>
              {values.projectTags?.map(tag => (
                <Chip
                  deleteIcon={<CloseIcon />}
                  key={tag}
                  label={tag}
                  onDelete={() => handleTagDelete(tag)}
                />
              ))}
            </div>
          </div>
          <div className={classes.formGroup}>
            <div className={classes.fieldGroup}>
              <DatePicker
                required
                className={classes.dateField}
                label="Start Date"
                name="startDate"
                minDate={startMinDate}
                format={'MM-DD-YYYY'}
                maxDate={startMaxDate}
                onAccept={handleCalendarAccept}
                onChange={handleCalendarChange}
                onClose={handleCalendarClose}
                variant="dialog"
                readOnly={initialValues ? true : false}
                value={moment(values.startDate)}
                inputVariant="outlined"
              />
              <DatePicker
                required
                className={classes.dateField}
                label="Target Date"
                name="targetDate"
                minDate={targetMinDate}
                readOnly={initialValues ? true : false}
                format={'MM-DD-YYYY'}
                onAccept={date => setFieldValue('targetDate', date)}
                onChange={handleCalendarChange}
                onClose={handleCalendarClose}
                variant="dialog"
                inputRef={CurrencyTextField}
                value={moment(values.targetDate)}
                inputVariant="outlined"
              />
            </div>
          </div>
          <div className={classes.formGroup}>
            <div className={classes.fieldGroup}>
              {/* <TextField
                label="Target Premium"
                name="targetPremium"
                onChange={event =>
                  handleFieldChange(event, 'targetPremium', event.target.value)
                }
                {...getFieldProps('targetPremium')}
                type="number"
                inputProps={{ readOnly: initialValues ? true : false }}
                // disabled={initialValues ? true : false}
                value={values.targetPremium}
                variant="outlined"
                error={touched['targetPremium'] && !!errors['targetPremium']}
                helperText={touched['targetPremium'] && errors['targetPremium']}
              /> */}
              <CurrencyTextField
                customInput={TextField}
                label="Target Premium"
                name="targetPremium"
                onChange={(event, value) => {
                  console.log('event');
                  handleFieldChange(event, 'targetPremium', value);
                }}
                // {...getFieldProps('targetPremium')}
                inputProps={{ readOnly: initialValues ? true : false }}
                value={values.targetPremium}
                variant="outlined"
                error={touched['targetPremium'] && !!errors['targetPremium']}
                helperText={touched['targetPremium'] && errors['targetPremium']}
                currencySymbol="$"
                decimalPlaces={2}
                outputFormat="number"
                placeholder="xxx.xx"
                decimalCharacter="."
                digitGroupSeparator=","
              />
            </div>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

AboutSubmission.propTypes = {
  className: PropTypes.string
};

export default AboutSubmission;
