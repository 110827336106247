import { useMemo } from 'react';

const useSortedFormFields = (formData) => {
  const sortedFormData = useMemo(() => {
    const form = { ...formData };
    form.fields?.sort((a, b) => a.sort - b.sort);
    return form;
  }, [formData]);

  return sortedFormData
};

export default useSortedFormFields;
