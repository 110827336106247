export const translation = {
  signin: {
    title: 'signin.title',
    description: 'signin.description',
    form: {
      emailField: {
        label: 'signin.form.emailField.label'
      },
      passwordField: {
        label: 'signin.form.passwordField.label'
      },
      submitButtonText: 'signin.form.submitButtonText',
      dontHaveAccount: 'signin.form.dontHaveAccount'
    }
  },
  insurers: {
    metaData: {
      title: 'insurers.metaData.title',
      header: {
        title: 'insurers.metaData.header.title'
      },
      directoryTitle: 'insurers.metaData.directoryTitle'
    },
    table: {
      header: {
        company: 'insurers.table.header.company',
        contact: 'insurers.table.header.contact',
        phone: 'insurers.table.header.phone',
        email: 'insurers.table.header.email',
        ytd: 'insurers.table.header.ytd',
        type: 'insurers.table.header.type',
        currentMonth: 'insurers.table.header.currentMonth',
        submissions: 'insurers.table.header.submissions'
      }
    },
    insurerDetailsSection: {
      fields: {
        email: 'insurers.insurerDetailsSection.fields.email',
        phone: 'insurers.insurerDetailsSection.fields.phone',
        state: 'insurers.insurerDetailsSection.fields.state',
        country: 'insurers.insurerDetailsSection.fields.country',
        address1: 'insurers.insurerDetailsSection.fields.address1',
        address2: 'insurers.insurerDetailsSection.fields.address2'
      },
      actions: {
        editButton: {
          title: 'insurers.insurerDetailsSection.actions.editButton.title'
        }
      }
    },
    addInsurerForm: {
      title: 'insurers.addInsurerForm.title',
      fields: {
        companyName: 'insurers.addInsurerForm.fields.companyName',
        contactFirstName: 'insurers.addInsurerForm.fields.contactFirstName',
        contactLastName: 'insurers.addInsurerForm.fields.contactLastName',
        phoneNumber: 'insurers.addInsurerForm.fields.phoneNumber',
        emailAddress: 'insurers.addInsurerForm.fields.emailAddress'
      }
    },
    editInsurerForm: {
      title: 'insurers.editInsurerForm.title'
    },
    actions: {
      addInsurerButton: {
        text: 'insurers.actions.addInsurerButton.text'
      }
    }
  },
  customers: {
    metaData: {
      title: 'customers.metaData.title',
      directory: 'customers.metaData.directory'
    },
    search: {
      searchBar: {
        placeholder: 'customers.search.searchBar.placeholder'
      }
    },
    submissions: {
      meta: {
        title: 'customers.submissions.meta.title'
      }
    },
    submissionTable: {
      headerLabels: {
        type: 'customers.submissionTable.headerLabels.type',
        targetDate: 'customers.submissionTable.headerLabels.targetDate',
        status: 'customers.submissionTable.headerLabels.status',
        actions: 'customers.submissionTable.headerLabels.actions'
      },
      buttons: {
        seeAll: 'customers.submissionTable.buttons.seeAll',
        view: 'customers.submissionTable.buttons.view'
      }
    },
    submissionCard: {
      from: 'customers.submissionCard.from',
      company: 'customers.submissionCard.company',
      new: 'customers.submissionCard.new',
      notSpecified: 'customers.submissionCard.notSpecified',
      taskOwner: 'customers.submissionCard.taskOwner',
      submitted: 'customers.submissionCard.submitted',
      target: 'customers.submissionCard.target',
      status: 'customers.submissionCard.status',
      view: 'customers.submissionCard.view'
    },
    addCustomerForm: {
      addCustomerTitle: 'customers.addCustomerForm.addCustomerTitle',
      editCustomerTitle: 'customers.addCustomerForm.editCustomerTitle',
      fields: {
        companyName: 'customers.addCustomerForm.fields.companyName',
        contactFirstName: 'customers.addCustomerForm.fields.contactFirstName',
        contactLastName: 'customers.addCustomerForm.fields.contactLastName',
        phoneNumber: 'customers.addCustomerForm.fields.phoneNumber',
        emailAddress: 'customers.addCustomerForm.fields.emailAddress',
        country: {
          label: 'customers.addCustomerForm.fields.country.label',
          helperText: 'customers.addCustomerForm.fields.country.helperText'
        },
        state: {
          label: 'customers.addCustomerForm.fields.state.label',
          helperText: 'customers.addCustomerForm.fields.state.helperText'
        },
        city: 'customers.addCustomerForm.fields.city',
        postalCode: 'customers.addCustomerForm.fields.postalCode',
        address1: 'customers.addCustomerForm.fields.address1',
        address2: 'customers.addCustomerForm.fields.address2'
      }
    },

    filterDrawer: {
      close: 'customers.filterDrawer.close',
      form: {
        project: {
          title: 'customers.filterDrawer.form.project.title',
          paymentStatusField: {
            label:
              'customers.filterDrawer.form.project.paymentStatusField.label'
          },
          filterTagField: {
            label: 'customers.filterDrawer.form.project.filterTagField.label',
            addTag: 'customers.filterDrawer.form.project.filterTagField.addTag'
          },
          projectAmountField: {
            label:
              'customers.filterDrawer.form.project.projectAmountField.label'
          },
          projectStatusField: {
            label:
              'customers.filterDrawer.form.project.projectStatusField.label'
          }
        },
        customer: {
          title: 'customers.filterDrawer.form.customer.title',
          customerNameField: {
            label:
              'customers.filterDrawer.form.customer.customerNameField.label'
          },
          emailField: {
            label: 'customers.filterDrawer.form.customer.emailField.label'
          },
          phoneNumberField: {
            label: 'customers.filterDrawer.form.customer.phoneNumberField.label'
          },
          ageField: {
            label: 'customers.filterDrawer.form.customer.ageField.label'
          },
          togglerField: {
            projectOwner:
              'customers.filterDrawer.form.customer.togglerField.projectOwner',
            freelancer:
              'customers.filterDrawer.form.customer.togglerField.projectOwner'
          }
        },
        clear: 'customers.filterDrawer.form.clear',
        submit: 'customers.filterDrawer.form.submit'
      }
    },
    table: {
      title: 'customers.table.title',
      paginationTop: {
        text: 'customers.table.paginationTop.text'
      },
      header: {
        company: 'customers.table.header.company',
        contact: 'customers.table.header.contact',
        phone: 'customers.table.header.phone',
        email: 'customers.table.header.email',
        lifetimeValue: 'customers.table.header.lifetimeValue',
        type: 'customers.table.header.type',
        submissions: 'customers.table.header.submissions',
        actions: 'customers.table.header.actions'
      }
    },
    actions: {
      addCustomerButton: {
        text: 'customers.actions.addCustomerButton.text',
        actionPopOverText1:
          'customers.actions.addCustomerButton.actionPopOverText1',
        actionPopOverText2:
          'customers.actions.addCustomerButton.actionPopOverText2'
      },
      closeCustomerButton: {
        text: 'customers.actions.closeCustomerButton.text'
      },
      closeCustomerConfirmation: {
        cancelButtonText:
          'customers.actions.closeCustomerConfirmation.cancelButtonText',
        deleteButtonText:
          'customers.actions.closeCustomerConfirmation.deleteButtonText',
        text: 'customers.actions.closeCustomerConfirmation.text',
        title: 'customers.actions.closeCustomerConfirmation.title'
      },
      otherActions: 'customers.actions.otherActions',
      showFilterButton: 'customers.actions.showFilterButton'
    }
  },
  marketingGroups: {
    metaData: {
      parentTitle: 'marketingGroups.metaData.parentTitle',
      title: 'marketingGroups.metaData.title'
    },
    table: {
      mainHeader: 'marketingGroups.table.mainHeader',
      headers: {
        groupName: 'marketingGroups.table.headers.groupName',
        numberOfUnderwriters:
          'marketingGroups.table.headers.numberOfUnderwriters'
      }
    },
    actions: {
      addMarketingGroupButton: {
        text: 'marketingGroups.actions.addMarketingGroupButton.text'
      }
    }
  },

  submissions: {
    createSubmission: {
      metaData: {
        title: 'submissions.createSubmission.metaData.title'
      },
      addCustomer: {
        title: 'submissions.createSubmission.addCustomer.title',
        addCustomerButton:
          'submissions.createSubmission.addCustomer.addCustomerButton',
        fieldLabel: 'submissions.createSubmission.addCustomer.fieldLabel'
      },
      aboutThisSubmission: {
        title: 'submissions.createSubmission.aboutThisSubmission.title',
        fields: {
          submissionName:
            'submissions.createSubmission.aboutThisSubmission.fields.submissionName',
          projectTags:
            'submissions.createSubmission.aboutThisSubmission.fields.projectTags',
          startDate:
            'submissions.createSubmission.aboutThisSubmission.fields.startDate',
          endDate:
            'submissions.createSubmission.aboutThisSubmission.fields.endDate',
          targetPremium:
            'submissions.createSubmission.aboutThisSubmission.fields.targetPremium'
        }
      },
      selectSubmission: {
        title: 'submissions.createSubmission.selectSubmission.title',
        fileDropzone: {
          title: 'submissions.createSubmission.selectSubmission.fileDropzone',
          description:
            'submissions.createSubmission.selectSubmission.description'
        },
        error: 'submissions.createSubmission.selectSubmission.error'
      },
      submissionDetails: {
        title: 'submissions.createSubmission.submissionDetails.title',
        placeholder:
          'submissions.createSubmission.submissionDetails.placeholder'
      },
      sendSubmissionTo: {
        title: 'submissions.createSubmission.sendSubmissionTo.title',
        marketingGroup:
          'submissions.createSubmission.sendSubmissionTo.marketingGroup'
      },
      buttons: {
        saveAsDraft: 'submissions.createSubmission.buttons.saveAsDraft',
        finalize: 'submissions.createSubmission.buttons.finalize'
      }
    },
    proposalComments: {
      title: 'submissions.proposalComments.title',
      results: {
        bestTermsQuoted: 'submissions.proposalComments.results.bestTermsQuoted',
        termsQuoted: 'submissions.proposalComments.results.termsQuoted',
        declined: 'submissions.proposalComments.results.declined',
        didNotMeetTimeline:
          'submissions.proposalComments.results.didNotMeetTimeline'
      }
    }
  },
  pagination: {
    record: 'pagination.record',
    page: 'pagination.page',
    of: 'pagination.of'
  }
};
