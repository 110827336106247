import * as Yup from 'yup';

export const addInsurerSchema = Yup.object({
  name: Yup.string().required('Company Name is required!'),
  email: Yup.string()
    .email()
    .required('Email is required!'),
  phone: Yup.string().required('Phone is required!'),
  firstName: Yup.string().required('First Name is required!'),
  lastName: Yup.string().required('Last Name is required!'),
});
