import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import { Storage } from 'aws-amplify';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  }
}));

const S3Avatar = ({ s3key, className, component, to }) => {
  const classes = useStyles();
  const [src, setSrc] = useState('');

  useEffect(() => {
    let mount = true;
    const getS3Url = async () => {
      if (s3key) {
        const s3Url = await Storage.get(s3key);
        if (mount) setSrc(s3Url);
      }
    };
    getS3Url();

    return () => {
      mount = false;
    };
  }, [s3key]);
  return (
    <Avatar
      className={className ? className : classes.avatar}
      to={to}
      component={component}
      src={src}></Avatar>
  );
};

S3Avatar.propTypes = {
  s3key: PropTypes.string
};

export default S3Avatar;
