import { useEffect, useState } from 'react';
import { Country } from 'country-state-city';

const useCountry = countryRestrictions => {
  const [countryList, setCountryList] = useState([]);
  useEffect(() => {
    let isMounted = true;
    const result = Country.getAllCountries();
    if (result?.length) {
      const countriesData = result
        .filter(country =>
          countryRestrictions
            ? countryRestrictions.includes(country.name)
            : true
        )
        .map(country => ({
          name: country.name,
          code: country.isoCode
        }));
      if (isMounted) {
        setCountryList(countriesData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [setCountryList]);

  return countryList;
};

export default useCountry;
