import Amplify from 'aws-amplify';

export const _get = (data, path, defaultValue, seprator = '.') => {
  if (data) {
    const properties = Array.isArray(path) ? path : path.split(seprator);
    return properties.reduce((value, path) => value && value[path], data);
  }
  return defaultValue;
};

export const splitString = (customString, seprator = ' ') => {
  if (customString) {
    return customString.includes(seprator)
      ? customString.split(seprator)
      : null;
  }
  return null;
};

export const UploadDocument = async (file, directory) => {
  try {
    const result = await Amplify.Storage.put(
      `${directory}/${Date.now()}${file.name}`,
      file,
      {
        contentType: file.type,
        level: 'public'
      }
    );
    return result;
  } catch (err) {
    console.log('error while uploading document in s3', err);
  }
};

export const isEmailUnique = (callback, list = []) => {
  return list.find(callback);
};

export const numberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  roundingIncrement: 5
});
