import uuid from 'uuid/v1';
import moment from 'moment';
import { colors } from '@material-ui/core';

import mock from 'utils/mock';

import { submissionInitialValues } from './fixtures/submissionFixtures';

const today = new Date();

mock.onGet('/api/submissions').reply(200, {
  submissions: [
    {
      id: uuid(),
      author: {
        name: 'Anje Keizer',
        avatar: '//logo.clearbit.com/beedie.ca',
        emailAddress: 'anje@beedie.ca'
      },
      assignedBroker: 'Jenn',
      progressStatus: 'on track',
      name: 'Commercial Realty',
      type: 'new',
      status: 'proposed',
      company: 'Beedie Properties',
      startDate: moment().subtract(1, 'weeks'),
      submittedDate: moment().subtract(24, 'days'),
      targetDate: moment().add(2, 'days'),
      updatedAt: moment().subtract(24, 'minutes'),
      ...submissionInitialValues
    },
    {
      id: uuid(),
      author: {
        name: 'Emilee Simchenko',
        avatar: '//logo.clearbit.com/teck.com',
        emailAddress: 'esimchenko@teck.com'
      },
      assignedBroker: 'Nick',
      progressStatus: 'at risk',
      price: '15,750',
      currency: '$',
      name: 'Location 2 - Equipment Breakdown',
      type: 'change',
      status: 'out to market',
      company: 'Teck Corporation',
      startDate: moment().subtract(3, 'weeks'),
      submittedDate: moment().subtract(10, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(24, 'hours')
    },
    {
      id: uuid(),
      author: {
        name: 'Jin Seong',
        avatar: '//logo.clearbit.com/londondrugs.com',
        emailAddress: 'jim@londondrugs.com'
      },
      assignedBroker: 'Izzy',
      progressStatus: 'behind',
      price: '23,220',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'out to market',
      company: 'London Drugs',
      startDate: moment().subtract(3, 'weeks'),
      submittedDate: moment().subtract(12, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(24, 'hours')
    },
    {
      id: uuid(),
      author: {
        name: 'Matt Smith',
        avatar: '//logo.clearbit.com/loblaws.ca',
        emailAddress: 'matt@loblaws.com'
      },
      assignedBroker: 'Ronnie',
      progressStatus: 'behind',
      price: '22,039',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'out to market',
      company: 'Loblaws',
      startDate: moment().subtract(3, 'weeks'),
      submittedDate: moment().subtract(12, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(24, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Anabel Wilderman',
        avatar: '//logo.clearbit.com/joeysfranchisegroup.ca',
        emailAddress: 'anabel@joeysfranchisegroup.ca'
      },
      assignedBroker: 'Nick',
      progressStatus: 'on track',
      price: '40,938',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'out to market',
      company: 'Joey Restaurant Group',
      startDate: moment().subtract(9, 'weeks'),
      submittedDate: moment().subtract(9, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(1, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Hosea Kulas',
        avatar: '//logo.clearbit.com/stemcelltechnologies.com',
        emailAddress: 'hosea@stemcelltechnologies.com'
      },
      assignedBroker: 'Jenn',
      progressStatus: 'on track',
      price: '14,352',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'out to market',
      company: 'Stemcell Technologies',
      startDate: moment().subtract(6, 'weeks'),
      submittedDate: moment().subtract(27, 'days'),
      targetDate: moment().add(1, 'weeks'),
      updatedAt: moment().subtract(19, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Josiane Medhurst',
        avatar: '//logo.clearbit.com/dirtyapron.com',
        emailAddress: 'josiane@dirtyapron.com'
      },
      assignedBroker: 'Izzy',
      progressStatus: 'behind',
      price: '19,761',
      currency: '$',
      name: '"Event Liability - 3456 1st St',
      type: 'new',
      status: 'submission ready',
      company: 'The Dirty Apron',
      startDate: moment().subtract(8, 'weeks'),
      submittedDate: moment().subtract(25, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(20, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Ike Murphy',
        avatar: '//logo.clearbit.com/sagesoftware.com',
        emailAddress: 'ike@sagesoftware.com'
      },
      assignedBroker: 'Jenn',
      progressStatus: 'on track',
      price: '21,632',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'out to market',
      company: 'Sage Software',
      startDate: moment().subtract(12, 'weeks'),
      submittedDate: moment().subtract(24, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(12, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Ruthie Sanford',
        avatar: '//logo.clearbit.com/globalrelay.com',
        emailAddress: 'ruthie@globalrelay.com'
      },
      assignedBroker: 'Jenn',
      progressStatus: 'on track',
      price: '10,219',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'out to market',
      company: 'Global Relay',
      startDate: moment().subtract(7, 'weeks'),
      submittedDate: moment().subtract(21, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(8, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Vernie Kirlin',
        avatar: '//logo.clearbit.com/electronicarts.com',
        emailAddress: 'vernie@electronicarts.com'
      },
      assignedBroker: 'Jenn',
      progressStatus: 'on track',
      price: '40,522',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'out to market',
      company: 'Electronic Arts',
      startDate: moment().subtract(10, 'weeks'),
      submittedDate: moment().subtract(25, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(15, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Agustin Hills',
        avatar: '//logo.clearbit.com/herschel.com',
        emailAddress: 'agustin@herschel.com'
      },
      assignedBroker: 'Nick',
      progressStatus: 'behind',
      price: '35,419',
      currency: '$',
      name: '"Location 1 - Flagship Showroom - Gastown',
      status: 'submission ready',
      type: 'new',
      company: 'Herschel Supply Company',
      startDate: moment().subtract(12, 'weeks'),
      submittedDate: moment().subtract(18, 'days'),
      targetDate: moment().add(1, 'weeks'),
      updatedAt: moment().subtract(8, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Karolann Muller',
        avatar: '//logo.clearbit.com/ledcor.com',
        emailAddress: 'karolann@ledcor.com'
      },
      assignedBroker: 'Nick',
      progressStatus: 'behind',
      price: '35,015',
      currency: '$',
      name: '"New Project - COC Downtown Victoria, BC',
      type: 'new',
      status: 'submission ready',
      company: 'Ledcor',
      startDate: moment().subtract(11, 'weeks'),
      submittedDate: moment().subtract(26, 'days'),
      targetDate: moment().add(1, 'weeks'),
      updatedAt: moment().subtract(6, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Marlee Von',
        avatar: '//logo.clearbit.com/burrardpharma.com',
        emailAddress: 'marlee@burrardpharma.com'
      },
      assignedBroker: 'Izzy',
      progressStatus: 'on track',
      price: '19,240',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'out to market',
      company: 'Burrard Pharmaceuticals',
      startDate: moment().subtract(9, 'weeks'),
      submittedDate: moment().subtract(7, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(9, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Oliver Schultz',
        avatar: '//logo.clearbit.com/teckresources.com',
        emailAddress: 'oliver@teckresources.com'
      },
      assignedBroker: 'Ronnie',
      progressStatus: 'on track',
      price: '15,748',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Teck Resources',
      startDate: moment().subtract(7, 'weeks'),
      submittedDate: moment().subtract(26, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(12, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Zelda Morissette',
        avatar: '//logo.clearbit.com/bgordonbuilders.com',
        emailAddress: 'zelda@bgordonbuilders.com'
      },
      assignedBroker: 'Nick',
      progressStatus: 'on track',
      price: '24,085',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'out to market',
      company: 'B2Gord Corp',
      startDate: moment().subtract(5, 'weeks'),
      submittedDate: moment().subtract(8, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(12, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Jewell Conn',
        avatar: '//logo.clearbit.com/kindredconstruction.com',
        emailAddress: 'jewell@kindredconstruction.com'
      },
      assignedBroker: 'Nick',
      progressStatus: 'on track',
      price: '43,221',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'out to market',
      company: 'Kindred Construction',
      startDate: moment().subtract(6, 'weeks'),
      submittedDate: moment().subtract(27, 'days'),
      targetDate: moment().add(1, 'weeks'),
      updatedAt: moment().subtract(4, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Aubrey Mertz',
        avatar: '//logo.clearbit.com/broadbandtvcorp.com',
        emailAddress: 'aubrey@broadbandtvcorp.com'
      },
      assignedBroker: 'Nick',
      progressStatus: 'on track',
      price: '18,091',
      currency: '$',
      name: 'Cyber Risk',
      type: 'change',
      status: 'out to market',
      company: 'BroadbandTV Corp',
      startDate: moment().subtract(5, 'weeks'),
      submittedDate: moment().subtract(23, 'days'),
      targetDate: moment().add(1, 'weeks'),
      updatedAt: moment().subtract(11, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Eleazar Blick',
        avatar: '//logo.clearbit.com/hootsuite.com',
        emailAddress: 'eleazar@hootsuite.com'
      },
      assignedBroker: 'Nick',
      progressStatus: 'on track',
      price: '39,506',
      currency: '$',
      name: 'Cyber Risk',
      type: 'change',
      status: 'out to market',
      company: 'Hootsuite',
      startDate: moment().subtract(8, 'weeks'),
      submittedDate: moment().subtract(27, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(18, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Michel Greenfelder',
        avatar: '//logo.clearbit.com/metrocan.com',
        emailAddress: 'michel@metrocan.com'
      },
      assignedBroker: 'Nick',
      progressStatus: 'behind',
      price: '31,587',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Metro-Can Construction',
      startDate: moment().subtract(5, 'weeks'),
      submittedDate: moment().subtract(24, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(22, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Xavier Champlin',
        avatar: '//logo.clearbit.com/turnerconstruction.com',
        emailAddress: 'xavier@turnerconstruction.com'
      },
      assignedBroker: 'Izzy',
      progressStatus: 'at risk',
      price: '35,509',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Turner Construction',
      startDate: moment().subtract(12, 'weeks'),
      submittedDate: moment().subtract(25, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(20, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Jessy Bahringer',
        avatar: '//logo.clearbit.com/stantec.com',
        emailAddress: 'jessy@stantec.com'
      },
      assignedBroker: 'Izzy',
      progressStatus: 'behind',
      price: '10,636',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'started',
      company: 'Stantec Architecture',
      startDate: moment().subtract(5, 'weeks'),
      submittedDate: moment().subtract(13, 'days'),
      targetDate: moment().add(1, 'weeks'),
      updatedAt: moment().subtract(7, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Devyn Beier',
        avatar: '//logo.clearbit.com/schneiderelectric.com',
        emailAddress: 'devyn@schneiderelectric.com'
      },
      assignedBroker: 'Izzy',
      progressStatus: 'behind',
      price: '47,630',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Schneider Electric',
      startDate: moment().subtract(3, 'weeks'),
      submittedDate: moment().subtract(19, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(10, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Opal Mraz',
        avatar: '//logo.clearbit.com/clearly.ca',
        emailAddress: 'opal@clearly.ca'
      },
      assignedBroker: 'Nick',
      progressStatus: 'on track',
      price: '16,374',
      currency: '$',
      name: 'Location 25',
      type: 'new',
      status: 'proposed',
      company: 'Clearly',
      startDate: moment().subtract(10, 'weeks'),
      submittedDate: moment().subtract(22, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(5, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Audie Mayert',
        avatar: '//logo.clearbit.com/whitespot.ca',
        emailAddress: 'audie@whitespot.ca'
      },
      assignedBroker: 'Izzy',
      progressStatus: 'on track',
      price: '11,388',
      currency: '$',
      name: 'Location 11 - COC - Tenants Improvements',
      type: 'new',
      status: 'started',
      company: 'White Spot',
      startDate: moment().subtract(4, 'weeks'),
      submittedDate: moment().subtract(26, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(19, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Chloe Hintz',
        avatar: '//logo.clearbit.com/whistlergolf.com',
        emailAddress: 'chloe@whistlergolf.com'
      },
      assignedBroker: 'Nick',
      progressStatus: 'on track',
      price: '15,041',
      currency: '$',
      name: 'Hole in One',
      type: 'new',
      status: 'submission ready',
      company: 'Whistler Golf',
      startDate: moment().subtract(12, 'weeks'),
      submittedDate: moment().subtract(23, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(2, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Celestine Ondricka',
        avatar: '//logo.clearbit.com/cactusclubcafe.com',
        emailAddress: 'celestine@cactusclubcafe.com'
      },
      assignedBroker: 'Izzy',
      progressStatus: 'on track',
      price: '11,645',
      currency: '$',
      name: '"Location 39 - Toronto, ON',
      type: 'change',
      status: 'submission ready',
      company: 'Cactus Club',
      startDate: moment().subtract(4, 'weeks'),
      submittedDate: moment().subtract(15, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(4, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Sandra Dare',
        avatar: '//logo.clearbit.com/timhortons.com',
        emailAddress: 'sandra@timhortons.com'
      },
      assignedBroker: 'Ronnie',
      progressStatus: 'on track',
      price: '28,972',
      currency: '$',
      name: '"Location 83 - Manila, Philippines',
      status: 'submission ready',
      type: 'renewal',
      company: 'Tim Hortons',
      startDate: moment().subtract(4, 'weeks'),
      submittedDate: moment().subtract(27, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(8, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Brandi Stanton',
        avatar: '//logo.clearbit.com/clarkwilson.com',
        emailAddress: 'brandi@clarkwilson.com'
      },
      assignedBroker: 'Ronnie',
      progressStatus: 'on track',
      price: '22,784',
      currency: '$',
      name: 'Professional Liability',
      type: 'renewal',
      status: 'submission ready',
      company: 'Clark Wilson',
      startDate: moment().subtract(11, 'weeks'),
      submittedDate: moment().subtract(20, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(12, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Leonie Parisian',
        avatar: '//logo.clearbit.com/westportfuelsystems.com',
        emailAddress: 'leonie@westportfuelsystems.com'
      },
      assignedBroker: 'Jenn',
      progressStatus: 'on track',
      price: '28,379',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Westport Fuel Systems',
      startDate: moment().subtract(3, 'weeks'),
      submittedDate: moment().subtract(20, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(16, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Jaycee Gerlach',
        avatar: '//logo.clearbit.com/hyatt.com',
        emailAddress: 'jaycee@hyatt.com'
      },
      assignedBroker: 'Jenn',
      progressStatus: 'on track',
      price: '18,877',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Hyatt Regency',
      startDate: moment().subtract(5, 'weeks'),
      submittedDate: moment().subtract(11, 'days'),
      targetDate: moment().add(1, 'weeks'),
      updatedAt: moment().subtract(16, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Stuart Wisozk',
        avatar: '//logo.clearbit.com/suttonplace.com',
        emailAddress: 'stuart@suttonplace.com'
      },
      assignedBroker: 'Jenn',
      progressStatus: 'on track',
      price: '21,224',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Sutton Place Hotel',
      startDate: moment().subtract(10, 'weeks'),
      submittedDate: moment().subtract(7, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(2, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Lyric Stroman',
        avatar: '//logo.clearbit.com/fairmont.com',
        emailAddress: 'lyric@fairmont.com'
      },
      assignedBroker: 'Ronnie',
      progressStatus: 'on track',
      price: '10,566',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Fairmont Hotel Vancouver ',
      startDate: moment().subtract(7, 'weeks'),
      submittedDate: moment().subtract(8, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(19, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Jarred Abbott',
        avatar: '//logo.clearbit.com/riverrockcasino.com',
        emailAddress: 'jarred@riverrockcasino.com'
      },
      assignedBroker: 'Ronnie',
      progressStatus: 'on track',
      price: '17,161',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'River Rock Casino',
      startDate: moment().subtract(6, 'weeks'),
      submittedDate: moment().subtract(12, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(17, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Terrence Durgan',
        avatar: '//logo.clearbit.com/lululemon.com',
        emailAddress: 'terrence@lululemon.com'
      },
      assignedBroker: 'Nick',
      progressStatus: 'on track',
      price: '46,461',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Lululemon Athletica',
      startDate: moment().subtract(5, 'weeks'),
      submittedDate: moment().subtract(27, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(7, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Kristofer Cronin',
        avatar: '//logo.clearbit.com/purdys.com',
        emailAddress: 'kristofer@purdys.com'
      },
      assignedBroker: 'Izzy',
      progressStatus: 'on track',
      price: '25,494',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      // eslint-disable-next-line quotes
      company: "Purdy's Choclotatier",
      startDate: moment().subtract(9, 'weeks'),
      submittedDate: moment().subtract(11, 'days'),
      targetDate: moment().add(1, 'weeks'),
      updatedAt: moment().subtract(13, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Moriah Stoltenberg',
        avatar: '//logo.clearbit.com/earnesticecream.com',
        emailAddress: 'moriah@earnesticecream.com'
      },
      assignedBroker: 'Ronnie',
      progressStatus: 'on track',
      price: '24,241',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Earnest Ice Cream',
      startDate: moment().subtract(4, 'weeks'),
      submittedDate: moment().subtract(20, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(6, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Geovanni Moore',
        avatar: '//logo.clearbit.com/snclavalin.com',
        emailAddress: 'geovanni@snclavalin.com'
      },
      assignedBroker: 'Ronnie',
      progressStatus: 'on track',
      price: '34,215',
      currency: '$',
      name: 'Professional Liability',
      type: 'renewal',
      status: 'submission ready',
      company: 'SNC Lavalin',
      startDate: moment().subtract(12, 'weeks'),
      submittedDate: moment().subtract(13, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(10, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        // eslint-disable-next-line quotes
        name: "Gail O'Keefe",
        avatar: '//logo.clearbit.com/hemlock.com',
        emailAddress: 'gail@hemlock.com'
      },
      assignedBroker: 'Nick',
      progressStatus: 'on track',
      price: '27,642',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Hemlock Printiers',
      startDate: moment().subtract(4, 'weeks'),
      submittedDate: moment().subtract(15, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(1, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Tara Mosciski',
        avatar: '//logo.clearbit.com/paladinsecurity.com',
        emailAddress: 'tara@paladinsecurity.com'
      },
      assignedBroker: 'Jenn',
      progressStatus: 'on track',
      price: '24,754',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Paladin Security',
      startDate: moment().subtract(3, 'weeks'),
      submittedDate: moment().subtract(8, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(5, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Weldon Wiza',
        avatar: '//logo.clearbit.com/daiyafoods.com',
        emailAddress: 'weldon@daiyafoods.com'
      },
      assignedBroker: 'Izzy',
      progressStatus: 'on track',
      price: '45,414',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Daiya Foods',
      startDate: moment().subtract(10, 'weeks'),
      submittedDate: moment().subtract(8, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(4, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Vern Christiansen',
        avatar: '//logo.clearbit.com/ballard.com',
        emailAddress: 'vern@ballard.com'
      },
      assignedBroker: 'Nick',
      progressStatus: 'on track',
      price: '31,840',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Ballard Power',
      startDate: moment().subtract(8, 'weeks'),
      submittedDate: moment().subtract(22, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(14, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Adolfo Zboncak',
        avatar: '//logo.clearbit.com/sunrise-soya.com',
        emailAddress: 'adolfo@sunrise-soya.com'
      },
      assignedBroker: 'Izzy',
      progressStatus: 'on track',
      price: '13,273',
      currency: '$',
      name: 'Location 34 - new',
      type: 'change',
      status: 'submission ready',
      company: 'Sunrise Soya Foods',
      startDate: moment().subtract(3, 'weeks'),
      submittedDate: moment().subtract(22, 'days'),
      targetDate: moment().add(1, 'weeks'),
      updatedAt: moment().subtract(12, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Andres Wintheiser',
        avatar: '//logo.clearbit.com/deltacontrols.com',
        emailAddress: 'andres@deltacontrols.com'
      },
      assignedBroker: 'Jenn',
      progressStatus: 'on track',
      price: '37,717',
      currency: '$',
      name: 'Location 2 - Increase Stock Values',
      type: 'change',
      status: 'submission ready',
      company: 'Delta Controls',
      startDate: moment().subtract(3, 'weeks'),
      submittedDate: moment().subtract(9, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(21, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Candace Crona',
        avatar: '//logo.clearbit.com/bulldogbag.com',
        emailAddress: 'candace@bulldogbag.com'
      },
      assignedBroker: 'Ronnie',
      progressStatus: 'on track',
      price: '38,523',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Bulldog Bag Ltd',
      startDate: moment().subtract(4, 'weeks'),
      submittedDate: moment().subtract(15, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(1, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Nigel Terry',
        avatar: '//logo.clearbit.com/arcteryx.com',
        emailAddress: 'nigel@arcteryx.com'
      },
      assignedBroker: 'Nick',
      progressStatus: 'on track',
      price: '26,632',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Arcteryx Equipment',
      startDate: moment().subtract(12, 'weeks'),
      submittedDate: moment().subtract(18, 'days'),
      targetDate: moment().add(1, 'weeks'),
      updatedAt: moment().subtract(11, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Viva Goodwin',
        avatar: '//logo.clearbit.com/lush.ca',
        emailAddress: 'viva@lush.ca'
      },
      assignedBroker: 'Jenn',
      progressStatus: 'on track',
      price: '20,813',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Lush Fresh Handmade',
      startDate: moment().subtract(4, 'weeks'),
      submittedDate: moment().subtract(18, 'days'),
      targetDate: moment().add(1, 'weeks'),
      updatedAt: moment().subtract(16, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Novella Mante',
        avatar: '//logo.clearbit.com/seaspan.com',
        emailAddress: 'novella@seaspan.com'
      },
      assignedBroker: 'Nick',
      progressStatus: 'on track',
      price: '12,662',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Seaspan ULC',
      startDate: moment().subtract(10, 'weeks'),
      submittedDate: moment().subtract(7, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(13, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Tyra Hickle',
        avatar: '//logo.clearbit.com/glb.ca',
        emailAddress: 'tyra@glb.ca'
      },
      assignedBroker: 'Ronnie',
      progressStatus: 'on track',
      price: '17,075',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Granville Island Brewing',
      startDate: moment().subtract(10, 'weeks'),
      submittedDate: moment().subtract(14, 'days'),
      targetDate: moment().add(1, 'weeks'),
      updatedAt: moment().subtract(1, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Otho Krajcik',
        avatar: '//logo.clearbit.com/centralcitybrewing.com',
        emailAddress: 'otho@centralcitybrewing.com'
      },
      assignedBroker: 'Ronnie',
      progressStatus: 'on track',
      price: '12,338',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Central City Brewing',
      startDate: moment().subtract(7, 'weeks'),
      submittedDate: moment().subtract(17, 'days'),
      targetDate: moment().add(0, 'weeks'),
      updatedAt: moment().subtract(7, 'hours')
    },
    {
      id: 'uuid()',
      author: {
        name: 'Emily Nienow',
        avatar: '//logo.clearbit.com/metropolisatmetrotown.com',
        emailAddress: 'emily@metropolisatmetrotown.com'
      },
      assignedBroker: 'Jenn',
      progressStatus: 'on track',
      price: '18,580',
      currency: '$',
      name: 'Renewal',
      type: 'renewal',
      status: 'submission ready',
      company: 'Metropolis At Metrotown',
      startDate: moment().subtract(9, 'weeks'),
      submittedDate: moment().subtract(12, 'days'),
      targetDate: moment().add(2, 'weeks'),
      updatedAt: moment().subtract(11, 'hours')
    }
  ]
});

mock.onGet('/api/submissions/1/todos').reply(200, {
  todos: [
    {
      id: uuid(),
      title:
        'Look up previous Vancity Construction projects to see if they qualify for discount',
      deadline: moment(),
      done: false
    },
    {
      id: uuid(),
      title: 'Upload the latest photos',
      deadline: moment().add(2, 'days'),
      done: false
    },
    {
      id: uuid(),
      title: 'Send customer thank you card',
      deadline: moment().add(5, 'days'),
      done: false
    }
  ]
});

mock.onGet('/api/submissions/1/recentActivity').reply(200, {
  recentActivity: [
    {
      id: uuid(),
      author: {
        name: 'Jenn Broker',
        avatar: '/images/avatars/avatar_11.png'
      },
      description: 'Sent the submission out to market',
      created_at: moment().subtract(1, 'minutes')
    },
    {
      id: uuid(),
      author: {
        name: 'Abrams, JJ',
        avatar: '/images/avatars/avatar_1.png'
      },
      description: 'Viewed the submission progress',
      created_at: moment().subtract(16, 'minutes')
    },
    {
      id: uuid(),
      author: {
        name: 'Jenn Broker',
        avatar: '/images/avatars/avatar_11.png'
      },
      description: 'Uploaded 4 new documents',
      created_at: moment().subtract(4, 'hours')
    },
    {
      id: uuid(),
      author: {
        name: 'Jenn Broker',
        avatar: '/images/avatars/avatar_11.png'
      },
      description: 'Started the submission',
      created_at: moment().subtract(7, 'hours')
    }
  ]
});

mock.onGet('/api/submissions/1/rfps').reply(200, {
  rfps: [
    {
      id: '1',
      name: 'Aviva',
      logo: '//verdialliance.com/wp-content/uploads/Aviva-Insurance-Logo.jpg',
      city: 'Vancouver',
      province: 'BC',
      status: 'acknowledged',
      premium: null,
      qd: '-',
      comments: 'Did not meet timeline',
      files: [
        {
          lastModified: today.getTime(),
          lastModifiedDate: today,
          name: 'quote.pdf',
          path: 'quote.pdf',
          size: 477734,
          type: 'application/pdf',
          webkitRelativePath: ''
        },
        {
          lastModified: today.getTime(),
          lastModifiedDate: today,
          name: 'coverages.pdf',
          path: 'coverages.pdf',
          size: 477734,
          type: 'application/pdf',
          webkitRelativePath: ''
        }
      ]
    },
    {
      id: '2',
      name: 'Wawanesa',
      logo: '//logo.clearbit.com/wawanesa.com',
      city: 'Winnipeg',
      province: 'MB',
      status: 'quoted',
      premium: '$101,000',
      qd: 'Q',
      comments: 'Terms Quoted'
    },
    {
      id: '3',
      name: 'CNA',
      logo: '//logo.clearbit.com/cna.com',
      city: 'Vancouver',
      province: 'BC',
      status: 'uwassigned',
      premium: null,
      qd: '-',
      comments: 'Did not meet timeline'
    },
    {
      id: '4',
      name: 'Gore',
      logo: '//logo.clearbit.com/goremutual.ca',
      city: 'Vancouver',
      province: 'BC',
      status: 'acknowledged',
      premium: null,
      qd: '-',
      comments: 'Did not meet timeline'
    },
    {
      id: '5',
      name: 'Intact',
      logo: '//logo.clearbit.com/intact.ca',
      city: 'Vancouver',
      province: 'BC',
      status: 'quoted',
      premium: '$62,000',
      qd: 'Q',
      comments: 'Best terms quoted'
    },
    {
      id: '6',
      name: 'Travelers',
      logo: '//logo.clearbit.com/travelers.com',
      city: 'Vancouver',
      province: 'BC',
      status: 'negotiated',
      premium: '$102,000',
      qd: 'D',
      comments: 'Not within appetite'
    },
    {
      id: '7',
      name: 'Zurich',
      logo: '//logo.clearbit.com/zurich.com',
      city: 'Vancouver',
      province: 'BC',
      status: 'acknowledged',
      premium: null,
      qd: '-',
      comments: 'Did not meet timeline'
    },
    {
      id: '8',
      name: 'RSA',
      logo: '//logo.clearbit.com/rsagroup.com',
      city: 'Toronto',
      province: 'ON',
      status: 'complete',
      premium: '$75,000',
      qd: 'Q',
      comments: 'Terms quoted'
    },
    {
      id: '9',
      name: 'Northbridge Insurance',
      logo: '//logo.clearbit.com/nbins.com',
      city: 'Toronto',
      province: 'ON',
      status: 'acknowledged',
      premium: null,
      qd: '-',
      comments: 'Did not meet timeline'
    }
  ]
});

mock.onGet('/api/submissions/1/rfps/1/conversation').reply(200, {
  conversation: {
    id: uuid(),
    otherUser: {
      name: 'Adam Denisov',
      avatar: '/images/avatars/avatar_7.png',
      active: true,
      lastActivity: moment()
    },
    messages: [
      {
        id: uuid(),
        sender: {
          authUser: true,
          name: 'Jenn Broker',
          avatar: '/images/avatars/avatar_11.png'
        },
        content:
          // eslint-disable-next-line quotes
          "Hi Adam, thanks for your revised terms.  We'll review and will revert to you in due course.",
        contentType: 'text',
        created_at: moment().subtract(2, 'minutes')
      },
      {
        id: uuid(),
        sender: {
          authUser: false,
          name: 'Adam Denisov',
          avatar: '/images/avatars/avatar_7.png'
        },
        content:
          'Hi Jenn, we are pleased to present our revised terms for your review and consideration.',
        contentType: 'text',
        created_at: moment().subtract(1, 'day')
      },
      {
        id: uuid(),
        sender: {
          authUser: true,
          name: 'Jenn Broker',
          avatar: '/images/avatars/avatar_11.png'
        },
        content:
          'Call to Adam Denisov.  Updated SOV received from client.  Need to revise terms.',
        contentType: 'text',
        created_at: moment().subtract(2, 'days')
      },
      {
        id: uuid(),
        sender: {
          authUser: false,
          name: 'Adam Denisov',
          avatar: '/images/avatars/avatar_7.png'
        },
        content:
          'Hi Jenn, thanks again for sending us your new submission.  We are pleased to present our terms as attached.  Give me a call if you have any questions.  We would certainly love to win this account with you.',
        contentType: 'text',
        created_at: moment().subtract(3, 'days')
      },
      {
        id: uuid(),
        sender: {
          authUser: true,
          name: 'Jenn Broker',
          avatar: '/images/avatars/avatar_11.png'
        },
        content:
          // eslint-disable-next-line quotes
          "Hi Adam, just following up.  We are hoping to present terms to the client by May 1st so we'll need your terms no later than April 15th.",
        contentType: 'text',
        created_at: moment().subtract(12, 'days')
      },
      {
        id: uuid(),
        sender: {
          authUser: false,
          name: 'Adam Denisov',
          avatar: '/images/avatars/avatar_7.png'
        },
        content:
          // eslint-disable-next-line quotes
          "Hi Jenn, thanks for sending us your new submission.  I will be working on this file for you.  Once reviewed, I'll revert back to you if I have any further questions.",
        contentType: 'text',
        created_at: moment().subtract(19, 'days')
      },
      {
        id: uuid(),
        sender: {
          authUser: true,
          name: 'Jenn Broker'
        },
        content:
          'AUTOMATED MESSAGE: To whom it may concern, Please note that it has been 10 business days since our last correspondence and an underwriter has not yet been in touch with our office.  Please contact jennbroker@pacificinsurance.com as soon as possible.',
        contentType: 'text',
        created_at: moment().subtract(20, 'days')
      },
      {
        id: uuid(),
        sender: {
          authUser: false,
          name: 'Adam Denisov',
          avatar: '/images/avatars/avatar_7.png',
          lastActivity: moment()
        },
        content:
          'Thank you for your new submission.  Once cleared in our system, an underwriter will reach out to you.',
        contentType: 'text',
        created_at: moment().subtract(30, 'days')
      },
      {
        id: uuid(),
        sender: {
          authUser: true,
          name: 'Jenn Broker',
          avatar: '/images/avatars/avatar_11.png'
        },
        content:
          'Dear Underwriter, please find attached our new submission for your consideration?',
        contentType: 'text',
        created_at: moment().subtract(30, 'days')
      }
    ],
    unread: 0,
    created_at: moment().subtract(1, 'minute')
  }
});

mock.onGet('/api/projects/1').reply(200, {
  project: {
    title: 'Develop a PDF Export App',
    author: {
      name: 'Emilee Simchenko',
      avatar: '/images/avatars/avatar_9.png',
      bio: 'We build beautiful functional themes for web professionals'
    },
    brief: `
#### TL;DR

The primary aim of the product is to convert survery responses into PDF reports, these reports are generated on to what we call templates. This product is designer to work with 3rd party survery providers. The first MVP will integrate with TypeForm, because the's no direct way to convert results to PDF from the form people create in TypeForm and then ask users to fill out.

#### Background information

Design files are attachedin the files tab.

Develop the web app screens for our product called "PDFace". Please look at the wireframes, system activity workflow and read the section above to understand what we're trying to archive.

There's not many screens we need designed, but there will be modals and various other system triggered evenets that will need to be considered.

The project has benn created in Sketch so let me know if there are any problmes opening this project and I'll try to convert into a usable file.

I have attached a chat with our users most used devices.

#### Goals:
  - Maintainable Code
  - Easy UX
  - Readable Code
  - No Redux
    `,
    price: '12,500',
    currency: 'USD',
    tags: [
      {
        text: 'React JS',
        color: colors.indigo[600]
      }
    ],
    members: [
      {
        id: uuid(),
        name: 'Ekaterina Tankova',
        avatar: '/images/avatars/avatar_2.png',
        bio: 'Front End Developer'
      },
      {
        id: uuid(),
        name: 'Cao Yu',
        avatar: '/images/avatars/avatar_3.png',
        bio: 'UX Designer'
      },
      {
        id: uuid(),
        name: 'Anje Keizer',
        avatar: '/images/avatars/avatar_5.png',
        bio: 'Copyright'
      }
    ],
    files: [
      {
        id: uuid(),
        name: 'example-project1.jpg',
        url: '/images/projects/project_2.jpg',
        mimeType: 'image/png',
        size: 1024 * 1024 * 3
      },
      {
        id: uuid(),
        name: 'docs.zip',
        url: '#',
        mimeType: 'application/zip',
        size: 1024 * 1024 * 25
      },
      {
        id: uuid(),
        name: 'example-project2.jpg',
        url: '/images/projects/project_1.jpg',
        mimeType: 'image/png',
        size: 1024 * 1024 * 2
      }
    ],
    activities: [
      {
        id: uuid(),
        subject: 'Project owner',
        subject_type: 'user',
        action_type: 'upload_file',
        action_desc: 'has uploaded a new file',
        created_at: moment().subtract(23, 'minutes')
      },
      {
        id: uuid(),
        subject: 'Adrian Stefan',
        subject_type: 'user',
        action_type: 'join_team',
        action_desc: 'joined team as a Front-End Developer',
        created_at: moment().subtract(2, 'hours')
      },
      {
        id: uuid(),
        subject: 'Alexandru Robert',
        action_type: 'join_team',
        action_desc: 'joined team as a Full Stack Developer',
        created_at: moment().subtract(9, 'hours')
      },
      {
        id: uuid(),
        subject: 'Project owner',
        subject_type: 'user',
        action_type: 'price_change',
        action_desc: 'raised the project budget',
        created_at: moment().subtract(2, 'days')
      },
      {
        id: uuid(),
        subject: 'Contest',
        subject_type: 'project',
        action_type: 'contest_created',
        action_desc: 'created',
        created_at: moment().subtract(4, 'days')
      }
    ],
    subscribers: [
      {
        id: uuid(),
        name: 'Ekaterina Tankova',
        avatar: '/images/avatars/avatar_2.png',
        cover: '/images/covers/cover_1.jpg',
        common_connections: 12,
        labels: [
          'User Experience',
          'FrontEnd development',
          'HTML5',
          'VueJS',
          'ReactJS'
        ]
      },
      {
        id: uuid(),
        name: 'Cao Yu',
        avatar: '/images/avatars/avatar_3.png',
        cover: '/images/covers/cover_2.jpg',
        common_connections: 5,
        labels: [
          'User Interface',
          'FullStack development',
          'Angular',
          'ExpressJS'
        ]
      },
      {
        id: uuid(),
        name: 'Clarke Gillebert',
        avatar: '/images/avatars/avatar_6.png',
        cover: '/images/covers/cover_2.jpg',
        common_connections: 17,
        labels: ['BackEnd development', 'Firebase', 'MongoDB', 'ExpressJS']
      }
    ],
    deadline: moment().add(7, 'days'),
    updated_at: moment().subtract(23, 'minutes')
  }
});
