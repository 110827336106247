import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loggedIn: true,
  user: {
    first_name: 'Jenn',
    last_name: 'Broker',
    email: 'demo@devias.io',
    avatar: '/images/avatars/avatar_11.png',
    bio: 'Pacific Insurance',
    role: 'ADMIN' // ['GUEST', 'USER', 'ADMIN']
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      state.user = action.payload.user;
    }
  }
});

export const { login } = authSlice.actions;
export default authSlice.reducer;
