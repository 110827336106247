/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBrokerage = /* GraphQL */ `
  query GetBrokerage($id: ID!) {
    getBrokerage(id: $id) {
      id
      tenant
      name
      address {
        streetAddressLine1
        streetAddressLine2
        city
        postalCode
        provinceState
        country
      }
      brokers {
        items {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBrokerages = /* GraphQL */ `
  query ListBrokerages(
    $filter: ModelBrokerageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrokerages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        brokers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBroker = /* GraphQL */ `
  query GetBroker($id: ID!) {
    getBroker(id: $id) {
      id
      brokerageId
      firstName
      lastName
      email
      avatar {
        key
        type
      }
      brokerage {
        id
        tenant
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        brokers {
          nextToken
        }
        createdAt
        updatedAt
      }
      submissions {
        items {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBrokers = /* GraphQL */ `
  query ListBrokers(
    $filter: ModelBrokerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrokers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getBrokerByEmail = /* GraphQL */ `
  query GetBrokerByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelBrokerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBrokerByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCustomerContact = /* GraphQL */ `
  query GetCustomerContact($id: ID!) {
    getCustomerContact(id: $id) {
      id
      customerId
      firstName
      lastName
      email
      phoneNumber
      brokerageId
      isActive
      customer {
        id
        brokerageId
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        yearDatePolicyAmount
        currentMonthPolicyAmount
        avatar {
          key
          type
        }
        isActive
        contacts {
          nextToken
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      submissions {
        items {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerContacts = /* GraphQL */ `
  query ListCustomerContacts(
    $filter: ModelCustomerContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        firstName
        lastName
        email
        phoneNumber
        brokerageId
        isActive
        customer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerContactByEmail = /* GraphQL */ `
  query GetCustomerContactByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomerContactByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        firstName
        lastName
        email
        phoneNumber
        brokerageId
        isActive
        customer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      brokerageId
      name
      address {
        streetAddressLine1
        streetAddressLine2
        city
        postalCode
        provinceState
        country
      }
      yearDatePolicyAmount
      currentMonthPolicyAmount
      avatar {
        key
        type
      }
      isActive
      contacts {
        items {
          id
          customerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      brokerage {
        id
        tenant
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        brokers {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brokerageId
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        yearDatePolicyAmount
        currentMonthPolicyAmount
        avatar {
          key
          type
        }
        isActive
        contacts {
          nextToken
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInsurerContact = /* GraphQL */ `
  query GetInsurerContact($id: ID!) {
    getInsurerContact(id: $id) {
      id
      insurerId
      firstName
      lastName
      email
      phoneNumber
      avatar {
        key
        type
      }
      brokerageId
      isActive
      insurer {
        id
        brokerageId
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        yearDatePolicyAmount
        currentMonthPolicyAmount
        avatar {
          key
          type
        }
        isActive
        contacts {
          nextToken
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInsurerContacts = /* GraphQL */ `
  query ListInsurerContacts(
    $filter: ModelInsurerContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInsurerContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInsurerContactByEmail = /* GraphQL */ `
  query GetInsurerContactByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelInsurerContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInsurerContactByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInsurer = /* GraphQL */ `
  query GetInsurer($id: ID!) {
    getInsurer(id: $id) {
      id
      brokerageId
      name
      address {
        streetAddressLine1
        streetAddressLine2
        city
        postalCode
        provinceState
        country
      }
      yearDatePolicyAmount
      currentMonthPolicyAmount
      avatar {
        key
        type
      }
      isActive
      contacts {
        items {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      brokerage {
        id
        tenant
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        brokers {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInsurers = /* GraphQL */ `
  query ListInsurers(
    $filter: ModelInsurerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInsurers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brokerageId
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        yearDatePolicyAmount
        currentMonthPolicyAmount
        avatar {
          key
          type
        }
        isActive
        contacts {
          nextToken
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMarketingGroup = /* GraphQL */ `
  query GetMarketingGroup($id: ID!) {
    getMarketingGroup(id: $id) {
      id
      name
      brokerageId
      isActive
      insurerContacts {
        items {
          id
          marketingGroupId
          insurerContactId
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMarketingGroups = /* GraphQL */ `
  query ListMarketingGroups(
    $filter: ModelMarketingGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarketingGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        brokerageId
        isActive
        insurerContacts {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInsurerContactMarketingGroup = /* GraphQL */ `
  query GetInsurerContactMarketingGroup($id: ID!) {
    getInsurerContactMarketingGroup(id: $id) {
      id
      marketingGroupId
      insurerContactId
      isActive
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      marketingGroup {
        id
        name
        brokerageId
        isActive
        insurerContacts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInsurerContactMarketingGroups = /* GraphQL */ `
  query ListInsurerContactMarketingGroups(
    $filter: ModelInsurerContactMarketingGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInsurerContactMarketingGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        marketingGroupId
        insurerContactId
        isActive
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        marketingGroup {
          id
          name
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubmission = /* GraphQL */ `
  query GetSubmission($id: ID!) {
    getSubmission(id: $id) {
      id
      customerContactId
      brokerId
      submissionName
      projectTags
      startDate
      targetDate
      targetPremium
      files {
        key
        type
      }
      submissionDetails
      groups
      status
      additionalNote
      isVisited
      isUrgent
      customerContact {
        id
        customerId
        firstName
        lastName
        email
        phoneNumber
        brokerageId
        isActive
        customer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
      }
      rfps {
        items {
          id
          submissionId
          insurerContactId
          brokerId
          isActive
          subject
          groups
          status
          rfpResult
          comment
          quotedPremium
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSubmissions = /* GraphQL */ `
  query ListSubmissions(
    $filter: ModelSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubmissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerContactId
        brokerId
        submissionName
        projectTags
        startDate
        targetDate
        targetPremium
        files {
          key
          type
        }
        submissionDetails
        groups
        status
        additionalNote
        isVisited
        isUrgent
        customerContact {
          id
          customerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        rfps {
          nextToken
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        isActive
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSubmissionByCustomerContactId = /* GraphQL */ `
  query GetSubmissionByCustomerContactId(
    $customerContactId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubmissionByCustomerContactId(
      customerContactId: $customerContactId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerContactId
        brokerId
        submissionName
        projectTags
        startDate
        targetDate
        targetPremium
        files {
          key
          type
        }
        submissionDetails
        groups
        status
        additionalNote
        isVisited
        isUrgent
        customerContact {
          id
          customerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        rfps {
          nextToken
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        isActive
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRfp = /* GraphQL */ `
  query GetRfp($id: ID!) {
    getRfp(id: $id) {
      id
      submissionId
      insurerContactId
      brokerId
      isActive
      subject
      groups
      status
      rfpResult
      comment
      quotedPremium
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      submission {
        id
        customerContactId
        brokerId
        submissionName
        projectTags
        startDate
        targetDate
        targetPremium
        files {
          key
          type
        }
        submissionDetails
        groups
        status
        additionalNote
        isVisited
        isUrgent
        customerContact {
          id
          customerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        rfps {
          nextToken
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        isActive
        createdAt
        updatedAt
        owner
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRfps = /* GraphQL */ `
  query ListRfps(
    $filter: ModelRfpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRfps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        submissionId
        insurerContactId
        brokerId
        isActive
        subject
        groups
        status
        rfpResult
        comment
        quotedPremium
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        submission {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRfpbySubmissionId = /* GraphQL */ `
  query GetRfpbySubmissionId(
    $submissionId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRfpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRfpbySubmissionId(
      submissionId: $submissionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        submissionId
        insurerContactId
        brokerId
        isActive
        subject
        groups
        status
        rfpResult
        comment
        quotedPremium
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        submission {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getBrokerRfp = /* GraphQL */ `
  query GetBrokerRfp($id: ID!) {
    getBrokerRfp(id: $id) {
      id
      rfpId
      brokerId
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      rfp {
        id
        submissionId
        insurerContactId
        brokerId
        isActive
        subject
        groups
        status
        rfpResult
        comment
        quotedPremium
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        submission {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBrokerRfps = /* GraphQL */ `
  query ListBrokerRfps(
    $filter: ModelBrokerRfpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrokerRfps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rfpId
        brokerId
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        rfp {
          id
          submissionId
          insurerContactId
          brokerId
          isActive
          subject
          groups
          status
          rfpResult
          comment
          quotedPremium
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBrokerRfpbyRfpId = /* GraphQL */ `
  query GetBrokerRfpbyRfpId(
    $rfpId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBrokerRfpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBrokerRfpbyRfpId(
      rfpId: $rfpId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rfpId
        brokerId
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        rfp {
          id
          submissionId
          insurerContactId
          brokerId
          isActive
          subject
          groups
          status
          rfpResult
          comment
          quotedPremium
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInsurerRfp = /* GraphQL */ `
  query GetInsurerRfp($id: ID!) {
    getInsurerRfp(id: $id) {
      id
      rfpId
      insurerContactId
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      rfp {
        id
        submissionId
        insurerContactId
        brokerId
        isActive
        subject
        groups
        status
        rfpResult
        comment
        quotedPremium
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        submission {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInsurerRfps = /* GraphQL */ `
  query ListInsurerRfps(
    $filter: ModelInsurerRfpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInsurerRfps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rfpId
        insurerContactId
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        rfp {
          id
          submissionId
          insurerContactId
          brokerId
          isActive
          subject
          groups
          status
          rfpResult
          comment
          quotedPremium
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInsurerRfpbyRfpId = /* GraphQL */ `
  query GetInsurerRfpbyRfpId(
    $rfpId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInsurerRfpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInsurerRfpbyRfpId(
      rfpId: $rfpId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rfpId
        insurerContactId
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        rfp {
          id
          submissionId
          insurerContactId
          brokerId
          isActive
          subject
          groups
          status
          rfpResult
          comment
          quotedPremium
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getError = /* GraphQL */ `
  query GetError($id: ID!) {
    getError(id: $id) {
      id
      objId
      errorMessage
      createdAt
      updatedAt
    }
  }
`;
export const listErrors = /* GraphQL */ `
  query ListErrors(
    $filter: ModelErrorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listErrors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        objId
        errorMessage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getThread = /* GraphQL */ `
  query GetThread($id: ID!) {
    getThread(id: $id) {
      id
      rfpId
      insurerContactId
      brokerId
      isActive
      message
      isInQueue
      attachments {
        key
        type
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listThreads = /* GraphQL */ `
  query ListThreads(
    $filter: ModelThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rfpId
        insurerContactId
        brokerId
        isActive
        message
        isInQueue
        attachments {
          key
          type
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getThreadByRfpId = /* GraphQL */ `
  query GetThreadByRfpId(
    $rfpId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getThreadByRfpId(
      rfpId: $rfpId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rfpId
        insurerContactId
        brokerId
        isActive
        message
        isInQueue
        attachments {
          key
          type
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
