import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

const ConfirmationDialog = ({
  cancelButtonText,
  deleteButtonText,
  text,
  title,
  onClose,
  onDelete,
  ...other
}) => {
  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      aria-labelledby="confirmation-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      {...other}>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          autoFocus
          color="primary"
          onClick={handleCancel}>
          {cancelButtonText}
        </Button>
        <Button 
          color="secondary"
          onClick={onDelete}>
          {deleteButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  cancelButtonText: PropTypes.string.isRequired,
  deleteButtonText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ConfirmationDialog;
