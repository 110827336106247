import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { AutoComplete } from 'components/Customer';
import { customerInitialValues } from 'views/Customers/Customers';
import { useFormikContext } from 'formik';
import { useFetchContinuously } from 'hooks';
import {
  useCreateCustomerContactMutation,
  useGetCustomerContactByIdQuery,
  useLazyGetCustomerContactsQuery
} from 'redux/slices/customer/customerSlice';
import { useAuthProviderContext } from 'context/AuthContextProvider';
import { useCreateCustomerMutation } from 'redux/slices/customer/companySlice';
import { _get } from 'utils/helper';
import clsx from 'clsx';
import { AddCustomer, formData } from 'views/Customers/components';
import { Header, ConfirmationDialog } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {},
  AboutSubmissionAction: {
    marginTop: theme.spacing(3)
  },
  aboutCustomer: {
    marginTop: theme.spacing(3)
  },
  aboutSubmission: {
    marginTop: theme.spacing(3)
  },
  addCustomerGrid: {
    marginTop: theme.spacing(-9),
    marginBottom: theme.spacing(2)
  }
}));

const AboutCustomer = props => {
  const { className, customerId, ...rest } = props;

  const classes = useStyles();
  const { data } = useGetCustomerContactByIdQuery({ id: customerId });

  const [customer, setCustomer] = useState(null);
  const [selectedContact, setSelectedContact] = useState([]);
  const [contactList, setContactList] = useState([]);
  const customerContactFilter = useRef({ isActive: { eq: true } });
  const { resultData } = useFetchContinuously(
    useLazyGetCustomerContactsQuery,
    customerContactFilter.current
  );
  const [showDialog, setShowDialog] = useState(false);

  const [openAutoCompletet, setpOenAutoCompletet] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const { getUserGroup } = useAuthProviderContext();
  const [createCustomerContact] = useCreateCustomerContactMutation();
  const [createCustomer] = useCreateCustomerMutation();
  const {
    values,
    setFieldValue,
    touched,
    errors,
    getFieldProps
  } = useFormikContext();

  useEffect(() => {
    setContactList(resultData);
  }, [resultData]);
  useEffect(() => {
    if (data) {
      setCustomer(data);
      setFieldValue('selectedCustomer', 'customer selected');
      setFieldValue('customer', data);
    }
  }, [data]);

  const handleAddCustomer = useCallback(() => {
    setOpenAddModal(prev => !prev);
  }, [setOpenAddModal]);

  const handleClose = useCallback(() => {
    setOpenAddModal(false);
  }, [setOpenAddModal]);
  const handleOpenAutoComplete = () => {
    setpOenAutoCompletet(true);
  };
  const closeAutoComplete = () => {
    setpOenAutoCompletet(false);
  };
  const createExistingCustomerMutation = async (values, customer) => {
    const customerContactInput = {
      customerId: customer.id,
      email: values.email,
      phoneNumber: values.phone,
      firstName: values.firstName,
      lastName: values.lastName,
      isActive: values.isActive,
      brokerageId: getUserGroup()
    };
    const createdCustomer = await createCustomerContact({
      input: customerContactInput
    });
    setCustomer(createdCustomer.data.data.createCustomerContact);
    setFieldValue('selectedCustomer', 'customer selected');
    setFieldValue('customer', createdCustomer.data.data.createCustomerContact);
  };
  const handleAddCustomerSubmission = async (values, { resetForm }) => {
    try {
      await handleCreateCustomer(values);
      resetForm();
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreateCustomer = async values => {
    const customerInput = {
      name: values.name,
      address: values.address,
      avatar: values.avatar,
      brokerageId: getUserGroup()
    };
    const customerResponse = await createCustomer({ input: customerInput });
    const customer = _get(customerResponse, 'data.data.createCustomer', null);
    if (customer) {
      await createExistingCustomerMutation(values, customer);
    }
  };

  const handleSelected = (_, value) => {
    setCustomer(value);
    setFieldValue('customer', value);

    if (value) {
      const contacts = contactList.filter(contact => contact.id !== value.id);
      setContactList(contacts);
      setSelectedContact([...selectedContact, value]);
    }
  };
  const handleClearCustomer = () => {
    setShowDialog(false);
    setCustomer(null);
    setFieldValue('customer', undefined);
  };
  const handleCloseDialog = () => setShowDialog(false);
  const handleOpenDialog = () => setShowDialog(true);

  return (
    <>
      <Header customer={customer} onClearCustomer={handleOpenDialog} />
      {!customer && (
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader title="Who's the customer?" />
          <CardContent>
            <Grid
              alignItems="flex-end"
              container
              justify="flex-end"
              spacing={3}
              className={classes.addCustomerGrid}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleAddCustomer}>
                  Add customer
                </Button>
              </Grid>
            </Grid>
            <AddCustomer
              initialValues={customerInitialValues}
              onClose={handleClose}
              open={openAddModal}
              formData={formData}
              onSubmit={handleAddCustomerSubmission}
              customerContacts={resultData}
            />
            <AutoComplete
              required
              value={values.selectedCustomer}
              closeAutoComplete={closeAutoComplete}
              formikProps={getFieldProps('selectedCustomer')}
              onInputChange={(_, value) =>
                setFieldValue('selectedCustomer', value)
              }
              error={
                touched['selectedCustomer'] && !!errors['selectedCustomer']
              }
              helperText={
                touched['selectedCustomer'] && errors['selectedCustomer']
              }
              handleCompanyAutoCompleteName={handleSelected}
              label={'Customer'}
              filterCallback={(options, state) =>
                options?.filter(
                  option =>
                    `${option.firstName} ${option.lastName}`
                      .toLowerCase()
                      .includes(state.inputValue) ||
                    option.customer?.name
                      ?.toLowerCase()
                      .includes(state.inputValue)
                )
              }
              handleOpenAutoComplete={handleOpenAutoComplete}
              getOptionLabel={option =>
                option ? option.firstName + ' ' + option.lastName : ''
              }
              openAutoCompletet={openAutoCompletet}
              options={contactList}
              autoComplete="new-password"
            />
          </CardContent>
        </Card>
      )}
      <ConfirmationDialog
        cancelButtonText="Cancel"
        deleteButtonText="Remove"
        text="Are you sure you want to remove this customer?"
        title="Remove Customer"
        open={showDialog}
        onClose={handleCloseDialog}
        onDelete={handleClearCustomer}
      />
    </>
  );
};

AboutCustomer.propTypes = {
  className: PropTypes.string
};

export default AboutCustomer;
