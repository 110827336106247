import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { DashboardMetric } from '../../views/DashboardHome/components';

import gradients from 'utils/gradients';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundImage: gradients.green,
    height: 48,
    width: 48
  }
}));

const FinancialMetric = props => {
  const { className, label, value, currency, ...rest } = props;

  const classes = useStyles();

  return (
    <DashboardMetric
      {...rest}
      className={className}
      label={label}
      value={
        <>
          {currency}
          {value}
        </>
      }
      icon={
        <Avatar className={classes.avatar}>
          <AttachMoneyIcon />
        </Avatar>
      }
    />
  );
};

FinancialMetric.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  currency: PropTypes.string
};

FinancialMetric.defaultProps = {
  currency: '$'
};

export default FinancialMetric;
