import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';

import { useAuthProviderContext } from '../../context/AuthContextProvider';
import { authRoutes } from '../../routes';
import {
  NavBar,
  TopBar
  //  ChatBar
} from './components';
// import { matchPath } from 'react-router';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  topBar: {
    zIndex: 2,
    position: 'relative'
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto'
  }
}));

const AuthenticatedLayout = props => {
  const { location, route } = props;

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const { isLoggedIn } = useAuthProviderContext();

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  // const match = matchPath(`${props.location.pathname}`, {
  //   path: '/submissions/:id/:tabs/:subId',
  //   exact: true,
  //   strict: false
  // });
  const loginPage = (
    <Redirect
      to={{
        pathname: authRoutes.login,
        search: location.search,
        state: { referer: !isLoggedIn ? props.location : null }
      }}
    />
  );

  const authLayout = (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </main>
      </div>
      {/* {!match && <ChatBar />} */}
    </div>
  );

  // check the user is logged in via AuthContext, if not redirect to login
  if (isLoggedIn !== undefined) {
    return isLoggedIn ? authLayout : loginPage;
  } else {
    return null;
  }
};

AuthenticatedLayout.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string // query string passed in via React Router
  }),
  route: PropTypes.object
};

export default AuthenticatedLayout;
