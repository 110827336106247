import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { i18n } from '../../../../localization/i18n';
import { translation } from '../../../../localization/translation';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Header = props => {
  const { className, handleAddCustomer, ...rest } = props;
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            {i18n.t(translation.customers.metaData.title)}
          </Typography>
          <Typography component="h1" variant="h3">
            {i18n.t(translation.customers.metaData.directory)}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={handleAddCustomer}>
            {i18n.t(translation.customers.actions.addCustomerButton.text)}
          </Button>
          {/* <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <Typography variant="h6" className={classes.typography}>
              {i18n.t(translation.customers.actions.addCustomerButton.actionPopOverText1)}
              <br />
              {i18n.t(translation.customers.actions.addCustomerButton.actionPopOverText2)}
            </Typography>
          </Popover> */}
        </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
