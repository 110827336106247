import React, { createContext, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { authRoutes } from 'routes';

const AuthContext = createContext();

export const useAuthProviderContext = () => {
  return useContext(AuthContext);
};

const AuthContextProvider = props => {
  const [userContext, setUserContext] = useState();
  const history = useHistory();

  useEffect(() => {
    getUserContext();
  }, []);

  const getUserContext = async () => {
    let user;
    let isLoggedIn = false;
    try {
      user = await Auth.currentAuthenticatedUser();
      isLoggedIn = true;
    } catch (e) {
      console.error(e);
    }

    setUserContext({
      isLoggedIn: isLoggedIn,
      user: user
    });
  };

  const handleLogout = async () => {
    await Auth.signOut();
    setUserContext({ user: null, serviceTypes: null, isLoggedIn: false });
    history.push(authRoutes.logout);
  };

  // const handleLogin = (userName, password) => {
  //   return new Promise((resolve, reject) => {
  //     Auth.signIn(userName, password)
  //       .then(data => {
  //         log.info(JSON.stringify(data));
  //         getUserContext();
  //         resolve();
  //       })
  //       .catch(e => {
  //         reject(e);
  //       });
  //   });
  // };
  const handleLogin = async (userName, password) => {
    await Auth.signIn(userName, password);
    getUserContext();
  };

  const getUserGroup = () => {
    console.log(
      'userContext.user.signInUserSession.accessToken.payload',
      userContext && userContext.user
    );
    return (
      userContext &&
      userContext.user &&
      userContext.user.signInUserSession.accessToken.payload[
        'cognito:groups'
      ][0]
    );
  };
  const getUserGroups = () => {
    return (
      userContext &&
      userContext.user &&
      userContext.user.signInUserSession.accessToken.payload['cognito:groups']
    );
  };
  const getUser = () => {
    return userContext?.user;
  };

  return (
    <AuthContext.Provider
      value={{
        ...userContext,
        refreshUserContext: getUserContext,
        getUserGroup,
        getUser,
        getUserGroups,
        login: handleLogin,
        logout: handleLogout
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContextProvider;
