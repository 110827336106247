import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { GenericMoreButton } from 'components';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'utils/axios';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 900
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const RecentCustomers = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [latestCustomers, setLatestCustomers] = useState([]);

  useEffect(() => {
    let mounted = true;

    const fetchLatestCustomers = () => {
      axios.get('/api/dashboard/latest-customers').then(response => {
        if (mounted) {
          setLatestCustomers(response.data.customers);
        }
      });
    };

    fetchLatestCustomers();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader action={<GenericMoreButton />} title="Recent customers" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar options={{ suppressScrollY: true }}>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection="desc">
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel active direction="desc">
                        Company
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {latestCustomers.map(customer => (
                  <TableRow hover key={customer.id}>
                    <TableCell>
                      <div className={classes.nameCell}>
                        <Avatar
                          className={classes.avatar}
                          src={customer.avatar}></Avatar>
                        <div>
                          <Link
                            color="inherit"
                            component={RouterLink}
                            to="/customers/1/summary"
                            variant="h6">
                            {customer.name}
                          </Link>
                          <div>
                            {customer.address.city}, {customer.address.province}
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{customer.contactName}</TableCell>
                    <TableCell>{customer.phoneNumber}</TableCell>
                    <TableCell>{customer.emailAddress}</TableCell>
                    <TableCell>
                      {moment(customer.joinedDate).format('MMM D, YYYY')}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        component={RouterLink}
                        size="small"
                        to="/customers/1/summary"
                        variant="outlined">
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to="/management/projects"
          variant="text">
          See all
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </Button>
      </CardActions>
    </Card>
  );
};

RecentCustomers.propTypes = {
  className: PropTypes.string
};

export default RecentCustomers;
