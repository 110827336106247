export const submissionDetails = {
  blocks: [
    {
      key: 'co2tb',
      text:
        'Conveniently located on the quiet side of Metrotown, the Kin Collection gives residents the luxury of living in a family-friendly, walkable neighbourhood with the accessibility of a modern urban hub. Connected by pathways, courtyards, and lush landscaping, its’ collection of 4 different home styles give everyone, at every stage of life, an incredible place to call their own.',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    }
  ],
  entityMap: {}
};

const today = new Date();
export const submissionInitialValues = {
  name: 'Kin Collection 7470 Buller Ave, Burnaby, BC',
  targetPremium: '59,000.00',
  submissionDetails,
  tags: ['Commercial Realty', 'Location 11'],
  files: [
    {
      lastModified: today.getTime(),
      lastModifiedDate: today,
      name: 'submission.pdf',
      path: 'submission.pdf',
      size: 477734,
      type: 'application/pdf',
      webkitRelativePath: ''
    }
  ],
  // mock ids are re-generated on lock, temporarily use name
  marketingGroup: 'Preferred'
};
