import React from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/styles';
import { S3Avatar } from '../../S3Avatar';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  dropzone: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    border: '3px dashed #eeeeee',
    backgroundColor: '#fafafa',
    color: '#333',
    marginBottom: '20px',
    maxWidth: '350px',
    margin: '0 auto'
  }
}));

const FileDropZone = ({ onDrop, s3Key, id, disabled = false }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, id)
  });
  const classes = useStyles();

  return (
    <div {...getRootProps({ className: classes.dropzone })}>
      <input disabled={disabled} {...getInputProps()} />
      {isDragActive ? (
        <p>Drag 'n' drop some files here, or click to select files</p>
      ) : (
        <S3Avatar s3key={s3Key} />
      )}
    </div>
  );
};

export default FileDropZone;


FileDropZone.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onDrag: PropTypes.func,
  s3Key: PropTypes.string,
};

FileDropZone.defaultProps = {
  onDrag: () => {}
};