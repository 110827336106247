import {
  apiSlice,
  CUSTOMER_CONTACT_LIST_TAG,
  CUSTOMER_CONTACT_TAG
} from '../apiSlice';
import { createMutation, createQuery } from '../commons';
import { createCustomerContact } from '../../../graphql/mutations';
import { updateCustomerContact } from '../../../graphql/custom-mutation';
import {
  listCustomerContacts,
  getCustomerContact
} from 'graphql/custom-queries';

const customerEndpoints = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCustomerContacts: builder.query(
      createQuery(listCustomerContacts, 'listCustomerContacts', [
        CUSTOMER_CONTACT_LIST_TAG
      ])
    ),
    getCustomerContactById: builder.query(
      createQuery(getCustomerContact, 'getCustomerContact', [
        CUSTOMER_CONTACT_TAG
      ])
    ),
    createCustomerContact: builder.mutation(
      createMutation(createCustomerContact, [CUSTOMER_CONTACT_LIST_TAG])
    ),
    updateCustomerContact: builder.mutation(
      createMutation(updateCustomerContact, [
        CUSTOMER_CONTACT_LIST_TAG,
        CUSTOMER_CONTACT_TAG
      ])
    )
  })
});

export const {
  useLazyGetCustomerContactsQuery,
  useGetCustomerContactByIdQuery,
  useCreateCustomerContactMutation,
  useUpdateCustomerContactMutation
} = customerEndpoints;
