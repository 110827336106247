import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import { DashboardMetric } from '../../views/DashboardHome/components';

import gradients from 'utils/gradients';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundImage: gradients.red,
    height: 48,
    width: 48
  }
}));

const DeclinedMetric = props => {
  const { className, label, value, ...rest } = props;

  const classes = useStyles();

  return (
    <DashboardMetric
      {...rest}
      className={className}
      label={label}
      value={value}
      icon={
        <Avatar className={classes.avatar}>
          <BlockIcon />
        </Avatar>
      }
    />
  );
};

DeclinedMetric.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default DeclinedMetric;
