import { useState } from 'react';

const usePagination = (pageLength = 10) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageLength);

  const getPageDataParams = () => {
    return [page * rowsPerPage, (page * rowsPerPage) + rowsPerPage];
  }

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  return {
    page,
    rowsPerPage,
    getPageDataParams,
    handleChangePage,
    handleChangeRowsPerPage
  }
}

export default usePagination;