import uuid from 'uuid/v1';
import moment from 'moment';
import mock from 'utils/mock';

mock.onGet('/api/insurers').reply(200, {
  insurers: [
    {
      id: uuid(),
      contactName: 'Chandler, Wes',
      name: 'Aviva',
      avatar: '//verdialliance.com/wp-content/uploads/Aviva-Insurance-Logo.jpg',
      spent: '98,230.00',
      currentMonth: '11,223.00',
      currency: '$',
      type: 'Personal',
      submissions: '25',
      joinedDate: new Date('Aug 13, 2019'),
      phoneNumber: '604-661-5362',
      emailAddress: 'info@aviva.ca',
      address: {
        city: 'Vancouver',
        province: 'British Columbia'
      }
    },
    {
      id: uuid(),
      contactName: 'Schrute, Dwight',
      name: 'CNA',
      avatar: '//logo.clearbit.com/cna.com',
      spent: '4,022.30',
      currentMonth: '225.23',
      currency: '$',
      type: 'Commercial',
      submissions: '23',
      joinedDate: new Date('Nov 11, 2019'),
      phoneNumber: '403-661-5362',
      emailAddress: 'smith@cna.com',
      address: {
        city: 'Vancouver',
        province: 'British Columbia'
      }
    },
    {
      id: uuid(),
      contactName: 'Halpert, Jim',
      name: 'Gore',
      avatar: '//logo.clearbit.com/goremutual.ca',
      spent: '56,202.20',
      currentMonth: '31,978.25',
      currency: '$',
      type: 'Commercial/Personal',
      submissions: '10',
      joinedDate: new Date('Sep 23, 2019'),
      phoneNumber: '778-661-5362',
      emailAddress: 'help@goremutual.com',
      address: {
        city: 'Vancouver',
        province: 'British Columbia'
      }
    },
    {
      id: uuid(),
      contactName: 'Batton, Creed',
      name: 'Intact',
      avatar: '//logo.clearbit.com/intact.ca',
      spent: '54,299.10',
      currentMonth: '21,633.00',
      currency: '$',
      type: 'Personal',
      submissions: '23',
      joinedDate: new Date('Sep 23, 2019'),
      phoneNumber: '604-878-2457',
      emailAddress: 'quote@intact.ca',
      address: {
        city: 'Vancouver',
        province: 'British Columbia'
      }
    },
    {
      id: uuid(),
      contactName: 'Bernard, Andy',
      name: 'Northbridge Insurance',
      avatar: '//logo.clearbit.com/nbins.com',
      spent: '87,309.37',
      currentMonth: '53,086.00',
      currency: '$',
      type: 'Commercial/Personal',
      submissions: '12',
      joinedDate: new Date('Sep 23, 2019'),
      phoneNumber: '476-245-7745',
      emailAddress: 'info@nbins.com',
      address: {
        city: 'Toronto',
        province: 'ON'
      }
    },
    {
      id: uuid(),
      contactName: 'Malone, Kevin',
      name: 'RSA',
      avatar: '//logo.clearbit.com/rsagroup.com',
      spent: '23,983.20',
      currentMonth: '2,987.00',
      currency: '$',
      type: 'Commercial/Personal',
      submissions: '12',
      joinedDate: new Date('Sep 23, 2019'),
      phoneNumber: '476-787-8424',
      emailAddress: 'quote@rsagroup.com',
      address: {
        city: 'Toronto',
        province: 'ON'
      }
    },
    {
      id: uuid(),
      contactName: 'Levinson, Jan',
      name: 'Travelers',
      avatar: '//logo.clearbit.com/travelers.com',
      spent: '1,009.20',
      currentMonth: '0.00',
      currency: '$',
      type: 'Travel',
      submissions: '4',
      joinedDate: new Date('Sep 23, 2019'),
      phoneNumber: '604-788-8788',
      emailAddress: 'info@travelers.com',
      address: {
        city: 'Vancouver',
        province: 'British Columbia'
      }
    },
    {
      id: uuid(),
      contactName: 'Beasley, Pam',
      name: 'Wawanesa',
      avatar: '//logo.clearbit.com/wawanesa.com',
      spent: '24,232.50',
      currentMonth: '3,208.00',
      currency: '$',
      type: 'Commercial/Personal',
      submissions: '23',
      joinedDate: new Date('Aug 15, 2019'),
      phoneNumber: '605-231-2322',
      emailAddress: 'sales@wawanesa.com',
      address: {
        city: 'Winnipeg',
        province: 'MB'
      }
    },
    {
      id: uuid(),
      contactName: 'Hannon, Erin',
      name: 'Zurich',
      avatar: '//logo.clearbit.com/zurich.com',
      spent: '63,209.40',
      currentMonth: '36,983.00',
      currency: '$',
      type: 'Commercial',
      submissions: '14',
      joinedDate: new Date('Sep 23, 2019'),
      phoneNumber: '604-787-8424',
      emailAddress: 'inquiry@zurich.com',
      address: {
        city: 'Vancouver',
        province: 'British Columbia'
      }
    }
  ]
});

mock.onGet('/api/insurers/1/summary').reply(200, {
  summary: {
    id: uuid(),
    contactName: 'Chandler, Wes',
    name: 'Aviva',
    avatar: '//verdialliance.com/wp-content/uploads/Aviva-Insurance-Logo.jpg',
    spent: '98,230.00',
    currentMonth: '11,223.00',
    currency: '$',
    type: 'Personal',
    submissions: '25',
    joinedDate: new Date('Aug 13, 2019'),
    phoneNumber: '604-661-5362',
    emailAddress: 'info@aviva.ca',
    address: {
      city: 'Vancouver',
      province: 'British Columbia'
    }
  }
});

mock.onGet('/api/insurers/1/connections/primaryContact').reply(200, {
  id: uuid(),
  firstName: 'Wes',
  lastName: 'Chandler',
  role: 'Business Development Manager',
  avatar: '//upload.wikimedia.org/wikipedia/en/d/dc/MichaelScott.png',
  address: {
    name: 'Aviva West Regional Office',
    addressLine1: '1111 1st Avenue',
    city: 'Vancouver',
    province: 'BC',
    postalCode: 'V01 1X0'
  },
  email: 'wchandeler@aviva.com',
  phone: '+1-604-999-9999',
  recentActivity: [
    {
      id: uuid(),
      description: 'Logged off',
      created_at: moment().subtract(1, 'minutes')
    },
    {
      id: uuid(),
      description: 'Viewed the submission progress',
      created_at: moment().subtract(16, 'minutes')
    },
    {
      id: uuid(),
      description: 'Uploaded 4 new documents',
      created_at: moment().subtract(4, 'hours')
    }
  ]
});

mock.onGet('/api/insurers/1/connections/underwriters').reply(200, {
  underwriters: [
    {
      id: '1',
      firstName: 'Ella',
      lastName: 'Salamat',
      role: 'Senior Underwriter',
      email: 'ella.salamat@aviva.com',
      avatar: '/images/avatars/avatar_2.png',
      address: {
        name: 'Aviva National Office',
        addressLine1: '1125 Howe St',
        city: 'Vancouver',
        province: 'BC',
        postalCode: 'V01 1X0'
      },
      phone: '+1-604-999-9999',
      recentActivity: [
        {
          id: uuid(),
          description: 'Logged off',
          created_at: moment().subtract(1, 'minutes')
        },
        {
          id: uuid(),
          description: 'Viewed the submission progress',
          created_at: moment().subtract(16, 'minutes')
        },
        {
          id: uuid(),
          description: 'Uploaded 4 new documents',
          created_at: moment().subtract(4, 'hours')
        }
      ]
    },
    {
      id: '2',
      firstName: 'Phil',
      lastName: 'Reimer',
      role: 'Underwriter',
      email: 'phil.reimer@aviva.com',
      avatar: '/images/avatars/avatar_3.png',
      address: {
        name: 'Aviva West Regional Office',
        addressLine1: '1111 1st Avenue',
        city: 'Vancouver',
        province: 'BC',
        postalCode: 'V01 1X0'
      },
      phone: '+1-604-999-9999',
      recentActivity: [
        {
          id: uuid(),
          description: 'Logged off',
          created_at: moment().subtract(1, 'minutes')
        },
        {
          id: uuid(),
          description: 'Viewed the submission progress',
          created_at: moment().subtract(16, 'minutes')
        },
        {
          id: uuid(),
          description: 'Uploaded 4 new documents',
          created_at: moment().subtract(4, 'hours')
        }
      ]
    },
    {
      id: '3',
      firstName: 'Alan',
      lastName: 'Yung',
      role: 'Underwriter',
      email: 'alan.yung@aviva.com',
      avatar: '/images/avatars/avatar_4.png',
      address: {
        name: 'Aviva West Regional Office',
        addressLine1: '1111 1st Avenue',
        city: 'Vancouver',
        province: 'BC',
        postalCode: 'V01 1X0'
      },
      phone: '+1-604-999-9999',
      recentActivity: [
        {
          id: uuid(),
          description: 'Logged off',
          created_at: moment().subtract(1, 'minutes')
        },
        {
          id: uuid(),
          description: 'Viewed the submission progress',
          created_at: moment().subtract(16, 'minutes')
        },
        {
          id: uuid(),
          description: 'Uploaded 4 new documents',
          created_at: moment().subtract(4, 'hours')
        }
      ]
    },
    {
      id: '4',
      firstName: 'Peter',
      lastName: 'Parker',
      role: 'Producer',
      email: 'peter.parker@aviva.com',
      avatar: '/images/avatars/avatar_5.png',
      address: {
        name: 'Aviva East Regional Office',
        addressLine1: '100 King Street W',
        city: 'Toronto',
        province: 'ON',
        postalCode: 'M5X 1C7'
      },
      phone: '+1-800-387-4518',
      recentActivity: [
        {
          id: uuid(),
          description: 'Logged off',
          created_at: moment().subtract(1, 'minutes')
        },
        {
          id: uuid(),
          description: 'Viewed the submission progress',
          created_at: moment().subtract(16, 'minutes')
        },
        {
          id: uuid(),
          description: 'Uploaded 4 new documents',
          created_at: moment().subtract(4, 'hours')
        }
      ]
    },
    {
      id: '5',
      firstName: 'Marsha',
      lastName: 'Kent',
      role: 'Producer',
      email: 'marsha.kent@aviva.com',
      avatar: '/images/avatars/avatar_6.png',
      address: {
        name: 'Aviva West Regional Office',
        addressLine1: '1111 1st Avenue',
        city: 'Vancouver',
        province: 'BC',
        postalCode: 'V01 1X0'
      },
      phone: '+1-604-999-9999',
      recentActivity: [
        {
          id: uuid(),
          description: 'Logged off',
          created_at: moment().subtract(1, 'minutes')
        },
        {
          id: uuid(),
          description: 'Viewed the submission progress',
          created_at: moment().subtract(16, 'minutes')
        },
        {
          id: uuid(),
          description: 'Uploaded 4 new documents',
          created_at: moment().subtract(4, 'hours')
        }
      ]
    }
  ]
});

mock.onGet('/api/insurers/marketingGroups').reply(200, {
  marketingGroups: [
    {
      id: uuid(),
      name: 'Marketing Group 1',
      members: [
        {
          id: uuid(),
          name: 'Wawanesa',
          logo: '//logo.clearbit.com/wawanesa.com',
          city: 'Winnipeg',
          province: 'MB'
        },
        {
          id: uuid(),
          name: 'Gore',
          logo: '//logo.clearbit.com/goremutual.ca',
          city: 'Vancouver',
          province: 'BC'
        },
        {
          id: uuid(),
          name: 'Intact',
          logo: '//logo.clearbit.com/intact.ca',
          city: 'Vancouver',
          province: 'BC'
        },
        {
          id: uuid(),
          name: 'Travelers',
          logo: '//logo.clearbit.com/travelers.com',
          city: 'Vancouver',
          province: 'BC'
        },
        {
          id: uuid(),
          name: 'RSA',
          logo: '//logo.clearbit.com/rsagroup.com',
          city: 'Toronto',
          province: 'ON'
        }
      ]
    },
    {
      id: uuid(),
      name: 'Marketing Group 2',
      members: [
        {
          id: uuid(),
          name: 'Aviva',
          logo:
            '//verdialliance.com/wp-content/uploads/Aviva-Insurance-Logo.jpg',
          city: 'Vancouver',
          province: 'BC'
        },
        {
          id: uuid(),
          name: 'Wawanesa',
          logo: '//logo.clearbit.com/wawanesa.com',
          city: 'Winnipeg',
          province: 'MB'
        },
        {
          id: uuid(),
          name: 'CNA',
          logo: '//logo.clearbit.com/cna.com',
          city: 'Vancouver',
          province: 'BC'
        },
        {
          id: uuid(),
          name: 'Gore',
          logo: '//logo.clearbit.com/goremutual.ca',
          city: 'Vancouver',
          province: 'BC'
        },
        {
          id: uuid(),
          name: 'Intact',
          logo: '//logo.clearbit.com/intact.ca',
          city: 'Vancouver',
          province: 'BC'
        },
        {
          id: uuid(),
          name: 'Travelers',
          logo: '//logo.clearbit.com/travelers.com',
          city: 'Vancouver',
          province: 'BC'
        },
        {
          id: uuid(),
          name: 'Zurich',
          logo: '//logo.clearbit.com/zurich.com',
          city: 'Vancouver',
          province: 'BC'
        },
        {
          id: uuid(),
          name: 'RSA',
          logo: '//logo.clearbit.com/rsagroup.com',
          city: 'Toronto',
          province: 'ON'
        },
        {
          id: uuid(),
          name: 'Northbridge Insurance',
          logo: '//logo.clearbit.com/nbins.com',
          city: 'Toronto',
          province: 'ON'
        }
      ]
    },
    {
      id: uuid(),
      name: 'Marketing Group 3',
      members: [
        {
          id: uuid(),
          name: 'Aviva',
          logo:
            '//verdialliance.com/wp-content/uploads/Aviva-Insurance-Logo.jpg',
          city: 'Vancouver',
          province: 'BC'
        },
        {
          id: uuid(),
          name: 'CNA',
          logo: '//logo.clearbit.com/cna.com',
          city: 'Vancouver',
          province: 'BC'
        },
        {
          id: uuid(),
          name: 'Zurich',
          logo: '//logo.clearbit.com/zurich.com',
          city: 'Vancouver',
          province: 'BC'
        },
        {
          id: uuid(),
          name: 'RSA',
          logo: '//logo.clearbit.com/rsagroup.com',
          city: 'Toronto',
          province: 'BC'
        },
        {
          id: uuid(),
          name: 'Northbridge Insurance',
          logo: '//logo.clearbit.com/nbins.com',
          city: 'Toronto',
          province: 'ON'
        }
      ]
    }
  ]
});
