/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const scale = 1 / 0.375;

const TrafficLight = ({
  onRedClick,
  onYellowClick,
  onGreenClick,
  RedOn,
  YellowOn,
  GreenOn,
  Size,
  BlackColor,
  DisabledColor,
  RedColor,
  YellowColor,
  GreenColor,
  Horizontal,
  Flip,
  ...props
}) => (
  <svg
    width={`${Size * (Horizontal ? scale : 1)}px`}
    height={`${Size * (Horizontal ? 1 : scale)}px`}
    viewBox={Horizontal ? '0 0 160 60' : '0 0 60 160'}
    version="1.1"
    {...props}>
    <defs>
      <circle
        style={{ cursor: onRedClick ? 'pointer' : undefined }}
        id="redCirclePath"
        cx="30"
        cy={Flip ? '130' : '30'}
        r="20"
      />
      <circle
        style={{ cursor: onYellowClick ? 'pointer' : undefined }}
        id="yellowCirclePath"
        cx="30"
        cy="80"
        r="20"
      />
      <circle
        style={{ cursor: onGreenClick ? 'pointer' : undefined }}
        id="greenCirclePath"
        cx="30"
        cy={Flip ? '30' : '130'}
        r="20"
      />
    </defs>
    <g transform={Horizontal ? 'rotate(-90 30 30)' : undefined}>
      <rect fill={BlackColor} x="0" y="0" width="60" height="160" rx="8" />

      <use
        fill={RedOn ? RedColor : DisabledColor}
        fillRule="evenodd"
        xlinkHref="#redCirclePath"
      />
      <use
        fill={YellowOn ? YellowColor : DisabledColor}
        fillRule="evenodd"
        xlinkHref="#yellowCirclePath"
      />
      <use
        fill={GreenOn ? GreenColor : DisabledColor}
        fillRule="evenodd"
        xlinkHref="#greenCirclePath"
      />

      <use
        onClick={onRedClick}
        fill="black"
        fillOpacity="1"
        filter="url(#shadowFilter)"
        xlinkHref="#redCirclePath"
      />
      <use
        onClick={onYellowClick}
        fill="black"
        fillOpacity="1"
        filter="url(#shadowFilter)"
        xlinkHref="#yellowCirclePath"
      />
      <use
        onClick={onGreenClick}
        fill="black"
        fillOpacity="1"
        filter="url(#shadowFilter)"
        xlinkHref="#greenCirclePath"
      />
    </g>
  </svg>
);

TrafficLight.propTypes = {
  onRedClick: PropTypes.func,
  onYellowClick: PropTypes.func,
  onGreenClick: PropTypes.func,
  RedOn: PropTypes.bool,
  YellowOn: PropTypes.bool,
  GreenOn: PropTypes.bool,
  Size: PropTypes.number,
  BlackColor: PropTypes.string,
  DisabledColor: PropTypes.string,
  RedColor: PropTypes.string,
  YellowColor: PropTypes.string,
  GreenColor: PropTypes.string,
  Horizontal: PropTypes.bool,
  Flip: PropTypes.bool
};

TrafficLight.defaultProps = {
  onRedClick: undefined,
  onYellowClick: undefined,
  onGreenClick: undefined,
  RedOn: false,
  YellowOn: false,
  GreenOn: false,
  Size: 60,
  BlackColor: '#000000',
  DisabledColor: '#4A4A4A',
  RedColor: '#D0021B',
  YellowColor: '#F8E71C',
  GreenColor: '#7ED321',
  Horizontal: false,
  Flip: false
};

export default TrafficLight;
