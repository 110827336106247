import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  // IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
  // Tooltip,
  colors
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import bytesToSize from 'utils/bytesToSize';
import { ConfirmationDialog } from 'views/SubmissionCreate/components';
import { useMemo } from 'react';

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 130
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const FilesDropzone = ({
  className,
  savedFiles,
  removeAllFiles,
  deleteFile,
  handleDropFiles,
  disabled
  // initialValues
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showConfirmationv2, setShowConfirmationv2] = useState(false);
  const deletedFileRef = React.useRef(null);

  const files = useMemo(() => savedFiles, [savedFiles]);

  const handleDrop = useCallback(acceptedFiles => {
    handleDropFiles(acceptedFiles);
  }, []);

  const handleRemoveAll = () => {
    removeAllFiles();
    handleCloseDialog();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenDialog = () => setShowDialog(true);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    disabled: disabled
  });
  const removeFile = file => {
    deleteFile(file.current);
    setShowConfirmationv2(false);
  };
  const handleCloseDialog = () => setShowDialog(false);
  return (
    <div className={clsx(classes.root, className)}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: disabled ? disabled : isDragActive
        })}
        {...getRootProps()}
        onBlur={() => {}}>
        <input {...getInputProps()} />
        <div>
          <img
            alt="Select file"
            className={classes.image}
            src="/images/undraw_add_file2_gvbb.svg"
          />
        </div>
        <div>
          <Typography gutterBottom variant="h3">
            Select files
          </Typography>
          <Typography
            className={classes.info}
            color="textSecondary"
            variant="body1">
            Drop files here or click <Link underline="always">browse</Link>{' '}
            thorough your machine
          </Typography>
        </div>
      </div>
      {files && files.length > 0 && (
        <Fragment>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem divider={i < files.length - 1} key={uuid()}>
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: 'h5' }}
                    secondary={bytesToSize(file.size)}
                  />
                  <Button
                    title="Delete File"
                    disabled={disabled}
                    onClick={() => {
                      setShowConfirmationv2(true);
                      deletedFileRef.current = file;
                    }}>
                    <DeleteIcon />
                  </Button>
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <div className={classes.actions}>
            <Button onClick={handleOpenDialog} size="small" disabled={disabled}>
              Remove all
            </Button>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}>
              <Typography variant="h6" className={classes.typography}>
                Coming soon! You'll be able to upload files for this submission
              </Typography>
            </Popover>
          </div>
          <ConfirmationDialog
            cancelButtonText="Cancel"
            deleteButtonText="Remove"
            text="Are you sure you want to remove All files?"
            title="Remove Files"
            open={showDialog}
            onClose={handleCloseDialog}
            onDelete={handleRemoveAll}
          />
          <ConfirmationDialog
            cancelButtonText="Cancel"
            deleteButtonText="Delete"
            open={showConfirmationv2}
            text="Are you sure you want to remove this file?"
            title="Remove File"
            onClose={() => setShowConfirmationv2(false)}
            onDelete={() => {
              removeFile(deletedFileRef);
            }}
          />
        </Fragment>
      )}
    </div>
  );
};

FilesDropzone.propTypes = {
  className: PropTypes.string,
  deleteFile: PropTypes.func,
  handleDropFiles: PropTypes.func,
  removeAllFiles: PropTypes.func,
  savedFiles: PropTypes.array
};

export default FilesDropzone;
