import {
  apiSlice,
  MARKETING_GROUPS_TAG,
  MARKETING_GROUP_TAG
} from '../apiSlice';
import { createMutation, createQuery } from '../commons';
import {
  createMarketingGroup,
  createInsurerContactMarketingGroup,
  updateInsurerContactMarketingGroup,
  updateMarketingGroup
} from '../../../graphql/mutations';
import {
  getMarketingGroup,
  listMarketingGroups
} from '../../../graphql/custom-queries';

const marketingGroupEndpoints = apiSlice.injectEndpoints({
  endpoints: builder => ({
    listMarketingGroups: builder.query(
      createQuery(listMarketingGroups, 'listMarketingGroups', [
        MARKETING_GROUPS_TAG
      ])
    ),
    getMarketingGroup: builder.query(
      createQuery(getMarketingGroup, 'getMarketingGroup', [MARKETING_GROUP_TAG])
    ),
    createMarketingGroup: builder.mutation(
      createMutation(createMarketingGroup, [MARKETING_GROUPS_TAG])
    ),
    createMarketingInsurerContact: builder.mutation(
      createMutation(createInsurerContactMarketingGroup, [MARKETING_GROUP_TAG])
    ),
    updateMarketingGroupInsurerContact: builder.mutation(
      createMutation(updateInsurerContactMarketingGroup, [MARKETING_GROUP_TAG])
    ),
    updateMarketingGroup: builder.mutation(
      createMutation(updateMarketingGroup, [
        MARKETING_GROUPS_TAG,
        MARKETING_GROUP_TAG
      ])
    )
  })
});

export const {
  useListMarketingGroupsQuery,
  useGetMarketingGroupQuery,
  useCreateMarketingGroupMutation,
  useCreateMarketingInsurerContactMutation,
  useUpdateMarketingGroupInsurerContactMutation,
  useUpdateMarketingGroupMutation
} = marketingGroupEndpoints;
