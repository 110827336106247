import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import { apiSlice } from '../redux/slices/apiSlice';

const rootReducer = {
  session: authReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
};

const middlewares = (getDefaultMiddlewares) => {
  const apiMiddlewares = [apiSlice.middleware];
  return getDefaultMiddlewares().concat(...apiMiddlewares)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares
});
