import { useEffect, useState } from 'react';
import { compareAndFillArray } from './utility';

const useFetchContinuously = (queryToExecute, filter = {}) => {
  const [resultData, setResultData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [
    trigger,
    { data /*, isFetching, isLoading */ }
  ] = queryToExecute();
  
  useEffect(() => {
    if (!data) trigger({ filter }, true);
    else if (data.nextToken) {
      trigger({ nextToken: data.nextToken, filter }, true);
    }
    if (data) {
      setResultData(prev => {
        return compareAndFillArray(prev, data.items, 'id');
      });
    }
    if (data && !data.nextToken) {
      setIsLoading(false);
    }
  }, [data, filter]);

  return {
    resultData,
    isLoading
  }
}

export default useFetchContinuously;