import { apiSlice, BROKER_TAG } from '../apiSlice';
import { createQuery } from '../commons';
import { getBrokerByBrokerageId, getBroker } from 'graphql/custom-queries';

const threadEndpoints = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getBrokerByBrokerageId: builder.query(
      createQuery(getBrokerByBrokerageId, 'getBrokerByBrokerageId', [
        BROKER_TAG
      ])
    ),
    getBroker: builder.query(createQuery(getBroker, 'getBroker', [BROKER_TAG]))
  })
});

export const {
  useGetBrokerByBrokerageIdQuery,
  useGetBrokerQuery
} = threadEndpoints;
