export const createSubmission = /* GraphQL */ `
  mutation CreateSubmission(
    $input: CreateSubmissionInput!
    $condition: ModelSubmissionConditionInput
  ) {
    createSubmission(input: $input, condition: $condition) {
      id
      customerContactId
      brokerId
      submissionName
      projectTags
      startDate
      targetDate
      files {
        key
        type
      }
      submissionDetails
      groups
      status
      isActive
      createdAt
      updatedAt
    }
  }
`;

export const createRfp = /* GraphQL */ `
  mutation CreateRfp(
    $input: CreateRfpInput!
    $condition: ModelRfpConditionInput
  ) {
    createRfp(input: $input, condition: $condition) {
      id
      submissionId
      insurerContactId
      brokerId
      isActive
      subject
      groups
      status
      createdAt
      updatedAt
    }
  }
`;

export const createBrokerRfp = /* GraphQL */ `
  mutation CreateBrokerRfp(
    $input: CreateBrokerRfpInput!
    $condition: ModelBrokerRfpConditionInput
  ) {
    createBrokerRfp(input: $input, condition: $condition) {
      id
      rfpId
      brokerId
      broker {
        id
        firstName
        email
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateCustomerContact = /* GraphQL */ `
  mutation UpdateCustomerContact(
    $input: UpdateCustomerContactInput!
    $condition: ModelCustomerContactConditionInput
  ) {
    updateCustomerContact(input: $input, condition: $condition) {
      id
      customerId
      firstName
      createdAt
      updatedAt
      isActive
    }
  }
`;
export const updateRfp = /* GraphQL */ `
  mutation UpdateRfp(
    $input: UpdateRfpInput!
    $condition: ModelRfpConditionInput
  ) {
    updateRfp(input: $input, condition: $condition) {
      id
      submissionId
      insurerContactId
      brokerId
      isActive
      subject
      groups
      status
      rfpResult
      comment
      quotedPremium
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          address {
            city
            provinceState
          }
          avatar {
            key
            type
          }
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
        }
      }
      submission {
        id
        customerContactId
        brokerId
        submissionName
        projectTags
        startDate
        targetDate
        targetPremium
        files {
          key
          type
        }
        submissionDetails
        groups
        status
        customerContact {
          id
          customerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
        }

        broker {
          id
          brokerageId
          firstName
          lastName
          email
        }
        isActive
        owner
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
      }
    }
  }
`;
