export const compareAndFillArray = (previous, current, key) => {
  const resultArray = [];
  if (previous && previous.length > 0 && current && current.length > 0) {
    for (const item of previous) {
      if (current.findIndex(curr => curr[key] === item[key]) === -1) {
        resultArray.push(item);
      }
    }
  }
  return [...resultArray, ...current];
}