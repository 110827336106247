import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  icon: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    position: 'absolute'
  },
  subtitle: {
    paddingTop: theme.spacing(1)
  }
}));

const DashboardMetric = props => {
  const { className, label, icon, subtitle, value, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <div>
        <Typography component="h3" gutterBottom variant="overline">
          {label}
        </Typography>
        <div className={classes.details}>
          <Typography variant="h3">{value}</Typography>
        </div>
        {subtitle && (
          <div className={classes.subtitle}>
            <Typography variant="caption">{subtitle}</Typography>
          </div>
        )}
      </div>
      <div className={classes.icon}>{icon}</div>
    </Card>
  );
};

DashboardMetric.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired
};

export default DashboardMetric;
