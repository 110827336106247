import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  colors
} from '@material-ui/core';

import { _get } from 'utils/helper';
import theme from 'theme';
import { S3Avatar } from 'components/S3Avatar';
import { useFormikContext } from 'formik';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  header: {
    paddingBottom: 0
  },
  content: {
    paddingTop: 0
  },
  actions: {
    backgroundColor: colors.grey[50]
  },
  listItem: {
    width: 'auto',
    margin: theme.spacing(2)
  }
}));

const MarketingGroupMembers = props => {
  const { className, selectedUnderwriter, selectedGroupArray, ...rest } = props;
  const { setFieldValue } = useFormikContext();
  const [
    marketingGroupsToDisplay,
    setMarketingGroupsToDisplay
  ] = React.useState([]);
  let insurerContact2 = [];
  selectedGroupArray.map(item => {
    insurerContact2 = [
      ...insurerContact2,
      ..._get(item, 'insurerContacts.items', null)
    ];
  });
  useEffect(() => {
    insurerContact2 = insurerContact2?.filter(contact => {
      const found = selectedUnderwriter?.find(Underwriter => {
        return Underwriter.id === contact.insurerContact.id;
      });
      if (found) return false;
      return true;
    });

    setMarketingGroupsToDisplay(() => {
      //to remove  duplicate from marketing groups insurer contacts
      insurerContact2 = insurerContact2.filter(
        (value, index, self) =>
          index ===
          self.findIndex(t => t.insurerContact.id === value.insurerContact.id)
      );

      return insurerContact2;
    });
  }, [selectedUnderwriter.length]);

  useEffect(() => {
    let selectedInsurer = [];
    const groupId = marketingGroupsToDisplay?.map(
      group => group.insurerContact.id
    );
    const underwriterId = selectedUnderwriter?.map(group => group.id);
    if (groupId) {
      selectedInsurer = [...groupId];
    }
    if (underwriterId) {
      selectedInsurer = [...selectedInsurer, ...underwriterId];
    }
    setFieldValue('insurerSubmissionId', selectedInsurer);
  }, [marketingGroupsToDisplay, selectedUnderwriter.length]);

  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {marketingGroupsToDisplay &&
        marketingGroupsToDisplay?.map(member => (
          <ListItem disableGutters key={member.id} className={classes.listItem}>
            <ListItemAvatar>
              <S3Avatar s3key={member.insurerContact.insurer.avatar.key} />
            </ListItemAvatar>
            <ListItemText
              primary={member.insurerContact.insurer.name}
              primaryTypographyProps={{ variant: 'h6' }}
              secondary={
                <div>
                  <div>
                    {member.insurerContact.firstName +
                      ' ' +
                      member.insurerContact.lastName}
                  </div>{' '}
                  <div>{member.insurerContact.email}</div>
                </div>
              }
            />
          </ListItem>
        ))}

      {selectedUnderwriter &&
        selectedUnderwriter?.map(member => (
          <ListItem disableGutters key={member.id} className={classes.listItem}>
            <ListItemAvatar>
              <S3Avatar s3key={member.insurer.avatar?.key} />
            </ListItemAvatar>
            <ListItemText
              primary={member.insurer.name}
              primaryTypographyProps={{ variant: 'h6' }}
              secondary={
                <div>
                  <div>{member.firstName + ' ' + member.lastName}</div>{' '}
                  <div>{member.email}</div>
                </div>
              }
            />
          </ListItem>
        ))}
    </List>
  );
};

MarketingGroupMembers.propTypes = {
  className: PropTypes.string,
  marketingGroup: PropTypes.shape({
    name: PropTypes.string.isRequired,
    members: PropTypes.array.isRequired
  }).isRequired
};

export default MarketingGroupMembers;
