import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { Page, BoundMetric, FinancialMetric } from 'components';
import {
  Header,
  LineOfBusiness,
  RecentCustomers,
  SalesPerformance
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const DashboardHome = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Home">
      <Header />
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={4} sm={6} xs={12}>
          <FinancialMetric label="Premium" value="1,056,620" />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <FinancialMetric label="Revenue" value="211,324" />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <BoundMetric value="265" />
        </Grid>
        <Grid item lg={8} xs={12}>
          <SalesPerformance />
        </Grid>
        <Grid item lg={4} xs={12}>
          <LineOfBusiness />
        </Grid>
        <Grid item xs={12}>
          <RecentCustomers />
        </Grid>
      </Grid>
    </Page>
  );
};

export default DashboardHome;
