import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import SendIcon from '@material-ui/icons/Send';
import {
  Button,
  Dialog,
  TextField,
  Typography,
  colors
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
const useStyles = makeStyles(theme => ({
  root: {
    width: 960
  },
  header: {
    padding: theme.spacing(3),
    maxWidth: 720,
    margin: '0 auto'
  },
  content: {
    padding: theme.spacing(0, 2),
    maxWidth: 720,
    margin: '0 auto'
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  author: {
    margin: theme.spacing(4, 0),
    display: 'flex'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    backgroundColor: colors.grey[100],
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  applyButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const ConfirmSubmission = props => {
  const {
    open,
    onClose,
    onApply,
    // disabled,
    initialValues,
    className,
    ...rest
  } = props;

  const [value, setValue] = useState('');
  const { setFieldValue } = useFormikContext();
  const classes = useStyles();

  useEffect(() => {
    setValue(initialValues);
  }, [initialValues]);

  const handleChange = event => {
    event.persist();
    if (event.target.value.length >= 200) {
      setValue(event.target.value.substring(0, 200));
      setFieldValue('additionalNote', event.target.value.substring(0, 200));
      event.target.value = event.target.value.substring(0, 200);
    } else {
      setValue(event.target.value);
      setFieldValue('additionalNote', event.target.value);
    }
  };

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={open}>
      <div {...rest} className={clsx(classes.root, className)}>
        <div className={classes.header}>
          <Typography
            align="center"
            className={classes.title}
            gutterBottom
            variant="h3">
            Ready to send your submission out to market?
          </Typography>
          <Typography
            align="center"
            className={classes.subtitle}
            variant="subtitle1">
            You can't undo this!
          </Typography>
        </div>
        <div className={classes.content}>
          <TextField
            autoFocus
            className={classes.textField}
            // eslint-disable-next-line react/jsx-sort-props
            FormHelperTextProps={{ classes: { root: classes.helperText } }}
            fullWidth
            // inputProps={{ readOnly: disabled }}
            helperText={`${200 - value?.length} characters left`}
            label="Submission Intro (optional)"
            multiline
            onChange={handleChange}
            placeholder="Add an optional note to be sent with this package to all recipients"
            rows={5}
            value={value}
            variant="outlined"
          />
        </div>
        <div className={classes.actions}>
          <Button
            className={classes.applyButton}
            onClick={onApply}
            endIcon={<SendIcon />}
            variant="contained">
            Send to market
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

ConfirmSubmission.propTypes = {
  className: PropTypes.string,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default ConfirmSubmission;
