import Auth from '@aws-amplify/auth';
import aws_exports from '../../aws-exports';
const baseUrl = aws_exports.aws_appsync_graphqlEndpoint;

const createRequest = (graphqlQuery, method, variables) => ({
  body: graphqlQuery,
  method,
  variables
});

export const createQuery = (query, dataField, tags) => ({
  query: args => createRequest({
    query: query,
    variables: args
  }, 'POST'),
  transformResponse: response => {
    return response && response.data && response.data[dataField];
  },
  providesTags: tags
});

export const createMutation = (mutation, tags) => ({
  query: args => createRequest({
    query: mutation,
    variables: args
  }, 'POST'),
  invalidatesTags: tags
});

export const getBaseQueryParams = () => ({
  baseUrl,
  prepareHeaders: async headers => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    headers.set('Authorization', token);
    return headers;
  }
})