/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/PublicLayout';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/AuthenticatedLayout';
import DashboardHomeView from './views/DashboardHome';
import OverviewView from './views/Overview';
import PresentationView from './views/Presentation';

export const authRoot = '/auth';
export const allRoot = '*';

export const authRoutes = {
  login: `${authRoot}/login`,
  logout: `${authRoot}/logout`,
  register: `${authRoot}/register`,
  forgotPassword: `${authRoot}/forgot-password`,
  resetPassword: `${authRoot}/reset-password`,
  expiredEmailValidation: `${authRoot}/expired-email-validation`
};
const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/auth/login" />
  },
  {
    path: authRoot,
    component: AuthLayout,
    routes: [
      {
        path: authRoutes.login,
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: authRoutes.register,
        exact: true,
        component: lazy(() => import('views/Register'))
      },
      {
        path: authRoutes.logout,
        exact: true,
        component: () => <Redirect to={authRoutes.login} />
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: allRoot,
    component: DashboardLayout,
    routes: [
      {
        path: '/calendar',
        exact: true,
        component: lazy(() => import('views/Calendar'))
      },
      {
        path: '/changelog',
        exact: true,
        component: lazy(() => import('views/Changelog'))
      },
      {
        path: '/chat',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/chat/:id',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/customers',
        exact: true,
        component: lazy(() => import('views/Customers'))
      },
      {
        path: '/customers/:id',
        exact: true,
        component: lazy(() => import('views/CustomerDetails'))
      },
      {
        path: '/customers/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CustomerDetails'))
      },
      {
        path: '/customers/:customerId/submissions/create',
        exact: true,
        component: lazy(() => import('views/SubmissionCreate'))
      },
      {
        path: '/submissions/:submissionId/submissions/create',
        exact: true,
        component: lazy(() => import('views/SubmissionCreate'))
      },
      {
        path: '/insurers',
        exact: true,
        component: lazy(() => import('views/Insurers'))
      },
      {
        path: '/insurers/overview',
        exact: true,
        component: lazy(() => import('views/InsurersOverview'))
      },
      {
        path: '/insurers/marketing-groups',
        exact: true,
        component: lazy(() => import('views/MarketingGroups'))
      },
      {
        path: '/insurers/marketing-groups/:action',
        exact: true,
        component: lazy(() => import('views/MarketingGroups/AddMarketingGroup'))
      },
      {
        path: '/insurers/:id',
        exact: true,
        component: lazy(() => import('views/InsurerDetails'))
      },
      {
        path: '/insurers/:id/:tab',
        exact: true,
        component: lazy(() => import('views/InsurerDetails'))
      },
      {
        path: '/insurers/:id/:tab/:connection',
        exact: true,
        component: lazy(() => import('views/InsurerDetails'))
      },
      {
        path: '/insurers/:id/:tab/:connection/:connectionId',
        exact: true,
        component: lazy(() => import('views/InsurerDetails'))
      },
      {
        path: '/dashboards/rfps',
        exact: true,
        component: lazy(() => import('views/DashboardRFP'))
      },
      {
        path: '/dashboards/analytics',
        exact: true,
        component: lazy(() => import('views/_DashboardAnalytics'))
      },
      {
        path: '/dashboards/default',
        exact: true,
        component: lazy(() => import('views/_DashboardDefault'))
      },
      {
        path: '/home',
        exact: true,
        component: DashboardHomeView
      },
      {
        path: '/invoices/:id',
        exact: true,
        component: lazy(() => import('views/InvoiceDetails'))
      },
      {
        path: '/kanban-board',
        exact: true,
        component: lazy(() => import('views/KanbanBoard'))
      },
      {
        path: '/mail',
        exact: true,
        component: lazy(() => import('views/Mail'))
      },
      {
        path: '/management/customers',
        exact: true,
        component: lazy(() => import('views/_CustomerManagementList'))
      },
      {
        path: '/management/customers/:id',
        exact: true,
        component: lazy(() => import('views/_CustomerManagementDetails'))
      },
      {
        path: '/management/customers/:id/:tab',
        exact: true,
        component: lazy(() => import('views/_CustomerManagementDetails'))
      },
      {
        path: '/management/projects',
        exact: true,
        component: lazy(() => import('views/_ProjectManagementList'))
      },
      {
        path: '/management/orders',
        exact: true,
        component: lazy(() => import('views/OrderManagementList'))
      },
      {
        path: '/management/orders/:id',
        exact: true,
        component: lazy(() => import('views/OrderManagementDetails'))
      },
      {
        path: '/overview',
        exact: true,
        component: OverviewView
      },
      {
        path: '/presentation',
        exact: true,
        component: PresentationView
      },
      {
        path: '/profile/:id',
        exact: true,
        component: lazy(() => import('views/Profile'))
      },
      {
        path: '/profile/:id/:tab',
        exact: true,
        component: lazy(() => import('views/Profile'))
      },
      {
        path: '/projects/create',
        exact: true,
        component: lazy(() => import('views/_ProjectCreate'))
      },
      {
        path: '/projects/:id',
        exact: true,
        component: lazy(() => import('views/ProjectDetails'))
      },
      {
        path: '/projects/:id/:tab',
        exact: true,
        component: lazy(() => import('views/ProjectDetails'))
      },
      {
        path: '/projects',
        exact: true,
        component: lazy(() => import('views/ProjectList'))
      },
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        path: '/social-feed',
        exact: true,
        component: lazy(() => import('views/SocialFeed'))
      },
      {
        path: '/submissions',
        exact: true,
        component: lazy(() => import('views/SubmissionsList'))
      },
      {
        path: '/submissions/appetite',
        exact: true,
        component: lazy(() => import('views/AppetiteCheck'))
      },
      {
        path: '/submissions/create',
        exact: true,
        component: lazy(() => import('views/SubmissionCreate'))
      },
      {
        path: '/submissions/:id',
        exact: true,
        component: lazy(() => import('views/SubmissionDetails'))
      },
      {
        path: '/submissions/:id/:tab',
        exact: true,
        component: lazy(() => import('views/SubmissionDetails'))
      },
      {
        path: '/submissions/:id/:tab/:subId',
        exact: true,
        component: lazy(() => import('views/SubmissionDetails'))
      },
      {
        path: '/getting-started',
        exact: true,
        component: lazy(() => import('views/GettingStarted'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
