import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const TextFieldComponent = ({
  disabled,
  label,
  id,
  variant,
  formikProps,
  error,
  helperText,
  ...rest
}) => {
  return (
    <TextField
      disabled={disabled}
      error={error}
      fullWidth
      label={label}
      id={id}
      helperText={helperText}
      {...formikProps}
      {...rest}
      variant={variant}
    />
  );
};

export default TextFieldComponent;

TextFieldComponent.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  formikProps: PropTypes.any,
  helperText: PropTypes.any,
  id: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string
};

TextFieldComponent.defaultProps = {
  variant: 'outlined',
  error: false,
  disabled: false,
  helperText: ''
};
