import I18n from 'i18n-js'

import { en } from './en'

export const SupportedLanguages = Object.freeze({
  ENGLISH: 'en',
});

export const i18n = I18n

i18n.locale = SupportedLanguages.ENGLISH
i18n.fallbacks = false
i18n.translations = { en }

export function seti18Language(language){
  i18n.locale = language || SupportedLanguages.ENGLISH
}
