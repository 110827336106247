import * as Yup from 'yup';

export const addCustomerSchema = Yup.object({
  name: Yup.string().required('Company Name is required!'),
  email: Yup.string()
    .email()
    .required('Email is required!'),
  phone: Yup.string().required('Phone is required!'),
  firstName: Yup.string().required('First Name is required!'),
  lastName: Yup.string().required('Last Name is required!'),
  address: Yup.object().shape({
    streetAddressLine1: Yup.string().required('Address line 1 is required!'),
    streetAddressLine2: Yup.string().nullable(),
    city: Yup.string().required('City is required!'),
    provinceState: Yup.string().required('State is required!'),
    postalCode: Yup.string().required('Postal Code is required!'),
    country: Yup.string().required('Country name is required!')
  })
});
