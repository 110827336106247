import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseQueryParams } from './commons';

export const CUSTOMER_CONTACT_LIST_TAG = 'CustomerContacts';
export const CUSTOMER_CONTACT_TAG = 'CustomerContact';
export const CUSTOMER_LIST_TAG = 'Customers';
export const INSURER_CONTACT_LIST_TAG = 'InsurerContacts';
export const INSURER_CONTACT_TAG = 'InsurerContact';
export const INSURER_LIST_TAG = 'Insurers';
export const MARKETING_GROUPS_TAG = 'MarketingGroups';
export const MARKETING_GROUP_TAG = 'MarketingGroup';
export const SUBMISSION_TAG = 'Submission';
export const SUBMISSION_TAGS = 'Submissions';
export const RFP_TAG = 'Rfp';
export const RFP_TAG_LIST = 'RfpList';
export const THREAD_TAG = 'thread';
export const THREAD_TAG_LIST = 'threadList';
export const BROKER_TAG = 'broker';
export const BROKER_RFP_TAG = 'brokerRfp';
export const BROKER_RFP_TAG_LIST = 'brokerRfpList';
export const Insurer_Rfp_TAG = 'insurerRfp';

export const apiSlice = createApi({
  reducerPath: 'dbunk',
  tagTypes: [
    CUSTOMER_CONTACT_LIST_TAG,
    CUSTOMER_CONTACT_TAG,
    CUSTOMER_LIST_TAG,
    INSURER_CONTACT_LIST_TAG,
    INSURER_CONTACT_TAG,
    INSURER_LIST_TAG,
    MARKETING_GROUPS_TAG,
    MARKETING_GROUP_TAG,
    SUBMISSION_TAG,
    SUBMISSION_TAGS,
    RFP_TAG,
    RFP_TAG_LIST,
    THREAD_TAG,
    THREAD_TAG_LIST,
    BROKER_TAG,
    BROKER_RFP_TAG,
    BROKER_RFP_TAG_LIST,
    Insurer_Rfp_TAG
  ],
  baseQuery: fetchBaseQuery(getBaseQueryParams()),
  endpoints: () => ({})
});
