import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const AutoComplete = ({
  closeAutoComplete,
  disabled,
  error,
  formikProps,
  filterCallback,
  getOptionLabel,
  handleCompanyAutoCompleteName,
  handleOpenAutoComplete,
  helperText,
  id,
  label,
  onInputChange,
  options,
  openAutoCompletet,
  value,
  multiple,
  defaultValue,
  required
}) => {
  const getfilterOption = () => {
    if (filterCallback) {
      return { filterOptions: filterCallback };
    }
    return {};
  };
  return (
    <Autocomplete
      multiple={multiple}
      disableClearable={disabled}
      freeSolo
      getOptionLabel={getOptionLabel}
      id={id}
      {...getfilterOption()}
      inputValue={value}
      defaultValue={defaultValue}
      onChange={handleCompanyAutoCompleteName}
      onClose={closeAutoComplete}
      onInputChange={onInputChange}
      onOpen={handleOpenAutoComplete}
      open={openAutoCompletet}
      options={options}
      renderInput={params => (
        <TextField
          {...formikProps}
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off'
          }}
          disabled={disabled}
          error={error}
          helperText={helperText}
          fullWidth
          label={label}
          variant="outlined"
          required={required}
          value={value}
        />
      )}
    />
  );
};

export default AutoComplete;

AutoComplete.propTypes = {
  closeAutoComplete: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  formikProps: PropTypes.any,
  filterCallback: PropTypes.any,
  getOptionLabel: PropTypes.func,
  handleCompanyAutoCompleteName: PropTypes.func,
  handleOpenAutoComplete: PropTypes.func,
  helperText: PropTypes.any,
  id: PropTypes.string,
  label: PropTypes.string,
  onInputChange: PropTypes.func,
  openAutoCompletet: PropTypes.bool,
  options: PropTypes.any,
  value: PropTypes.string
};

AutoComplete.defaultProps = {
  closeAutoComplete: () => {},
  disabled: false,
  error: false,
  filterCallback: null,
  getOptionLabel: () => {},
  handleCompanyAutoCompleteName: () => {},
  handleOpenAutoComplete: () => {},
  onInputChange: () => {}
};
