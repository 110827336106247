import {
  apiSlice,
  CUSTOMER_CONTACT_LIST_TAG,
  CUSTOMER_LIST_TAG
} from '../apiSlice';
import { createMutation, createQuery } from '../commons';
import { listCustomers } from '../../../graphql/queries';
import { createCustomer, updateCustomer } from '../../../graphql/mutations';

const companyEndpoints = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCustomers: builder.query(
      createQuery(listCustomers, 'listCustomers', [CUSTOMER_LIST_TAG])
    ),
    createCustomer: builder.mutation(
      createMutation(createCustomer, [CUSTOMER_LIST_TAG])
    ),
    updateCustomer: builder.mutation(
      createMutation(updateCustomer, [CUSTOMER_CONTACT_LIST_TAG])
    )
  })
});

export const {
  useGetCustomersQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation
} = companyEndpoints;
