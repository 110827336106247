import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, CardContent } from '@material-ui/core';

import { RichEditor } from 'components';
import { useFormikContext } from 'formik';

const useStyles = makeStyles(() => ({
  root: {}
}));

const SubmissionDetails = props => {
  const { className, disabled, ...rest } = props;
  const { setFieldValue, errors, getFieldProps, values } = useFormikContext();
  const classes = useStyles();
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Submission details" />
      <CardContent>
        <RichEditor
          initialValues={values.submissionDetails}
          disabled={disabled}
          placeholder="Add any additional details about this submission. This information will also be shared to the insurers."
          formikFieldProps={getFieldProps()}
          onEditorChange={value => setFieldValue('submissionDetails', value)}
        />
        {errors && (
          <div style={{ color: '#e53935' }}>{errors.submissionDetails}</div>
        )}
      </CardContent>
    </Card>
  );
};

SubmissionDetails.propTypes = {
  className: PropTypes.string
};

export default SubmissionDetails;
