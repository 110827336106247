import {
  apiSlice,
  INSURER_CONTACT_LIST_TAG,
  INSURER_CONTACT_TAG
} from '../apiSlice';
import { createMutation, createQuery } from '../commons';
// import { getInsurerContact } from '../../../graphql/queries';
import {
  createInsurerContact,
  updateInsurerContact
} from '../../../graphql/mutations';
import { listInsurerContacts, getInsurerContact } from 'graphql/custom-queries';

const insurerContactEndpoints = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getInsurerContacts: builder.query(
      createQuery(listInsurerContacts, 'listInsurerContacts', [
        INSURER_CONTACT_LIST_TAG
      ])
    ),
    getInsurerContactById: builder.query(
      createQuery(getInsurerContact, 'getInsurerContact', [INSURER_CONTACT_TAG])
    ),
    createInsurerContact: builder.mutation(
      createMutation(createInsurerContact, [INSURER_CONTACT_LIST_TAG])
    ),
    updateInsurerContact: builder.mutation(
      createMutation(updateInsurerContact, [
        INSURER_CONTACT_TAG,
        INSURER_CONTACT_LIST_TAG
      ])
    )
  })
});

export const {
  useLazyGetInsurerContactsQuery,
  useGetInsurerContactByIdQuery,
  useCreateInsurerContactMutation,
  useUpdateInsurerContactMutation
} = insurerContactEndpoints;
