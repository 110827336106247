/* eslint-disable quotes */
export const en = {
  signin: {
    title: 'Sign in',
    description: 'Sign in on the internal platform',
    form: {
      emailField: {
        label: 'Email address'
      },
      passwordField: {
        label: 'password'
      },
      submitButtonText: 'Sign in',
      dontHaveAccount: "Don't have an account?"
    }
  },
  insurers: {
    metaData: {
      title: 'All insurers',
      header: {
        title: 'Insurers'
      },
      directoryTitle: 'Directory'
    },
    table: {
      header: {
        company: 'Company',
        contact: 'Contact',
        phone: 'Phone',
        email: 'Email',
        ytd: 'YTD',
        type: 'Type',
        currentMonth: 'Current Month',
        submissions: 'Submissions'
      }
    },
    insurerDetailsSection: {
      fields: {
        email: 'Email',
        phone: 'Phone',
        state: 'State/Region',
        country: 'Country',
        address1: 'Address1',
        address2: 'Address2'
      },
      actions: {
        editButton: { title: 'Edit' }
      }
    },
    addInsurerForm: {
      title: 'Add Insurer',
      fields: {
        companyName: 'Company Name*',
        contactFirstName: 'Contact First Name*',
        contactLastName: 'Contact Last Name*',
        phoneNumber: 'Phone Number*',
        emailAddress: 'Email Address*'
      }
    },
    editInsurerForm: {
      title: 'Edit Insurer'
    },
    actions: {
      addInsurerButton: { text: 'ADD INSURER' }
    }
  },
  customers: {
    metaData: {
      title: 'customers',
      directory: 'Directory'
    },
    search: {
      searchBar: {
        placeholder: 'Search'
      }
    },

    addCustomerForm: {
      addCustomerTitle: 'Add Customer',
      editCustomerTitle: 'Edit Customer',
      fields: {
        companyName: 'Company Name*',
        contactFirstName: 'Contact First Name*',
        contactLastName: 'Contact Last Name*',
        phoneNumber: 'Contact Phone Number*',
        emailAddress: 'Contact Email Address*',
        country: {
          label: 'Company Country',
          helperText: 'Please select your country'
        },
        state: {
          label: 'Company State/Region',
          helperText: 'Please select your state'
        },
        city: 'Company City*',
        postalCode: 'Company Postal Code*',
        address1: 'Company Address 1*',
        address2: 'Company Address 2'
      }
    },

    filterDrawer: {
      close: 'close',
      form: {
        project: {
          title: 'Project',
          paymentStatusField: {
            label: 'Payment status'
          },
          filterTagField: {
            label: 'Filter Tags',
            addTag: 'Add'
          },
          projectAmountField: {
            label: 'Project amount'
          },
          projectStatusField: {
            label: 'Project status'
          }
        },
        customer: {
          title: 'Customer',
          customerNameField: {
            label: 'Customer name'
          },
          emailField: {
            label: 'Email address'
          },
          phoneNumberField: {
            label: 'Phone number'
          },
          ageField: {
            label: 'Age'
          },
          togglerField: {
            projectOwner: 'Project owner',
            freelancer: 'Freelancer'
          }
        },
        clear: 'Clear',
        submit: 'Apply filters'
      }
    },
    table: {
      title: 'All customers',
      paginationTop: {
        text: 'Records found. Page {{currentPage}} of'
      },
      header: {
        company: 'Company',
        contact: 'Contact',
        phone: 'Phone',
        email: 'Email',
        lifetimeValue: 'Lifetime Value',
        type: 'Type',
        submissions: 'Submissions',
        actions: 'Actions'
      }
    },
    actions: {
      addCustomerButton: {
        text: 'Add customer',
        actionPopOverText1:
          "Coming soon! You'll be able to add more customers and manage them",
        actionPopOverText2:
          'all within dbunk (or integrate with your existing BMS or CRM)'
      },
      closeCustomerButton: {
        text: 'Close Customer Account'
      },
      closeCustomerConfirmation: {
        cancelButtonText: 'Cancel',
        deleteButtonText: 'Deactivate',
        text: 'Are you sure you want to deactivate this customer?',
        title: 'Deactivate Customer'
      },
      otherActions: 'Other actions',
      showFilterButton: 'Show filters'
    },
    submissions: {
      meta: {
        title: 'Customer Submissions'
      }
    },
    submissionTable: {
      headerLabels: {
        type: 'Type',
        targetDate: 'Target Date',
        status: 'Status',
        actions: 'Actions'
      },
      buttons: {
        seeAll: 'See All',
        view: 'View'
      }
    },
    submissionCard: {
      from: 'From',
      company: 'Company',
      new: 'New',
      notSpecified: 'Not specified',
      taskOwner: 'Task Owner',
      submitted: 'Submitted',
      target: 'Target',
      status: 'Status',
      view: 'View'
    }
  },
  marketingGroups: {
    metaData: {
      parentTitle: 'INSURERS',
      title: 'Marketing Groups'
    },
    table: {
      mainHeader: 'Marketing Groups',
      headers: {
        groupName: 'Group Name',
        numberOfUnderwriters: 'Number of Underwriters'
      }
    },
    actions: {
      addMarketingGroupButton: { text: 'ADD MARKETING GROUP' }
    }
  },

  submissions: {
    createSubmission: {
      metaData: {
        title: 'Prepare a Submission'
      },
      addCustomer: {
        title: "Who's the customer",
        addCustomerButton: 'ADD CUSTOMER',
        fieldLabel: 'Customers'
      },
      aboutThisSubmission: {
        title: 'About this Submission',
        fields: {
          submissionName: 'Submission Name',
          projectTags: 'Project Tags',
          startDate: 'Start Date',
          endDate: 'End Date',
          targetPremium: 'Target Premium'
        }
      },
      selectSubmission: {
        title: 'Select Submission',
        fileDropzone: {
          title: 'Select files',
          description: 'Drop files here or click browse through your machine'
        },
        error: 'Please select a file'
      },
      submissionDetails: {
        title: 'Submission Details',
        placeholder:
          'Add any additional details about this submission. This information will be shared to the insurers.'
      },
      sendSubmissionTo: {
        title: 'Who do you want to send this submission to?',
        marketingGroup: 'Marketing Group'
      },
      buttons: {
        saveAsDraft: 'Save As Draft',
        finalize: 'Finalize'
      }
    },
    proposalComments: {
      title: 'Outcome',
      results: {
        bestTermsQuoted: 'Best Terms Quoted',
        termsQuoted: 'Terms Quoted',
        declined: 'Declined',
        didNotMeetTimeline: 'Did not meet timeline'
      }
    }
  },
  pagination: {
    record: 'Records found',
    page: 'Page',
    of: 'of'
  }
};
