import React, { useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Button,
  colors
} from '@material-ui/core';
import { useFormik } from 'formik';
import { addCustomerSchema } from '../../../../schema';
import { useGetCustomersQuery } from '../../../../redux/slices/customer/companySlice';
import { _get, UploadDocument, isEmailUnique } from 'utils/helper';
import {
  TextField as TextFieldComponent,
  FileDropZone,
  DropDown,
  AutoComplete
} from '../../../../components/Customer';
import {
  useCountryStates,
  useCountry,
  useSortedFormFields
} from '../../../../hooks';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const AddCustomer = props => {
  const {
    customerContacts,
    open,
    onClose,
    initialValues,
    onSubmit,
    className,
    formData,
    ...rest
  } = props;
  const [countryCode, setCountryCode] = useState('');
  const [openAutoCompletet, setpOenAutoCompletet] = useState(false);
  const { data } = useGetCustomersQuery();
  const sortedFormData = useSortedFormFields(formData);
  const countryList = useCountry(['Canada']); // restrict to Canada for now
  const states = useCountryStates(countryCode);
  const customers = _get(data, 'items', []);
  const classes = useStyles();

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    isValid,
    isSubmitting,
    dirty,
    values,
    touched,
    errors,
    resetForm,
    setFieldError
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: addCustomerSchema
  });

  useEffect(() => {
    let mounted = true;
    const selectedCountry = _get(values, 'address.country', '');
    if (selectedCountry && countryList.length) {
      const country = countryList.find(
        country => country.name === selectedCountry
      );
      if (mounted) {
        setCountryCode(country.code);
      }
    }
    return () => {
      mounted = false;
    };
  }, [values.address.country, countryList, setCountryCode]);

  useEffect(() => {
    if (values.email && !errors.email) {
      const customer = isEmailUnique(
        customerContact => customerContact.email === values.email,
        customerContacts
      );
      if (customer) {
        setFieldError('email', 'The email is already in use!');
      } else {
        setFieldError('email', undefined);
      }
    }
  }, [setFieldError, customerContacts, values.email, errors.email]);

  const onDrop = useCallback(
    async (acceptedFiles, id) => {
      try {
        const file = acceptedFiles[0];
        const uploadedFile = await UploadDocument(file, 'customers');
        setFieldValue(id, { key: uploadedFile.key, type: file.type });
      } catch (err) {
        console.log('upload Failed!', err);
      }
    },
    [setFieldValue]
  );

  const handleCountryChange = (e, id) => {
    setFieldValue(id, e.target.value);
  };

  const handleCompanyAutoCompleteName = (_, value) => {
    if (value) {
      setFieldValue('isCustomerExist', value);
      setFieldValue('address', value.address);
      const avatar = value.avatar;
      setFieldValue('avatar', avatar);
    }
  };

  const handleOpenAutoComplete = () => {
    if (values.name.length >= 3) {
      setpOenAutoCompletet(true);
    }
  };
  const closeAutoComplete = () => {
    setpOenAutoCompletet(false);
  };

  if (!open) {
    return null;
  }

  const onCloseHandler = () => {
    resetForm();
    onClose();
  };
  return (
    <Modal onClose={onClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form onSubmit={handleSubmit} autoComplete="new-password">
          <CardContent>
            <Typography align="center" gutterBottom variant="h3">
              {formData.label}
            </Typography>
            <Grid className={classes.container} container spacing={3}>
              {sortedFormData.fields?.map(field => {
                switch (field.component) {
                  case 'text_file_dropzone':
                    return (
                      <Grid item md={12} xs={12} key={field.sort}>
                        <FileDropZone
                          onDrop={onDrop}
                          id={field.id}
                          s3Key={values.avatar?.key}
                        />
                      </Grid>
                    );
                  case 'text_field_autoComplete':
                    return (
                      <Grid item md={6} xs={12} key={field.sort}>
                        <AutoComplete
                          error={touched[field.id] && !!errors[field.id]}
                          formikProps={getFieldProps(field.id)}
                          helperText={touched[field.id] && errors[field.id]}
                          value={values.name}
                          closeAutoComplete={closeAutoComplete}
                          onInputChange={(_, value) =>
                            setFieldValue(field.id, value)
                          }
                          handleCompanyAutoCompleteName={
                            handleCompanyAutoCompleteName
                          }
                          label={field.label}
                          handleOpenAutoComplete={handleOpenAutoComplete}
                          getOptionLabel={option => option.name || ''}
                          openAutoCompletet={openAutoCompletet}
                          options={customers}
                        />
                      </Grid>
                    );
                  case 'text_field':
                    return (
                      <Grid item md={6} xs={12} key={field.sort}>
                        <TextFieldComponent
                          autoComplete="new-password"
                          error={
                            _get(touched, field.id, false) &&
                            !!_get(errors, field.id, false)
                          }
                          helperText={
                            _get(touched, field.id, false) &&
                            _get(errors, field.id, '')
                          }
                          id={field.id}
                          formikProps={getFieldProps(field.id)}
                          label={field.label}
                        />
                      </Grid>
                    );
                  case 'select_field_country':
                    return (
                      <Grid item md={6} xs={12} key={field.sort}>
                        <DropDown
                          error={
                            _get(touched, field.id, false) &&
                            !!_get(errors, field.id, false)
                          }
                          helperText={
                            _get(touched, field.id, false) &&
                            _get(errors, field.id, '')
                          }
                          formikProps={getFieldProps(field.id)}
                          id={field.id}
                          label={field.label}
                          handleChange={e => handleCountryChange(e, field.id)}>
                          {countryList.map(country => (
                            <option key={country.code} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </DropDown>
                      </Grid>
                    );
                  case 'select_field_state':
                    return (
                      <React.Fragment key={field.sort}>
                        {countryCode && (
                          <Grid item md={6} xs={12}>
                            <DropDown
                              error={
                                _get(touched, field.id, false) &&
                                !!_get(errors, field.id, false)
                              }
                              helperText={
                                _get(touched, field.id, false) &&
                                _get(errors, field.id, '')
                              }
                              formikProps={getFieldProps(field.id)}
                              id={field.id}
                              label={field.label}>
                              {states.map(state => (
                                <option key={state.code} value={state.name}>
                                  {state.name}
                                </option>
                              ))}
                            </DropDown>
                          </Grid>
                        )}
                      </React.Fragment>
                    );
                  case 'break_space':
                    return (
                      <Grid item md={6} xs={12} key={field.sort}>
                        <br></br>
                      </Grid>
                    );
                }
              })}
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button onClick={onCloseHandler} variant="contained">
              Close
            </Button>
            <Button
              className={classes.saveButton}
              disabled={!(isValid && dirty) || isSubmitting}
              type="submit"
              variant="contained">
              Add
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
};

AddCustomer.propTypes = {
  className: PropTypes.string,
  customerContacts: PropTypes.arrayOf(PropTypes.any),
  formData: PropTypes.any,
  initialValues: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

AddCustomer.defaultProps = {
  open: false,
  customerContacts: [],
  onClose: () => {},
  onSubmit: () => {}
};

export default AddCustomer;
