import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Page, SearchBar, CustomerSearchResults } from 'components';
import { AddCustomer, Header, formData } from './components';
import {
  useLazyGetCustomerContactsQuery,
  useCreateCustomerContactMutation
} from '../../redux/slices/customer/customerSlice';
import useSearchedData from 'hooks/useSearchedData';
import {
  useCreateCustomerMutation,
  useUpdateCustomerMutation
} from '../../redux/slices/customer/companySlice';
import { useAuthProviderContext } from '../../context/AuthContextProvider';
import { _get } from '../../utils/helper';
import { useFetchContinuously } from 'hooks';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));

export const customerInitialValues = {
  name: '',
  email: '',
  phone: '',
  avatar: null,
  address: {
    streetAddressLine1: '',
    streetAddressLine2: '',
    city: '',
    postalCode: '',
    provinceState: '',
    country: 'Canada'
  },
  firstName: '',
  lastName: '',
  isCustomerExist: null,
  isActive: true
};

const Customers = props => {
  const { history } = props;
  const [openAddModal, setOpenAddModal] = useState(false);
  const { getUserGroup } = useAuthProviderContext();
  const classes = useStyles();
  const customerContactFilter = useRef({ isActive: { eq: true } });
  const arrayRef = useRef([]);
  const { resultData, isLoading } = useFetchContinuously(
    useLazyGetCustomerContactsQuery,
    customerContactFilter.current
  );
  const [createCustomer] = useCreateCustomerMutation();
  const [createCustomerContact] = useCreateCustomerContactMutation();
  const [updateCustomer] = useUpdateCustomerMutation();
  const { searchedElements, handleSearch } = useSearchedData(
    isLoading ? arrayRef.current : resultData,
    ['customer.name', 'firstName', 'lastName', 'email']
  );

  const handleFilter = () => {};
  // const handleSearch = () => {};
  const onClickRow = customer =>
    history.push(`/customers/${customer.id}/summary`);

  const handleAddCustomer = useCallback(() => {
    setOpenAddModal(prev => !prev);
  }, [setOpenAddModal]);

  const handleClose = useCallback(() => {
    setOpenAddModal(false);
  }, [setOpenAddModal]);

  const updateEditedCustomer = async values => {
    const customerInput = {
      id: values.isCustomerExist?.id,
      name: values.name,
      address: values.address,
      avatar: values.avatar,
      _version: values.isCustomerExist?._version
    };
    await updateCustomer({ input: customerInput });
  };

  const createExistingCustomerMutation = async (values, customer) => {
    const customerContactInput = {
      customerId: customer.id,
      email: values.email,
      phoneNumber: values.phone,
      firstName: values.firstName,
      lastName: values.lastName,
      isActive: values.isActive,
      brokerageId: getUserGroup()
    };
    await createCustomerContact({
      input: customerContactInput
    });
  };

  const handleCreateCustomer = async values => {
    const customerInput = {
      name: values.name,
      address: values.address,
      avatar: values.avatar,
      brokerageId: getUserGroup()
    };
    const customerResponse = await createCustomer({ input: customerInput });
    const customer = _get(customerResponse, 'data.data.createCustomer', null);
    if (customer) {
      await createExistingCustomerMutation(values, customer);
    }
  };

  const handleAddCustomerSubmission = async (values, { resetForm }) => {
    try {
      if (values.isCustomerExist) {
        await updateEditedCustomer(values);
        await createExistingCustomerMutation(values, values.isCustomerExist);
      } else {
        await handleCreateCustomer(values);
      }
      resetForm();
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Page className={classes.root} title="Customer Management List">
      <Header handleAddCustomer={handleAddCustomer} />
      <SearchBar onFilter={handleFilter} onSearch={handleSearch} />

      {searchedElements && (
        <CustomerSearchResults
          className={classes.results}
          customers={searchedElements}
          selectable="checkbox"
          onClickRow={onClickRow}
        />
      )}
      <AddCustomer
        initialValues={customerInitialValues}
        onClose={handleClose}
        open={openAddModal}
        formData={formData}
        onSubmit={handleAddCustomerSubmission}
        customerContacts={resultData}
      />
    </Page>
  );
};

Customers.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default Customers;
