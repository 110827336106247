import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  // FormControl,
  Grid
  // MenuItem,
  // Select,
  // Typography
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import { FilesDropzone } from 'components';
import { useEffect } from 'react';
import { Storage } from 'aws-amplify';

const _10mb = 10000000;
export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}
const useStyles = makeStyles(() => ({
  root: {},
  error: {
    paddingTop: '10px',
    color: '#e53935'
  }
}));

const SubmissionDocuments = props => {
  const [fileError, setFileError] = React.useState('');

  const [files, setFiles] = React.useState([]);
  const { className, initialValues, disabled, ...rest } = props;
  const classes = useStyles();
  useEffect(() => {
    const getS3Url = async () => {
      console.log('inital values', initialValues);
      if (initialValues) {
        const currFile = [];
        for (let i = 0; i < initialValues.length; i++) {
          if (initialValues[i].key) {
            const data = await Storage.get(initialValues[i].key, {
              download: true
            });
            // const file = new File(
            //   [data.Body],
            //   initialValues[i].key.substring(25),
            //   { lastModified: new Date() }
            // );
            const file = {
              name: initialValues[i].key.substring(25),
              size: data.Body.size
            };
            currFile.push(file);
          }
        }
        setFiles([...files, ...currFile]);
      }
    };
    getS3Url();
  }, [initialValues]);
  const {
    values,
    setFieldValue,
    setFieldError,
    errors,
    touched
  } = useFormikContext();

  useEffect(() => {
    if (files.length === 0) {
      setFileError('');
    }
  }, [files]);
  const handleDropFiles = acceptedFiles => {
    const savedFiles = [...files].concat(acceptedFiles);

    // setFieldValue('files', savedFiles);
    setFiles(prevFiles => {
      if (
        prevFiles
          .concat(savedFiles)
          .reduce((acc, currentFile) => acc + currentFile.size, 0) < _10mb
      ) {
        setFileError('');
        setFieldValue('files', prevFiles.concat(savedFiles));
        return prevFiles.concat(savedFiles);
      } else {
        setFieldError('files', 'files size cannot be greater than 10mb');
        setFileError('files size cannot be greater than 10mb');

        return prevFiles;
      }
    });
  };
  const removeAllFiles = () => {
    setFiles([]);
    setFieldValue('files', []);
    setFileError('');
  };

  const deleteFile = file => {
    const filterFiles = files.filter(fileData => fileData.path !== file.path);
    setFieldValue('files', filterFiles);
    values.files = filterFiles;
    setFiles(filterFiles);

    filterFiles &&
    filterFiles.length > 0 &&
    filterFiles?.reduce((acc, currentFile) => acc + currentFile.size) > _10mb
      ? setFileError('files size cannot be greater than 10mb')
      : setFileError('');
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Select submission" />
      <CardContent>
        <Grid>
          <FilesDropzone
            savedFiles={files}
            handleDropFiles={handleDropFiles}
            removeAllFiles={removeAllFiles}
            deleteFile={deleteFile}
            disabled={disabled}
          />
          {fileError && <div className={classes.error}>{fileError}</div>}
          {errors.files && (
            <div className={classes.error}>
              {touched['files'] && !!errors['files'] ? errors.files : ''}
            </div>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

SubmissionDocuments.propTypes = {
  className: PropTypes.string
};

export default SubmissionDocuments;
