import { useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { _get } from 'utils/helper';

const useSearchedData = (elements, keys) => {
  const [searchedElements, setSearchedElements] = useState([]);
  const [searchText, setSearchText] = useState('');

  // need to use useDeepCompareEffect otherwise new references to
  // empty arrays still causing this hook to re-render repeatedly
  useDeepCompareEffect(() => {
    if (elements && elements.length > 0) {
      setSearchedElements(elements);
    } else {
      setSearchedElements([]);
    }
  }, [elements]);

  const getArrayText = (element, keys) => {
    let text = '';
    for (const key in keys) {
      text = text + ' ' + _get(element, keys[key], []);
    }
    return text.trim();
  };

  useEffect(() => {
    if (elements && elements.length > 0 && searchText) {
      const searchResult = elements.filter(ele => {
        for (const key of keys) {
          let text = '';
          if (Array.isArray(key)) text = getArrayText(ele, key);
          else text = _get(ele, key, []);
          if (Array.isArray(text)) {
            const found = text.find(t =>
              t.toLowerCase().includes(searchText.toLowerCase())
            );
            if (found) return true;
          } else if (
            text &&
            text.toLowerCase().includes(searchText.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
      setSearchedElements(searchResult);
    } else {
      setSearchedElements(elements);
    }
  }, [searchText]);

  const handleSearch = text => {
    setSearchText(text);
  };

  return {
    searchedElements,
    handleSearch
  };
};

export default useSearchedData;
