import { translation } from '../../../../localization/translation';
import { i18n } from '../../../../localization/i18n';
export const formData = {
  component: 'form',
  label: i18n.t(translation.customers.addCustomerForm.addCustomerTitle),
  fields: [
    {
      component: 'text_field',
      label: i18n.t(
        translation.customers.addCustomerForm.fields.contactFirstName
      ),
      id: 'firstName',
      sort: 10
    },
    {
      component: 'text_field',
      label: i18n.t(
        translation.customers.addCustomerForm.fields.contactLastName
      ),
      id: 'lastName',
      sort: 11
    },
    {
      component: 'text_field',
      label: i18n.t(translation.customers.addCustomerForm.fields.phoneNumber),
      id: 'phone',
      sort: 13
    },
    {
      component: 'text_field',
      label: i18n.t(translation.customers.addCustomerForm.fields.emailAddress),
      id: 'email',
      sort: 12
    },
    {
      component: 'text_field',
      label: i18n.t(translation.customers.addCustomerForm.fields.postalCode),
      id: 'address.postalCode',
      sort: 6
    },
    {
      component: 'text_field',
      label: i18n.t(translation.customers.addCustomerForm.fields.city),
      id: 'address.city',
      sort: 5
    },
    {
      component: 'text_field',
      label: i18n.t(translation.customers.addCustomerForm.fields.address1),
      id: 'address.streetAddressLine1',
      sort: 7
    },
    {
      component: 'text_field',
      label: i18n.t(translation.customers.addCustomerForm.fields.address2),
      id: 'address.streetAddressLine2',
      sort: 8
    },
    {
      component: 'break_space',
      sort: 9
    },
    {
      component: 'select_field_country',
      label: i18n.t(translation.customers.addCustomerForm.fields.country.label),
      helperText: 'Please select your country',
      id: 'address.country',
      sort: 3
    },
    {
      component: 'select_field_state',
      label: i18n.t(translation.customers.addCustomerForm.fields.state.label),
      helperText: 'Please select your state',
      id: 'address.provinceState',
      sort: 4
    },
    {
      component: 'text_field_autoComplete',
      label: i18n.t(translation.customers.addCustomerForm.fields.companyName),
      id: 'name',
      sort: 2
    },
    {
      component: 'text_file_dropzone',
      id: 'avatar',
      sort: 1
    }
  ]
};
