/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const saveMessage = /* GraphQL */ `
  mutation SaveMessage(
    $inboundMessage: InboundMessage
    $outboundMessage: OutboundMessage
  ) {
    saveMessage(
      inboundMessage: $inboundMessage
      outboundMessage: $outboundMessage
    )
  }
`;
export const saveMessageInQueue = /* GraphQL */ `
  mutation SaveMessageInQueue($outboundMessage: OutboundMessage) {
    saveMessageInQueue(outboundMessage: $outboundMessage)
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail(
    $sender: String!
    $receiver: String!
    $subject: String!
    $body: String
    $attachments: String
    $messageId: ID!
    $cc: [String]
  ) {
    sendEmail(
      sender: $sender
      receiver: $receiver
      subject: $subject
      body: $body
      attachments: $attachments
      messageId: $messageId
      cc: $cc
    )
  }
`;
export const createBrokerage = /* GraphQL */ `
  mutation CreateBrokerage(
    $input: CreateBrokerageInput!
    $condition: ModelBrokerageConditionInput
  ) {
    createBrokerage(input: $input, condition: $condition) {
      id
      tenant
      name
      address {
        streetAddressLine1
        streetAddressLine2
        city
        postalCode
        provinceState
        country
      }
      brokers {
        items {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBrokerage = /* GraphQL */ `
  mutation UpdateBrokerage(
    $input: UpdateBrokerageInput!
    $condition: ModelBrokerageConditionInput
  ) {
    updateBrokerage(input: $input, condition: $condition) {
      id
      tenant
      name
      address {
        streetAddressLine1
        streetAddressLine2
        city
        postalCode
        provinceState
        country
      }
      brokers {
        items {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrokerage = /* GraphQL */ `
  mutation DeleteBrokerage(
    $input: DeleteBrokerageInput!
    $condition: ModelBrokerageConditionInput
  ) {
    deleteBrokerage(input: $input, condition: $condition) {
      id
      tenant
      name
      address {
        streetAddressLine1
        streetAddressLine2
        city
        postalCode
        provinceState
        country
      }
      brokers {
        items {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBroker = /* GraphQL */ `
  mutation CreateBroker(
    $input: CreateBrokerInput!
    $condition: ModelBrokerConditionInput
  ) {
    createBroker(input: $input, condition: $condition) {
      id
      brokerageId
      firstName
      lastName
      email
      avatar {
        key
        type
      }
      brokerage {
        id
        tenant
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        brokers {
          nextToken
        }
        createdAt
        updatedAt
      }
      submissions {
        items {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBroker = /* GraphQL */ `
  mutation UpdateBroker(
    $input: UpdateBrokerInput!
    $condition: ModelBrokerConditionInput
  ) {
    updateBroker(input: $input, condition: $condition) {
      id
      brokerageId
      firstName
      lastName
      email
      avatar {
        key
        type
      }
      brokerage {
        id
        tenant
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        brokers {
          nextToken
        }
        createdAt
        updatedAt
      }
      submissions {
        items {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBroker = /* GraphQL */ `
  mutation DeleteBroker(
    $input: DeleteBrokerInput!
    $condition: ModelBrokerConditionInput
  ) {
    deleteBroker(input: $input, condition: $condition) {
      id
      brokerageId
      firstName
      lastName
      email
      avatar {
        key
        type
      }
      brokerage {
        id
        tenant
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        brokers {
          nextToken
        }
        createdAt
        updatedAt
      }
      submissions {
        items {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCustomerContact = /* GraphQL */ `
  mutation CreateCustomerContact(
    $input: CreateCustomerContactInput!
    $condition: ModelCustomerContactConditionInput
  ) {
    createCustomerContact(input: $input, condition: $condition) {
      id
      customerId
      firstName
      lastName
      email
      phoneNumber
      brokerageId
      isActive
      customer {
        id
        brokerageId
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        yearDatePolicyAmount
        currentMonthPolicyAmount
        avatar {
          key
          type
        }
        isActive
        contacts {
          nextToken
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      submissions {
        items {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerContact = /* GraphQL */ `
  mutation UpdateCustomerContact(
    $input: UpdateCustomerContactInput!
    $condition: ModelCustomerContactConditionInput
  ) {
    updateCustomerContact(input: $input, condition: $condition) {
      id
      customerId
      firstName
      lastName
      email
      phoneNumber
      brokerageId
      isActive
      customer {
        id
        brokerageId
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        yearDatePolicyAmount
        currentMonthPolicyAmount
        avatar {
          key
          type
        }
        isActive
        contacts {
          nextToken
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      submissions {
        items {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerContact = /* GraphQL */ `
  mutation DeleteCustomerContact(
    $input: DeleteCustomerContactInput!
    $condition: ModelCustomerContactConditionInput
  ) {
    deleteCustomerContact(input: $input, condition: $condition) {
      id
      customerId
      firstName
      lastName
      email
      phoneNumber
      brokerageId
      isActive
      customer {
        id
        brokerageId
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        yearDatePolicyAmount
        currentMonthPolicyAmount
        avatar {
          key
          type
        }
        isActive
        contacts {
          nextToken
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      submissions {
        items {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      brokerageId
      name
      address {
        streetAddressLine1
        streetAddressLine2
        city
        postalCode
        provinceState
        country
      }
      yearDatePolicyAmount
      currentMonthPolicyAmount
      avatar {
        key
        type
      }
      isActive
      contacts {
        items {
          id
          customerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      brokerage {
        id
        tenant
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        brokers {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      brokerageId
      name
      address {
        streetAddressLine1
        streetAddressLine2
        city
        postalCode
        provinceState
        country
      }
      yearDatePolicyAmount
      currentMonthPolicyAmount
      avatar {
        key
        type
      }
      isActive
      contacts {
        items {
          id
          customerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      brokerage {
        id
        tenant
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        brokers {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      brokerageId
      name
      address {
        streetAddressLine1
        streetAddressLine2
        city
        postalCode
        provinceState
        country
      }
      yearDatePolicyAmount
      currentMonthPolicyAmount
      avatar {
        key
        type
      }
      isActive
      contacts {
        items {
          id
          customerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      brokerage {
        id
        tenant
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        brokers {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInsurerContact = /* GraphQL */ `
  mutation CreateInsurerContact(
    $input: CreateInsurerContactInput!
    $condition: ModelInsurerContactConditionInput
  ) {
    createInsurerContact(input: $input, condition: $condition) {
      id
      insurerId
      firstName
      lastName
      email
      phoneNumber
      avatar {
        key
        type
      }
      brokerageId
      isActive
      insurer {
        id
        brokerageId
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        yearDatePolicyAmount
        currentMonthPolicyAmount
        avatar {
          key
          type
        }
        isActive
        contacts {
          nextToken
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInsurerContact = /* GraphQL */ `
  mutation UpdateInsurerContact(
    $input: UpdateInsurerContactInput!
    $condition: ModelInsurerContactConditionInput
  ) {
    updateInsurerContact(input: $input, condition: $condition) {
      id
      insurerId
      firstName
      lastName
      email
      phoneNumber
      avatar {
        key
        type
      }
      brokerageId
      isActive
      insurer {
        id
        brokerageId
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        yearDatePolicyAmount
        currentMonthPolicyAmount
        avatar {
          key
          type
        }
        isActive
        contacts {
          nextToken
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInsurerContact = /* GraphQL */ `
  mutation DeleteInsurerContact(
    $input: DeleteInsurerContactInput!
    $condition: ModelInsurerContactConditionInput
  ) {
    deleteInsurerContact(input: $input, condition: $condition) {
      id
      insurerId
      firstName
      lastName
      email
      phoneNumber
      avatar {
        key
        type
      }
      brokerageId
      isActive
      insurer {
        id
        brokerageId
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        yearDatePolicyAmount
        currentMonthPolicyAmount
        avatar {
          key
          type
        }
        isActive
        contacts {
          nextToken
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInsurer = /* GraphQL */ `
  mutation CreateInsurer(
    $input: CreateInsurerInput!
    $condition: ModelInsurerConditionInput
  ) {
    createInsurer(input: $input, condition: $condition) {
      id
      brokerageId
      name
      address {
        streetAddressLine1
        streetAddressLine2
        city
        postalCode
        provinceState
        country
      }
      yearDatePolicyAmount
      currentMonthPolicyAmount
      avatar {
        key
        type
      }
      isActive
      contacts {
        items {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      brokerage {
        id
        tenant
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        brokers {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInsurer = /* GraphQL */ `
  mutation UpdateInsurer(
    $input: UpdateInsurerInput!
    $condition: ModelInsurerConditionInput
  ) {
    updateInsurer(input: $input, condition: $condition) {
      id
      brokerageId
      name
      address {
        streetAddressLine1
        streetAddressLine2
        city
        postalCode
        provinceState
        country
      }
      yearDatePolicyAmount
      currentMonthPolicyAmount
      avatar {
        key
        type
      }
      isActive
      contacts {
        items {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      brokerage {
        id
        tenant
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        brokers {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInsurer = /* GraphQL */ `
  mutation DeleteInsurer(
    $input: DeleteInsurerInput!
    $condition: ModelInsurerConditionInput
  ) {
    deleteInsurer(input: $input, condition: $condition) {
      id
      brokerageId
      name
      address {
        streetAddressLine1
        streetAddressLine2
        city
        postalCode
        provinceState
        country
      }
      yearDatePolicyAmount
      currentMonthPolicyAmount
      avatar {
        key
        type
      }
      isActive
      contacts {
        items {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      brokerage {
        id
        tenant
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        brokers {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMarketingGroup = /* GraphQL */ `
  mutation CreateMarketingGroup(
    $input: CreateMarketingGroupInput!
    $condition: ModelMarketingGroupConditionInput
  ) {
    createMarketingGroup(input: $input, condition: $condition) {
      id
      name
      brokerageId
      isActive
      insurerContacts {
        items {
          id
          marketingGroupId
          insurerContactId
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMarketingGroup = /* GraphQL */ `
  mutation UpdateMarketingGroup(
    $input: UpdateMarketingGroupInput!
    $condition: ModelMarketingGroupConditionInput
  ) {
    updateMarketingGroup(input: $input, condition: $condition) {
      id
      name
      brokerageId
      isActive
      insurerContacts {
        items {
          id
          marketingGroupId
          insurerContactId
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMarketingGroup = /* GraphQL */ `
  mutation DeleteMarketingGroup(
    $input: DeleteMarketingGroupInput!
    $condition: ModelMarketingGroupConditionInput
  ) {
    deleteMarketingGroup(input: $input, condition: $condition) {
      id
      name
      brokerageId
      isActive
      insurerContacts {
        items {
          id
          marketingGroupId
          insurerContactId
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInsurerContactMarketingGroup = /* GraphQL */ `
  mutation CreateInsurerContactMarketingGroup(
    $input: CreateInsurerContactMarketingGroupInput!
    $condition: ModelInsurerContactMarketingGroupConditionInput
  ) {
    createInsurerContactMarketingGroup(input: $input, condition: $condition) {
      id
      marketingGroupId
      insurerContactId
      isActive
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      marketingGroup {
        id
        name
        brokerageId
        isActive
        insurerContacts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInsurerContactMarketingGroup = /* GraphQL */ `
  mutation UpdateInsurerContactMarketingGroup(
    $input: UpdateInsurerContactMarketingGroupInput!
    $condition: ModelInsurerContactMarketingGroupConditionInput
  ) {
    updateInsurerContactMarketingGroup(input: $input, condition: $condition) {
      id
      marketingGroupId
      insurerContactId
      isActive
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      marketingGroup {
        id
        name
        brokerageId
        isActive
        insurerContacts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInsurerContactMarketingGroup = /* GraphQL */ `
  mutation DeleteInsurerContactMarketingGroup(
    $input: DeleteInsurerContactMarketingGroupInput!
    $condition: ModelInsurerContactMarketingGroupConditionInput
  ) {
    deleteInsurerContactMarketingGroup(input: $input, condition: $condition) {
      id
      marketingGroupId
      insurerContactId
      isActive
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      marketingGroup {
        id
        name
        brokerageId
        isActive
        insurerContacts {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSubmission = /* GraphQL */ `
  mutation CreateSubmission(
    $input: CreateSubmissionInput!
    $condition: ModelSubmissionConditionInput
  ) {
    createSubmission(input: $input, condition: $condition) {
      id
      customerContactId
      brokerId
      submissionName
      projectTags
      startDate
      targetDate
      targetPremium
      files {
        key
        type
      }
      submissionDetails
      groups
      status
      additionalNote
      isVisited
      isUrgent
      customerContact {
        id
        customerId
        firstName
        lastName
        email
        phoneNumber
        brokerageId
        isActive
        customer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
      }
      rfps {
        items {
          id
          submissionId
          insurerContactId
          brokerId
          isActive
          subject
          groups
          status
          rfpResult
          comment
          quotedPremium
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSubmission = /* GraphQL */ `
  mutation UpdateSubmission(
    $input: UpdateSubmissionInput!
    $condition: ModelSubmissionConditionInput
  ) {
    updateSubmission(input: $input, condition: $condition) {
      id
      customerContactId
      brokerId
      submissionName
      projectTags
      startDate
      targetDate
      targetPremium
      files {
        key
        type
      }
      submissionDetails
      groups
      status
      additionalNote
      isVisited
      isUrgent
      customerContact {
        id
        customerId
        firstName
        lastName
        email
        phoneNumber
        brokerageId
        isActive
        customer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
      }
      rfps {
        items {
          id
          submissionId
          insurerContactId
          brokerId
          isActive
          subject
          groups
          status
          rfpResult
          comment
          quotedPremium
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSubmission = /* GraphQL */ `
  mutation DeleteSubmission(
    $input: DeleteSubmissionInput!
    $condition: ModelSubmissionConditionInput
  ) {
    deleteSubmission(input: $input, condition: $condition) {
      id
      customerContactId
      brokerId
      submissionName
      projectTags
      startDate
      targetDate
      targetPremium
      files {
        key
        type
      }
      submissionDetails
      groups
      status
      additionalNote
      isVisited
      isUrgent
      customerContact {
        id
        customerId
        firstName
        lastName
        email
        phoneNumber
        brokerageId
        isActive
        customer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
      }
      rfps {
        items {
          id
          submissionId
          insurerContactId
          brokerId
          isActive
          subject
          groups
          status
          rfpResult
          comment
          quotedPremium
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      isActive
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRfp = /* GraphQL */ `
  mutation CreateRfp(
    $input: CreateRfpInput!
    $condition: ModelRfpConditionInput
  ) {
    createRfp(input: $input, condition: $condition) {
      id
      submissionId
      insurerContactId
      brokerId
      isActive
      subject
      groups
      status
      rfpResult
      comment
      quotedPremium
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      submission {
        id
        customerContactId
        brokerId
        submissionName
        projectTags
        startDate
        targetDate
        targetPremium
        files {
          key
          type
        }
        submissionDetails
        groups
        status
        additionalNote
        isVisited
        isUrgent
        customerContact {
          id
          customerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        rfps {
          nextToken
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        isActive
        createdAt
        updatedAt
        owner
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRfp = /* GraphQL */ `
  mutation UpdateRfp(
    $input: UpdateRfpInput!
    $condition: ModelRfpConditionInput
  ) {
    updateRfp(input: $input, condition: $condition) {
      id
      submissionId
      insurerContactId
      brokerId
      isActive
      subject
      groups
      status
      rfpResult
      comment
      quotedPremium
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      submission {
        id
        customerContactId
        brokerId
        submissionName
        projectTags
        startDate
        targetDate
        targetPremium
        files {
          key
          type
        }
        submissionDetails
        groups
        status
        additionalNote
        isVisited
        isUrgent
        customerContact {
          id
          customerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        rfps {
          nextToken
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        isActive
        createdAt
        updatedAt
        owner
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRfp = /* GraphQL */ `
  mutation DeleteRfp(
    $input: DeleteRfpInput!
    $condition: ModelRfpConditionInput
  ) {
    deleteRfp(input: $input, condition: $condition) {
      id
      submissionId
      insurerContactId
      brokerId
      isActive
      subject
      groups
      status
      rfpResult
      comment
      quotedPremium
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      submission {
        id
        customerContactId
        brokerId
        submissionName
        projectTags
        startDate
        targetDate
        targetPremium
        files {
          key
          type
        }
        submissionDetails
        groups
        status
        additionalNote
        isVisited
        isUrgent
        customerContact {
          id
          customerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        rfps {
          nextToken
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        isActive
        createdAt
        updatedAt
        owner
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createBrokerRfp = /* GraphQL */ `
  mutation CreateBrokerRfp(
    $input: CreateBrokerRfpInput!
    $condition: ModelBrokerRfpConditionInput
  ) {
    createBrokerRfp(input: $input, condition: $condition) {
      id
      rfpId
      brokerId
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      rfp {
        id
        submissionId
        insurerContactId
        brokerId
        isActive
        subject
        groups
        status
        rfpResult
        comment
        quotedPremium
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        submission {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBrokerRfp = /* GraphQL */ `
  mutation UpdateBrokerRfp(
    $input: UpdateBrokerRfpInput!
    $condition: ModelBrokerRfpConditionInput
  ) {
    updateBrokerRfp(input: $input, condition: $condition) {
      id
      rfpId
      brokerId
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      rfp {
        id
        submissionId
        insurerContactId
        brokerId
        isActive
        subject
        groups
        status
        rfpResult
        comment
        quotedPremium
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        submission {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrokerRfp = /* GraphQL */ `
  mutation DeleteBrokerRfp(
    $input: DeleteBrokerRfpInput!
    $condition: ModelBrokerRfpConditionInput
  ) {
    deleteBrokerRfp(input: $input, condition: $condition) {
      id
      rfpId
      brokerId
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      rfp {
        id
        submissionId
        insurerContactId
        brokerId
        isActive
        subject
        groups
        status
        rfpResult
        comment
        quotedPremium
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        submission {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInsurerRfp = /* GraphQL */ `
  mutation CreateInsurerRfp(
    $input: CreateInsurerRfpInput!
    $condition: ModelInsurerRfpConditionInput
  ) {
    createInsurerRfp(input: $input, condition: $condition) {
      id
      rfpId
      insurerContactId
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      rfp {
        id
        submissionId
        insurerContactId
        brokerId
        isActive
        subject
        groups
        status
        rfpResult
        comment
        quotedPremium
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        submission {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInsurerRfp = /* GraphQL */ `
  mutation UpdateInsurerRfp(
    $input: UpdateInsurerRfpInput!
    $condition: ModelInsurerRfpConditionInput
  ) {
    updateInsurerRfp(input: $input, condition: $condition) {
      id
      rfpId
      insurerContactId
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      rfp {
        id
        submissionId
        insurerContactId
        brokerId
        isActive
        subject
        groups
        status
        rfpResult
        comment
        quotedPremium
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        submission {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInsurerRfp = /* GraphQL */ `
  mutation DeleteInsurerRfp(
    $input: DeleteInsurerRfpInput!
    $condition: ModelInsurerRfpConditionInput
  ) {
    deleteInsurerRfp(input: $input, condition: $condition) {
      id
      rfpId
      insurerContactId
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      rfp {
        id
        submissionId
        insurerContactId
        brokerId
        isActive
        subject
        groups
        status
        rfpResult
        comment
        quotedPremium
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        submission {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          targetPremium
          submissionDetails
          groups
          status
          additionalNote
          isVisited
          isUrgent
          isActive
          createdAt
          updatedAt
          owner
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
    }
  }
`;
export const createError = /* GraphQL */ `
  mutation CreateError(
    $input: CreateErrorInput!
    $condition: ModelErrorConditionInput
  ) {
    createError(input: $input, condition: $condition) {
      id
      objId
      errorMessage
      createdAt
      updatedAt
    }
  }
`;
export const updateError = /* GraphQL */ `
  mutation UpdateError(
    $input: UpdateErrorInput!
    $condition: ModelErrorConditionInput
  ) {
    updateError(input: $input, condition: $condition) {
      id
      objId
      errorMessage
      createdAt
      updatedAt
    }
  }
`;
export const deleteError = /* GraphQL */ `
  mutation DeleteError(
    $input: DeleteErrorInput!
    $condition: ModelErrorConditionInput
  ) {
    deleteError(input: $input, condition: $condition) {
      id
      objId
      errorMessage
      createdAt
      updatedAt
    }
  }
`;
export const createThread = /* GraphQL */ `
  mutation CreateThread(
    $input: CreateThreadInput!
    $condition: ModelThreadConditionInput
  ) {
    createThread(input: $input, condition: $condition) {
      id
      rfpId
      insurerContactId
      brokerId
      isActive
      message
      isInQueue
      attachments {
        key
        type
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateThread = /* GraphQL */ `
  mutation UpdateThread(
    $input: UpdateThreadInput!
    $condition: ModelThreadConditionInput
  ) {
    updateThread(input: $input, condition: $condition) {
      id
      rfpId
      insurerContactId
      brokerId
      isActive
      message
      isInQueue
      attachments {
        key
        type
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteThread = /* GraphQL */ `
  mutation DeleteThread(
    $input: DeleteThreadInput!
    $condition: ModelThreadConditionInput
  ) {
    deleteThread(input: $input, condition: $condition) {
      id
      rfpId
      insurerContactId
      brokerId
      isActive
      message
      isInQueue
      attachments {
        key
        type
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
