import { useCallback, useState, useEffect } from 'react';

const useAlert = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const displayAlert = message => {
    setErrorMsg(message);
    setShowAlert(true);
  };

  useEffect(() => {
    let timerId;
    if (showAlert) {
      timerId = setTimeout(() => {
        closeAlert();
      }, 3000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [showAlert]);

  const closeAlert = useCallback(() => setShowAlert(false), []);

  return { showAlert, displayAlert, closeAlert, errorMsg };
};

export default useAlert;
