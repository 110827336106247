export const listCustomerContacts = /* GraphQL */ `
  query ListCustomerContacts(
    $filter: ModelCustomerContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        firstName
        lastName
        email
        phoneNumber
        brokerageId
        isActive
        customer {
          id
          brokerageId
          name
          address {
            city
            provinceState
          }
          avatar {
            key
            type
          }
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        submissions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getInsurerContact = /* GraphQL */ `
  query GetInsurerContact($id: ID!) {
    getInsurerContact(id: $id) {
      id
      insurerId
      firstName
      lastName
      email
      phoneNumber
      avatar {
        key
        type
      }
      brokerageId
      isActive
      insurer {
        id
        brokerageId
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        yearDatePolicyAmount
        currentMonthPolicyAmount
        avatar {
          key
          type
        }
        isActive
        contacts {
          nextToken
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const listInsurerContacts = /* GraphQL */ `
  query ListInsurerContacts(
    $filter: ModelInsurerContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInsurerContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          address {
            city
            provinceState
          }
          avatar {
            key
            type
          }
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMarketingGroups = /* GraphQL */ `
  query ListMarketingGroups(
    $filter: ModelMarketingGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarketingGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        brokerageId
        isActive
        insurerContacts {
          items {
            id
            insurerContact {
              id
              email
              firstName
              lastName
              insurer {
                name
                avatar {
                  key
                  type
                }
              }
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getMarketingGroup = /* GraphQL */ `
  query GetMarketingGroup($id: ID!) {
    getMarketingGroup(id: $id) {
      id
      name
      brokerageId
      isActive
      isActive
      insurerContacts(filter: { isActive: { eq: true } }) {
        items {
          id
          marketingGroupId
          insurerContactId
          insurerContact {
            firstName
            lastName
            email
            phoneNumber
            insurer {
              avatar {
                key
              }
              name
              address {
                streetAddressLine1
                streetAddressLine2
                city
                postalCode
                provinceState
                country
              }
            }
          }
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const createSubmission = /* GraphQL */ `
  mutation CreateSubmission(
    $input: CreateSubmissionInput!
    $condition: ModelSubmissionConditionInput
  ) {
    createSubmission(input: $input, condition: $condition) {
      id
      customerContactId
      insurerContactId
      brokerId
      groups
      customerContact {
        id
        customerId
        firstName
        lastName
        email
        phoneNumber
        brokerageId
        isActive
        customer {
          id
          brokerageId
          name
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        submissions {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      rfps {
        items {
          id
          submissionId
          carrierId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
    }
  }
`;

export const listInsurers = /* GraphQL */ `
  query ListInsurers(
    $filter: ModelInsurerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInsurers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brokerageId
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        yearDatePolicyAmount
        currentMonthPolicyAmount
        avatar {
          key
          type
        }
        isActive
        contacts {
          items {
            id
            firstName
            lastName
          }

          nextToken
        }
        brokerage {
          id
          tenant
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getRfp = /* GraphQL */ `
  query GetRfp($id: ID!) {
    getRfp(id: $id) {
      id
      submissionId
      insurerContactId
      isActive
      subject
      status
      rfpResult
      comment
      quotedPremium
      insurerContact {
        id
        insurerId
        firstName
        lastName
        email
        phoneNumber
        avatar {
          key
          type
        }
        brokerageId
        isActive
        insurer {
          id
          brokerageId
          name
          address {
            city
            provinceState
          }
          avatar {
            key
            type
          }
        }
      }
      submission {
        id
        customerContactId
        brokerId
        submissionName
        projectTags
        startDate
        targetPremium
        targetDate
        submissionDetails
        groups
        customerContact {
          id
          customerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        rfps {
          nextToken
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          avatar {
            key
            type
          }
          owner
        }
        isActive
        createdAt
        updatedAt
        owner
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        # createdAt
        # updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;

export const getSubmission = /* GraphQL */ `
  query GetSubmission($id: ID!) {
    getSubmission(id: $id) {
      id
      customerContactId
      brokerId
      submissionName
      projectTags
      startDate
      targetDate
      status
      additionalNote
      files {
        key
        type
      }
      isUrgent
      targetPremium
      submissionDetails
      groups
      customerContact {
        id
        customerId
        firstName
        lastName
        email
        phoneNumber
        brokerageId
        isActive
        customer {
          id
          brokerageId
          name
          avatar {
            key
            type
          }
          yearDatePolicyAmount
          currentMonthPolicyAmount
          isActive
          createdAt
          updatedAt
        }

        createdAt
        updatedAt
      }
      rfps {
        items {
          id
          submissionId
          insurerContactId
          insurerContact {
            id
            firstName
            lastName
            email
            insurer {
              name
              id
              avatar {
                key
                type
              }
            }
          }
          isActive
          subject
          createdAt
          updatedAt
        }
        nextToken
      }
      broker {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
        }
      }
    }
  }
`;

export const listSubmissions = /* GraphQL */ `
  query ListSubmissions(
    $filter: ModelSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubmissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        submissionName
        startDate
        targetDate
        status
        projectTags
        isVisited
        brokerId
        customerContact {
          id
          firstName
          lastName
          customer {
            name
          }
        }
        broker {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getSubmissionByCustomerContactId = /* GraphQL */ `
  query GetSubmissionByCustomerContactId(
    $customerContactId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSubmissionByCustomerContactId(
      customerContactId: $customerContactId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerContactId
        submissionName
        startDate
        targetDate
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getBrokerByBrokerageId = /* GraphQL */ `
  query GetBrokerByBrokerageId(
    $brokerageId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBrokerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBrokerByBrokerageId(
      brokerageId: $brokerageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brokerageId
        firstName
        lastName
        email
        avatar {
          key
          type
        }
        brokerage {
          id
          tenant
          name
          owner
        }
        groups
        submissions {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;

export const getBrokerRfpbyRfpId = /* GraphQL */ `
  query GetBrokerRfpbyRfpId(
    $rfpId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBrokerRfpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBrokerRfpbyRfpId(
      rfpId: $rfpId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rfpId
        brokerId
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          # createdAt
          # updatedAt
          owner
        }
        rfp {
          id
          submissionId
          insurerContactId
          brokerId
          isActive
          subject
          groups
          status
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getBroker = /* GraphQL */ `
  query GetBroker($id: ID!) {
    getBroker(id: $id) {
      id
      brokerageId
      firstName
      lastName
      email
      avatar {
        key
        type
      }
      brokerage {
        id
        tenant
        name
        createdAt
        updatedAt
      }
      submissions {
        items {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          submissionDetails
          groups
          status
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      # createdAt
      # updatedAt
    }
  }
`;

export const getRfpbySubmissionId = /* GraphQL */ `
  query GetRfpbySubmissionId(
    $submissionId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRfpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRfpbySubmissionId(
      submissionId: $submissionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        submissionId
        insurerContactId
        brokerId
        isActive
        subject
        groups
        status
        quotedPremium
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
          insurer {
            id
            name
            address {
              city
              provinceState
            }
            avatar {
              key
              type
            }
          }
        }
        submission {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          submissionDetails
          groups
          status
          isActive
          createdAt
          updatedAt
          owner
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          # createdAt
          # updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

export const getThreadByRfpId = /* GraphQL */ `
  query GetThreadByRfpId(
    $rfpId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getThreadByRfpId(
      rfpId: $rfpId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rfpId
        insurerContactId
        brokerId
        isActive
        message
        attachments {
          key
          type
        }
        broker {
          id
          brokerageId
          firstName
          lastName
          email
          avatar {
            key
            type
          }
          # createdAt
          # updatedAt
          owner
        }
        insurerContact {
          id
          insurerId
          firstName
          lastName
          email
          phoneNumber
          brokerageId
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getCustomerContact = /* GraphQL */ `
  query GetCustomerContact($id: ID!) {
    getCustomerContact(id: $id) {
      id
      customerId
      firstName
      lastName
      email
      phoneNumber
      isActive
      customer {
        id
        name
        address {
          streetAddressLine1
          streetAddressLine2
          city
          postalCode
          provinceState
          country
        }
        yearDatePolicyAmount
        currentMonthPolicyAmount
        avatar {
          key
          type
        }
        isActive
        contacts {
          nextToken
        }
        createdAt
        updatedAt
      }
      submissions {
        items {
          id
          customerContactId
          brokerId
          submissionName
          projectTags
          startDate
          targetDate
          submissionDetails
          groups
          status
          isActive
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
