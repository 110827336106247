import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const DropDown = ({
  error,
  label,
  id,
  helperText,
  variant,
  formikProps,
  children,
  handleChange
}) => {
  const getHandleChange = () => {
    if (handleChange) {
      return { onChange: handleChange };
    }
    return {};
  };

  return (
    <TextField
      error={error}
      fullWidth
      id={id}
      {...formikProps}
      helperText={helperText}
      label={label}
      SelectProps={{
        native: true
      }}
      select
      variant={variant}
      {...getHandleChange()}>
      {children}
    </TextField>
  );
};

export default DropDown;

DropDown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  error: PropTypes.bool,
  formikProps: PropTypes.any,
  helperText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.any,
  variant: PropTypes.string
};

DropDown.defaultProps = {
  variant: 'outlined',
  handleChange: null
};
