import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import { Paper, Button, Input } from '@material-ui/core';
import { i18n } from '../../../../localization/i18n';
import { translation } from '../../../../localization/translation';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  search: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon
  },
  searchInput: {
    flexGrow: 1
  },
  searchButton: {
    marginLeft: theme.spacing(2)
  }
}));

const Search = props => {
  const { ctaLabel, onSearch, className, ...rest } = props;
  // const { ctaLabel, onSearch, className, customersData, ...rest } = props;

  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState('');

  //trigger search when enter key is pressed
  const handleKeypress = e => {
    if (e.keyCode === 13) {
      onSearch(searchTerm);
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {/* <Autocompleted customersData={customersData} /> */}
      <Paper className={classes.search} elevation={1}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          className={classes.searchInput}
          onChange={e => setSearchTerm(e.target.value)}
          onKeyDown={handleKeypress}
          disableUnderline
          placeholder={i18n.t(
            translation.customers.search.searchBar.placeholder
          )}
        />
      </Paper>
      <Button
        className={classes.searchButton}
        onClick={() => onSearch(searchTerm)}
        size="large"
        variant="contained">
        {ctaLabel}
      </Button>
    </div>
  );
};

Search.propTypes = {
  ctaLabel: PropTypes.string,
  className: PropTypes.string,
  onSearch: PropTypes.func
};

Search.defaultProps = {
  ctaLabel: 'Search'
};

export default Search;
