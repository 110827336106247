import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  // Checkbox,
  Divider,
  // Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import { GenericMoreButton, TableEditBar } from 'components';
import { i18n } from '../../localization/i18n';
import { translation } from '../../localization/translation';
import { S3Avatar } from '../S3Avatar';
import usePagination from 'hooks/usePagination';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  customerRow: {
    cursor: 'pointer'
  }
}));

const CustomerSearchResults = props => {
  const {
    className,
    customers,
    showActions,
    selectable,
    // onSelect,
    actionComponent,
    onClickRow,
    ...rest
  } = props;

  const classes = useStyles();

  const [selectedCustomers] = useState([]);
  const {
    page,
    rowsPerPage,
    getPageDataParams,
    handleChangePage,
    handleChangeRowsPerPage
  } = usePagination(10);

  // const handleSelectAll = event => {
  //   const selectedCustomers = event.target.checked
  //     ? customers.map(customer => customer.id)
  //     : [];

  //   setSelectedCustomers(selectedCustomers);
  // };

  // const handleSelectOne = (event, id, multiple) => {
  //   let newSelectedCustomers = [];

  //   if (multiple) {
  //     const selectedIndex = selectedCustomers.indexOf(id);
  //     if (selectedIndex === -1) {
  //       newSelectedCustomers = newSelectedCustomers.concat(
  //         selectedCustomers,
  //         id
  //       );
  //     } else if (selectedIndex === 0) {
  //       newSelectedCustomers = newSelectedCustomers.concat(
  //         selectedCustomers.slice(1)
  //       );
  //     } else if (selectedIndex === selectedCustomers.length - 1) {
  //       newSelectedCustomers = newSelectedCustomers.concat(
  //         selectedCustomers.slice(0, -1)
  //       );
  //     } else if (selectedIndex > 0) {
  //       newSelectedCustomers = newSelectedCustomers.concat(
  //         selectedCustomers.slice(0, selectedIndex),
  //         selectedCustomers.slice(selectedIndex + 1)
  //       );
  //     }
  //   } else {
  //     newSelectedCustomers.push(id);
  //   }

  //   // TODO: move all state to redux or parent component
  //   if (onSelect) {
  //     onSelect(newSelectedCustomers);
  //   }
  //   setSelectedCustomers(newSelectedCustomers);
  // };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {customers.length}{' '}
        {i18n.t(translation.customers.table.paginationTop.text, {
          currentPage: page + 1
        })}{' '}
        {Math.ceil(customers.length / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader
          action={<GenericMoreButton />}
          title={i18n.t(translation.customers.table.title)}
        />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* {selectable === 'checkbox' && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={
                            selectedCustomers.length === customers.length
                          }
                          color="primary"
                          indeterminate={
                            selectedCustomers.length > 0 &&
                            selectedCustomers.length < customers.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                    )}
                    {selectable === 'radio' && (
                      <TableCell padding="checkbox"></TableCell>
                    )} */}

                    <TableCell>
                      {i18n.t(translation.customers.table.header.company)}
                    </TableCell>
                    <TableCell>
                      {i18n.t(translation.customers.table.header.contact)}
                    </TableCell>
                    <TableCell>
                      {i18n.t(translation.customers.table.header.phone)}
                    </TableCell>
                    <TableCell>
                      {i18n.t(translation.customers.table.header.email)}
                    </TableCell>
                    <TableCell>
                      {i18n.t(translation.customers.table.header.lifetimeValue)}
                    </TableCell>
                    <TableCell>
                      {i18n.t(translation.customers.table.header.type)}
                    </TableCell>
                    <TableCell>
                      {i18n.t(translation.customers.table.header.submissions)}
                    </TableCell>
                    {showActions && (
                      <TableCell align="center">
                        {i18n.t(translation.customers.table.header.actions)}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers.slice(...getPageDataParams()).map(customer => {
                    return (
                      <TableRow
                        className={classes.customerRow}
                        hover
                        key={customer.id}
                        onClick={() => onClickRow(customer)}
                        selected={
                          selectedCustomers.indexOf(customer.id) !== -1
                        }>
                        {/* {selectable === 'checkbox' && (
                          <TableCell align="center">
                            <Checkbox
                              checked={
                                selectedCustomers.indexOf(customer.id) !== -1
                              }
                              color="primary"
                              onChange={event =>
                                handleSelectOne(event, customer.id, true)
                              }
                              value={
                                selectedCustomers.indexOf(customer.id) !== -1
                              }
                            />
                          </TableCell>
                        )}
                        {selectable === 'radio' && (
                          <TableCell padding="checkbox">
                            <Radio
                              checked={
                                selectedCustomers.indexOf(customer.id) !== -1
                              }
                              color="primary"
                              onChange={event =>
                                handleSelectOne(event, customer.id, false)
                              }
                              onClick={e => e.stopPropagation()}
                              value={
                                selectedCustomers.indexOf(customer.id) !== -1
                              }
                            />
                          </TableCell>
                        )} */}
                        <TableCell>
                          <div className={classes.nameCell}>
                            <S3Avatar s3key={customer.customer.avatar?.key} />
                            <div>
                              <Typography color="inherit" variant="h6">
                                {customer.customer.name}
                              </Typography>
                              <div>
                                {customer.customer.address?.city},{' '}
                                {customer.customer.address?.provinceState}
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {customer.firstName} {customer.lastName}
                        </TableCell>
                        <TableCell>{customer.phoneNumber}</TableCell>
                        <TableCell>{customer.email}</TableCell>
                        <TableCell>
                          {customer.currency}
                          {customer.spent}
                        </TableCell>
                        {/* <TableCell>{customer.type}</TableCell>
                      <TableCell>{customer.submissions}</TableCell> */}
                        {showActions && (
                          <TableCell align="center">
                            {actionComponent ? actionComponent(customer) : ''}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={customers.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <TableEditBar
        selected={
          selectable === 'checkbox' && selectedCustomers
            ? selectedCustomers
            : []
        }
      />
    </div>
  );
};

CustomerSearchResults.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
  showActions: PropTypes.bool,
  selectable: PropTypes.oneOf(['radio', 'checkbox']),
  actionComponent: PropTypes.node,
  onClickRow: PropTypes.func
};

CustomerSearchResults.defaultProps = {
  customers: [],
  showActions: false
};

export default CustomerSearchResults;
