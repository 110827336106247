import { colors } from '@material-ui/core';

const statusColors = {
  started: {
    color: colors.grey[400],
    label: 'Started',
    variant: 'outlined'
  },
  'submission ready': {
    color: colors.blueGrey[800],
    label: 'Ready',
    variant: 'outlined'
  },
  submitted: {
    color: colors.orange[800],
    label: 'Submitted',
    variant: 'contained'
  },
  marketing: {
    color: colors.orange[800],
    label: 'Marketing',
    variant: 'contained'
  },
  accepted: {
    color: colors.teal[600],
    label: 'Accepted',
    variant: 'contained'
  },
  proposed: {
    color: colors.teal[600],
    label: 'Presented',
    variant: 'contained'
  },
  paid: { color: colors.teal[600], label: 'Paid', variant: 'contained' },
  bound: {
    color: colors.green[600],
    label: 'Completed',
    variant: 'outlined'
  },
  rejected: { color: colors.red[600], label: 'Rejected', variant: 'outlined' }
};

const submissionStatusStyling = status => statusColors[status];
export default submissionStatusStyling;
