import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { translation } from '../../localization/translation';
import { i18n } from '../../localization/i18n';

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  // Checkbox,
  Divider,
  // Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

import { GenericMoreButton, TableEditBar } from 'components';
import { S3Avatar } from 'components/S3Avatar';
import usePagination from 'hooks/usePagination';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  insurerRow: {
    cursor: 'pointer'
  }
}));

const InsurerSearchResults = props => {
  const {
    className,
    insurers,
    showActions,
    selectable,
    // onSelect,
    actionComponent,
    onClickRow,
    ...rest
  } = props;

  const classes = useStyles();

  const [selectedInsurers] = useState([]);
  const {
    page,
    rowsPerPage,
    getPageDataParams,
    handleChangePage,
    handleChangeRowsPerPage
  } = usePagination(10);

  // const handleSelectAll = event => {
  //   const selectedInsurers = event.target.checked
  //     ? insurers.map(insurer => insurer.id)
  //     : [];

  //   setSelectedInsurers(selectedInsurers);
  // };

  // const handleSelectOne = (event, id, multiple) => {
  //   let newSelectedInsurers = [];

  //   if (multiple) {
  //     const selectedIndex = selectedInsurers.indexOf(id);
  //     if (selectedIndex === -1) {
  //       newSelectedInsurers = newSelectedInsurers.concat(selectedInsurers, id);
  //     } else if (selectedIndex === 0) {
  //       newSelectedInsurers = newSelectedInsurers.concat(
  //         selectedInsurers.slice(1)
  //       );
  //     } else if (selectedIndex === selectedInsurers.length - 1) {
  //       newSelectedInsurers = newSelectedInsurers.concat(
  //         selectedInsurers.slice(0, -1)
  //       );
  //     } else if (selectedIndex > 0) {
  //       newSelectedInsurers = newSelectedInsurers.concat(
  //         selectedInsurers.slice(0, selectedIndex),
  //         selectedInsurers.slice(selectedIndex + 1)
  //       );
  //     }
  //   } else {
  //     newSelectedInsurers.push(id);
  //   }

  //   // TODO: move all state to redux or parent component
  //   if (onSelect) {
  //     onSelect(newSelectedInsurers);
  //   }
  //   setSelectedInsurers(newSelectedInsurers);
  // };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {insurers.length} Records found. Page {page + 1} of{' '}
        {Math.ceil(insurers.length / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader
          action={<GenericMoreButton />}
          title={i18n.t(translation.insurers.metaData.title)}
        />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* {selectable === 'checkbox' && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedInsurers.length === insurers.length}
                          color="primary"
                          indeterminate={
                            selectedInsurers.length > 0 &&
                            selectedInsurers.length < insurers.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                    )}
                    {selectable === 'radio' && (
                      <TableCell padding="checkbox"></TableCell>
                    )} */}

                    <TableCell>
                      {i18n.t(translation.insurers.table.header.company)}
                    </TableCell>
                    <TableCell>
                      {i18n.t(translation.insurers.table.header.contact)}
                    </TableCell>
                    <TableCell>
                      {i18n.t(translation.insurers.table.header.phone)}
                    </TableCell>
                    <TableCell>
                      {i18n.t(translation.insurers.table.header.email)}
                    </TableCell>
                    <TableCell>
                      {i18n.t(translation.insurers.table.header.ytd)}
                    </TableCell>
                    <TableCell>
                      {i18n.t(translation.insurers.table.header.currentMonth)}
                    </TableCell>
                    <TableCell>
                      {i18n.t(translation.insurers.table.header.submissions)}
                    </TableCell>
                    {showActions && (
                      <TableCell align="center">Actions</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {insurers.slice(...getPageDataParams()).map(insurer => (
                    <TableRow
                      className={classes.insurerRow}
                      hover
                      onClick={() => onClickRow(insurer)}
                      key={insurer.id}
                      selected={selectedInsurers.indexOf(insurer.id) !== -1}>
                      {/* {selectable === 'checkbox' && (
                        <TableCell align="center">
                          <Checkbox
                            checked={
                              selectedInsurers.indexOf(insurer.id) !== -1
                            }
                            color="primary"
                            onChange={event =>
                              handleSelectOne(event, insurer.id, true)
                            }
                            value={selectedInsurers.indexOf(insurer.id) !== -1}
                          />
                        </TableCell>
                      )}
                      {selectable === 'radio' && (
                        <TableCell padding="checkbox">
                          <Radio
                            checked={
                              selectedInsurers.indexOf(insurer.id) !== -1
                            }
                            color="primary"
                            onChange={event =>
                              handleSelectOne(event, insurer.id, false)
                            }
                            onClick={e => e.stopPropagation()}
                            value={selectedInsurers.indexOf(insurer.id) !== -1}
                          />
                        </TableCell>
                      )} */}
                      <TableCell>
                        <div className={classes.nameCell}>
                          <S3Avatar s3key={insurer.insurer.avatar?.key} />
                          <div>
                            <Typography color="inherit" variant="h6">
                              {insurer.insurer.name}
                            </Typography>
                            <div>
                              {insurer.insurer.address?.city},{' '}
                              {insurer.insurer.address?.provinceState}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        {insurer.firstName} {insurer.lastName}
                      </TableCell>
                      <TableCell>{insurer.phoneNumber}</TableCell>
                      <TableCell>{insurer.email}</TableCell>
                      <TableCell>
                        {insurer.currency}
                        {insurer.spent}
                      </TableCell>
                      <TableCell>
                        {insurer.currency}
                        {insurer.currentMonth}
                      </TableCell>
                      {/* <TableCell>{insurer.submissions}</TableCell> */}
                      {showActions && (
                        <TableCell align="center">
                          {actionComponent ? actionComponent(insurer) : ''}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={insurers.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <TableEditBar
        selected={
          selectable === 'checkbox' && selectedInsurers ? selectedInsurers : []
        }
      />
    </div>
  );
};

InsurerSearchResults.propTypes = {
  className: PropTypes.string,
  insurers: PropTypes.array.isRequired,
  showActions: PropTypes.bool,
  selectable: PropTypes.oneOf(['radio', 'checkbox']),
  actionComponent: PropTypes.node,
  onClickRow: PropTypes.func
};

InsurerSearchResults.defaultProps = {
  insurers: [],
  showActions: false,
  onClickRow: () => {}
};

export default InsurerSearchResults;
