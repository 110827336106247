import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { MarketingGroupMembers } from '../';
import { _get } from '../../../../utils/helper';
import { useListMarketingGroupsQuery } from 'redux/slices/marketingGroup/marketingGroupSlice';
import { AutoComplete } from 'components/Customer';
import { useFetchContinuously } from 'hooks';
import { useLazyGetInsurerContactsQuery } from 'redux/slices/insurer/insurerContactSlice';
import { useRef } from 'react';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    width: '100%'
  },
  options: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  marketingGroupMembers: {
    marginTop: theme.spacing(-3)
  },
  recipient: {
    marginTop: theme.spacing(2)
  }
}));

const SubmissionRecipients = props => {
  const { className, initialValues, ...rest } = props;

  const classes = useStyles();
  const [selectedGroup, setSelectedGroup] = useState([]);
  const insurerContactFilter = useRef({ isActive: { eq: true } });
  const [underwriter, setUnderwriter] = useState([]);
  const [savedUnderwriters, setSavedUnderwriters] = useState([]);
  const { touched, errors, getFieldProps } = useFormikContext();
  const { data } = useListMarketingGroupsQuery({
    filter: { isActive: { eq: true } }
  });
  const { resultData } = useFetchContinuously(
    useLazyGetInsurerContactsQuery,
    insurerContactFilter.current
  );

  const marketingGroups = _get(data, 'items', null);
  useEffect(() => {
    if (initialValues) {
      const savedUnderwriters = initialValues.map(rfp => rfp.insurerContact);
      setSavedUnderwriters(savedUnderwriters);
    }
  }, [initialValues]);

  // if (isFetching || isLoading) {
  //   return null;
  // }

  const handleSelected = (_, value) => {
    const underwitter = [];
    const group = [];
    value.map(select => {
      if (select.insurerId) {
        underwitter.push(select);
      } else {
        group.push(select);
      }
    });
    setUnderwriter(underwitter);
    setSelectedGroup(group);
  };
  const options = useMemo(() => {
    let arr = [];
    if (marketingGroups && resultData) {
      arr = [...resultData, ...marketingGroups];
    }
    return arr;
  }, [resultData, marketingGroups]);
  const recipients = [...underwriter, ...savedUnderwriters];

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Who do you want to send this submission to?" />
      <CardContent>
        <Grid>
          <AutoComplete
            label="Marketing Group"
            required
            multiple
            options={options.filter(option => {
              let found = recipients.find(contact => contact.id === option.id);
              if (found) return false;
              found = selectedGroup.find(group => group.id === option.id);
              if (found) return false;

              return true;
            })}
            formikProps={getFieldProps('insurerSubmissionId')}
            error={
              touched['insurerSubmissionId'] && !!errors['insurerSubmissionId']
            }
            helperText={
              touched['insurerSubmissionId'] && errors['insurerSubmissionId']
            }
            handleCompanyAutoCompleteName={handleSelected}
            getOptionLabel={option =>
              !option
                ? ''
                : option.name === undefined
                ? option.firstName + ' ' + option.lastName
                : option.name
            }
          />
        </Grid>

        {(selectedGroup.length > 0 || recipients.length > 0) && (
          <Typography gutterBottom variant="h6" className={classes.recipient}>
            Recipients
          </Typography>
        )}
        <Grid
          direction="row"
          justify="flex-start"
          container
          alignItems="flex-start">
          {
            <MarketingGroupMembers
              selectedGroupArray={selectedGroup}
              selectedUnderwriter={recipients}
              className={classes.marketingGroupMembers}
            />
          }
        </Grid>
      </CardContent>
    </Card>
  );
};

SubmissionRecipients.propTypes = {
  className: PropTypes.string
};

export default SubmissionRecipients;
