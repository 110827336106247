import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { S3Avatar } from '../../../../../src/components/S3Avatar';
import {
  // Avatar,
  Card,
  IconButton,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/CancelOutlined';

const useStyles = makeStyles(theme => ({
  root: {},
  customerCard: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative'
  },
  avatar: {
    marginRight: theme.spacing(2),
    width: theme.spacing(7),
    height: theme.spacing(7)
  },
  clear: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(1)
  },
  text: {
    marginRight: theme.spacing(4)
  }
}));

const Header = props => {
  const { className, customer, onClearCustomer, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid
        alignItems="flex-start"
        container
        justify="space-between"
        spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            Submissions
          </Typography>
          <Typography component="h1" variant="h3">
            Prepare a Submission
          </Typography>
        </Grid>
        <Grid item>
          <div className={classes.header}>
            {customer && (
              <Card className={clsx(classes.customerCard)}>
                <S3Avatar s3key={customer.customer?.avatar?.key} />
                <div className={classes.text}>
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    noWrap
                    to={`/customers/${customer.id}`}
                    variant="h4">
                    {customer.firstName + ' ' + customer.lastName}
                  </Link>
                  <Typography variant="subtitle1">
                    {customer.customer.name}
                  </Typography>
                  <Typography variant="body2">{customer.email}</Typography>
                </div>
                <IconButton className={classes.clear} onClick={onClearCustomer}>
                  <CancelIcon color="error" />
                </IconButton>
              </Card>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.shape({
    emailAddress: PropTypes.string,
    name: PropTypes.string,
    avatar: PropTypes.string
  }),
  onClearCustomer: PropTypes.func
};

export default Header;
